/* global */
// FIRST ADD CLASS TO CASE-SITE ---- IF NOT THIS IS REALY GLOBAL
body {
	&.cases {
		#main {
			.mod_article {
				&.cases {
					/* images */
					.content-image {
						figure {
							img {
								width: 100%;
							}
						}
					}
				}
			}
		}
	}
}


/* #1 Hero Header Cases */
.ce_rsce_pp_01-01_hero_header {
	position: relative;
	height: calc(100vh - 80px);
	.rs_container {
		height: 100%;
		.row {
			height: 100%;
			.content-holder {
				height: 100%;
				
				.content {
					height: calc(100vh - 160px);
					display: flex;
					flex-direction: column;
					position: relative;
					h1, h2, h3, h4, h5, h6 {
						margin: auto 0;
						position: relative;
						z-index: 1;
						@include min(md) {
							margin: auto 0;
						}
						span {
							// color: $black;
							color: unset;
							display: flex;
						    flex-direction: column;
							&:nth-child(2) {
								@include min(md) {
									text-align: right;
								}
							}
						}
					}
					.image-holder {
						position: absolute;
						top: 0px;
						left: 0px;
						display: flex;
						img {
							height: 100%;
							width: auto;
							margin: 0 auto;
						}
					}
					.footer-hero-links {
						position: absolute;
						bottom: -60px;
						left: 0px;
						display: flex;
						width: 100%;
						.ce_hyperlink { 
							@include min(md) {
								@include flex(0,1,33.33%);
							}
							@include min(max) {
								@include flex(0,1,70%);
							}
						}
						.url_tel {
							display: none;
							@include min(md) {
								display: block;
								text-align: center;
								@include flex(0,1,33.33%);
							}
							@include min(lg) {
								text-align: right;
							}
							@include min(max) {
								text-align: left;
								@include flex(0,1,15%);
							}
						}
						.ce_text { 
							display: none;
							@include min(md) {
								display: block;
								text-align: right;
								@include flex(0,1,33.33%);
							}
							@include min(max) {
								@include flex(0,1,15%);
							}
						}
						.ce_hyperlink {
							a {
								position: relative;
								padding-right: 0;
								padding-left: 35px;
								// color: $black;
								@include min(md) {
									padding-left: 45px;
								}
								&:before {
									position: absolute;
									content: "";
									height: 21px;
									width: 26px;
									left: 0;
									top: 0;
									background-image: url("data:image/svg+xml,%3Csvg%20id%3D'Button3-Pfeil-Klein'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'36'%20height%3D'26'%20viewBox%3D'0%200%2036%2026'%3E%3Crect%20x%3D'1'%20y%3D'1'%20width%3D'34'%20height%3D'24'%20rx%3D'4'%20ry%3D'4'%20stroke%3D'%2316141d'%20stroke-width%3D'2'%20fill%3D'none'/%3E%3Cpath%20d%3D'M23.5%2C13.4l1.4%2C1.4-6.1%2C6.1a.967.967%2C0%2C0%2C1-1.4%2C0l-6.2-6.2%2C1.4-1.4L17%2C17.7V5.7h2V17.9l4.5-4.5'%20fill%3D'%2316141d'/%3E%3C%2Fsvg%3E");
									background-position: center;
									background-size: 100% 100%;
									background-repeat: no-repeat;
									background-color: transparent;
									@include transition(all $speed ease-in);
									@include min(md) {
										height: 26px;
										width: 36px;
										top: -2px;
									}
								}
								&:after {
									content: none;
								}
								&:hover {
									// color: $white;
									&:before {
										background-image: url("data:image/svg+xml,%3Csvg%20id%3D'Button3-Pfeil-Klein'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'36'%20height%3D'26'%20viewBox%3D'0%200%2036%2026'%3E%3Crect%20x%3D'1'%20y%3D'1'%20width%3D'34'%20height%3D'24'%20rx%3D'4'%20ry%3D'4'%20stroke%3D'%2316141d'%20stroke-width%3D'2'%20fill%3D'%2316141d'/%3E%3Cpath%20d%3D'M23.5%2C13.4l1.4%2C1.4-6.1%2C6.1a.967.967%2C0%2C0%2C1-1.4%2C0l-6.2-6.2%2C1.4-1.4L17%2C17.7V5.7h2V17.9l4.5-4.5'%20fill%3D'%23ffffff'/%3E%3C%2Fsvg%3E");
									}
								}
							}
						}
						.url_tel {
							a {
								position: relative;
								color: $black;
								@include min(md) {
								}
								&:hover {
									color: $white;
								}
								&:after, &:before {
									content: none;
								}
							}
						}
						.ce_text {
							p {
								margin: 0;
							}
						}
					}
				}
			}
		}
	}
}

/* Hero Header small Text/Pic */
.ce_rsce_pp_01-02_hero_header {
	padding: 0;
	@include min(md) {
		height: 50vh;
	}
	.rs_container {
		@include min(md) {
			height: 50vh;
		}
		.row {
			@include min(md) {
				height: 50vh;
			}
			.content-holder {
				@include min(md) {
					height: 50vh;
				}
				.content {
					display: flex;
					flex-direction: column;
					position: relative;
					@include min(md) {
						height: 50vh;
					}
					.mobile-wrapper {
						position: relative;
						@include min(md) {
							height: 50vh;
						}
						.image-holder {
							height: 320px;
							@include min(md) {
								height: 50vh;
								display: flex;
							}
							picture {
								width: 100%;
								img {
									height: 100%;
									width: 100%;
									margin: 0 auto;
									object-fit: cover;
									@include min(md) {
										// height: auto;
									}
								}
							}
						}
						.footer-hero-links {
							position: absolute;
							bottom: 11px;
							left: 11px;
							@include min(md) {
								bottom: 11px;
								left: 35px;
							}
							.ce_hyperlink {
								a {
									position: relative;
									padding-right: 0;
									padding-left: 35px;
									color: $black;
									@include min(md) {
										padding-left: 45px;
									}
									&:before {
										position: absolute;
										content: "";
										height: 21px;
										width: 26px;
										left: 0;
										top: -2px;
										background-image: url("data:image/svg+xml,%3Csvg%20id%3D'Button3-Pfeil-Klein'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'36'%20height%3D'26'%20viewBox%3D'0%200%2036%2026'%3E%3Crect%20x%3D'1'%20y%3D'1'%20width%3D'34'%20height%3D'24'%20rx%3D'4'%20ry%3D'4'%20stroke%3D'%2316141d'%20stroke-width%3D'2'%20fill%3D'none'/%3E%3Cpath%20d%3D'M23.5%2C13.4l1.4%2C1.4-6.1%2C6.1a.967.967%2C0%2C0%2C1-1.4%2C0l-6.2-6.2%2C1.4-1.4L17%2C17.7V5.7h2V17.9l4.5-4.5'%20fill%3D'%2316141d'/%3E%3C%2Fsvg%3E");
										background-position: center;
										background-size: 100% 100%;
										background-repeat: no-repeat;
										background-color: transparent;
										@include transition(all $speed ease-in);
										@include min(md) {
											height: 26px;
											width: 36px;
											top: -2px;
										}
									}
									&:after {
										// content: none;
										all: unset;
										position: absolute;
										content: "";
										height: 2px;
										width: 0;
										bottom: -2px;
										left: 35px;
										background: $black;
										@include transition(all $speed ease-in);
										@include min(md) {
											left: 45px;
										}
									}
									&:hover {
										// color: $white;
										&:before {
											// background-image: url("data:image/svg+xml,%3Csvg%20id%3D'Button3-Pfeil-Klein'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'36'%20height%3D'26'%20viewBox%3D'0%200%2036%2026'%3E%3Crect%20x%3D'1'%20y%3D'1'%20width%3D'34'%20height%3D'24'%20rx%3D'4'%20ry%3D'4'%20stroke%3D'%2316141d'%20stroke-width%3D'2'%20fill%3D'%2316141d'/%3E%3Cpath%20d%3D'M23.5%2C13.4l1.4%2C1.4-6.1%2C6.1a.967.967%2C0%2C0%2C1-1.4%2C0l-6.2-6.2%2C1.4-1.4L17%2C17.7V5.7h2V17.9l4.5-4.5'%20fill%3D'%23ffffff'/%3E%3C%2Fsvg%3E");
											background-image: url("data:image/svg+xml,%3Csvg id='a' data-name='Ebene 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 26'%3E%3Cdefs%3E%3Cstyle%3E .b { fill: none; } .c { fill: %2316141d; } %3C/style%3E%3C/defs%3E%3Crect class='b' x='0' y='0' width='36' height='26' rx='4' ry='4'/%3E%3Cpath class='c' d='M32,0H4C1.79,0,0,1.79,0,4v18C0,24.21,1.79,26,4,26h28c2.21,0,4-1.79,4-4V4c0-2.21-1.79-4-4-4ZM18.75,20.98c-.19.19-.44.29-.71.29s-.52-.11-.71-.29l-6.22-6.22,1.41-1.41,4.44,4.44V5.73h2v12.2l4.5-4.5,1.41,1.41-6.13,6.13Z'/%3E%3C/svg%3E");
										}
										&::after {
											width: calc(100% - 35px);
											@include min(md) {
												width: calc(100% - 45px);
											}
										}
									}
								}
							}
						}
					}
					h3 {
						padding: 11px 11px 0 11px;
						margin-bottom: 0;
						@include min(md) {
							position: absolute;
							bottom: 11px;
							right: 35px;
							width: 38%;
							padding: 0;
						}
						@include min(xl) {
							width: 39%;
						}
						@include min(max) {
							width: 25%;
						}
					}
				}
			}
		}
	}
}
/* Hero Header Text/Pic */
.ce_rsce_pp_01-03_hero_header_text {
	.rs_container {
		.row {
			.content-holder {
				.content {
					display: flex;
					flex-direction: column;
					position: relative;
					.headline {
						width: 100%;
						margin-bottom: 210px;
						@include min(md) {
							margin-bottom: 135px;
						}

					}
					.text-holder {
						display: flex;
						flex-direction: column;
						@include min(md) {
							flex-direction: initial;
						}
						.text-left {
							order: 1;
							margin-top: 35px;
							@include min(md) {
								order: 0;
								@include flex(0,1,calc(50% - 20px));
								margin-right: 20px;
								margin-top: 0;
							}
							@include min(xxl) {
								@include flex(0,1,calc(75% - 20px));
							}
						}
						.text-right {
							@include min(md) {
								@include flex(0,1,calc(50% - 20px));
								margin-left: 20px;
							}
							@include min(xxl) {
								@include flex(0,1,calc(25% - 20px));
								margin-left: 20px;
							}
							.subheadline {
								margin-bottom: 11px;
								@include min(md) {
									margin-bottom: 35px;
								}
							}
						}
						.text-left {
							margin-bottom: 11px;
							@include min(md) {
								margin-bottom: 35px;
								margin-top: auto;
							}
							.footer-hero-links {
								.ce_hyperlink {
									a {
										position: relative;
										padding-right: 0;
										padding-left: 35px;
										color: $black;
										@include min(md) {
											padding-left: 45px;
										}
										&:before {
											position: absolute;
											content: "";
											height: 21px;
											width: 26px;
											left: 0;
											top: -2px;
											background-image: url("data:image/svg+xml,%3Csvg%20id%3D'Button3-Pfeil-Klein'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'36'%20height%3D'26'%20viewBox%3D'0%200%2036%2026'%3E%3Crect%20x%3D'1'%20y%3D'1'%20width%3D'34'%20height%3D'24'%20rx%3D'4'%20ry%3D'4'%20stroke%3D'%2316141d'%20stroke-width%3D'2'%20fill%3D'none'/%3E%3Cpath%20d%3D'M23.5%2C13.4l1.4%2C1.4-6.1%2C6.1a.967.967%2C0%2C0%2C1-1.4%2C0l-6.2-6.2%2C1.4-1.4L17%2C17.7V5.7h2V17.9l4.5-4.5'%20fill%3D'%2316141d'/%3E%3C%2Fsvg%3E");
											background-position: center;
											background-size: 100% 100%;
											background-repeat: no-repeat;
											background-color: transparent;
											@include transition(all $speed ease-in);
											@include min(md) {
												height: 26px;
												width: 36px;
												top: -2px;
											}
										}
										&:after {
											// content: none;
											all: unset;
											position: absolute;
											content: "";
											height: 2px;
											width: 0;
											bottom: -2px;
											left: 35px;
											background: $black;
											@include transition(all $speed ease-in);
											@include min(md) {
												left: 45px;
											}
										}
										&:hover {
											// color: $white;
											&:before {
												// background-image: url("data:image/svg+xml,%3Csvg%20id%3D'Button3-Pfeil-Klein'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'36'%20height%3D'26'%20viewBox%3D'0%200%2036%2026'%3E%3Crect%20x%3D'1'%20y%3D'1'%20width%3D'34'%20height%3D'24'%20rx%3D'4'%20ry%3D'4'%20stroke%3D'%2316141d'%20stroke-width%3D'2'%20fill%3D'%2316141d'/%3E%3Cpath%20d%3D'M23.5%2C13.4l1.4%2C1.4-6.1%2C6.1a.967.967%2C0%2C0%2C1-1.4%2C0l-6.2-6.2%2C1.4-1.4L17%2C17.7V5.7h2V17.9l4.5-4.5'%20fill%3D'%23ffffff'/%3E%3C%2Fsvg%3E");
												background-image: url("data:image/svg+xml,%3Csvg id='a' data-name='Ebene 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 26'%3E%3Cdefs%3E%3Cstyle%3E .b { fill: none; } .c { fill: %2316141d; } %3C/style%3E%3C/defs%3E%3Crect class='b' x='0' y='0' width='36' height='26' rx='4' ry='4'/%3E%3Cpath class='c' d='M32,0H4C1.79,0,0,1.79,0,4v18C0,24.21,1.79,26,4,26h28c2.21,0,4-1.79,4-4V4c0-2.21-1.79-4-4-4ZM18.75,20.98c-.19.19-.44.29-.71.29s-.52-.11-.71-.29l-6.22-6.22,1.41-1.41,4.44,4.44V5.73h2v12.2l4.5-4.5,1.41,1.41-6.13,6.13Z'/%3E%3C/svg%3E");
											}
											&::after {
												width: calc(100% - 35px);
												@include min(md) {
													width: calc(100% - 45px);
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

/* #2 Anleser */
.ce_rsce_pp_02-01_text {
	@include padding-case;
	.rs_container {
		.row {
			.content-holder {
				.content {
					.headline {
						margin-top: 0;
						margin-bottom: 22px;
						@include min(md) {
							text-indent: 15%;
							margin-bottom: 0;
						}
						&.big {
							text-indent: unset;
						}
					}
					.text-holder {
						@include min(md) {
							margin-top: 50px;
							display: flex;
							margin-top: 70px;
						}
						.text-left {
							@include min(md) {
								@include flex(0,1,calc(40% - 20px));
								margin-right: 20px;
							}
							@include min(xxl) {
								@include flex(0,1,calc(55% - 20px));
							}
							.ce_text {
								&.customer {
									margin-bottom: 65px;
									@include min(md) {
										margin-bottom: 0;
									}
								}
								p {
									margin-top: 0;
								}
							}

						}
						.text-right {
							@include min(md) {
								@include flex(0,1,calc(60% - 20px));
								margin-left: 20px;
							}
							@include min(xxl) {
								@include flex(0,1,calc(45% - 20px));
								margin-left: 20px;
							}
							p {
								margin-top: 0;
							}
						}
					}
				}
			}
		}
	}
}

/* #2-2 Anleser Beschreibung (nur text links) */
.ce_rsce_pp_02-02_text {
	@include padding-case;
	.rs_container {
		.row {
			.content-holder {
				.content {
					.text-holder {
						@include min(lg) {
							width: 65%;
						}
						@include min(max) {
							width: 55%;
						}
						.ce_text {
							&.top {
								p {
									margin-bottom: 1em;
								}
							}
							&.bottom {
								p {
									@include font-size(2.1);
									@include min(md) {
										@include font-size(2.6);
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.headline {
		@include font-size(4.5);
		font-family: $FactoryFont-Bold;
		line-height: 45px;
		@include min(md) {
			@include font-size(6.0);
			line-height: 60px;
		}
		@include min(lg) {
			@include font-size(8.0);
			line-height: 80px;
		}
		@include min(xl) {
			@include font-size(10.0);
			line-height: 100px;
		}
		@include min(xxl) {
			@include font-size(13.0);
			line-height: 130px;
		}
		@include min(max) {
			@include font-size(16.0);
			line-height: 160px;
		}
	}
	.headline {
		margin-top: 0.8em;
		margin-bottom: 0;
		text-align: right;
		margin-right: 20px;
		@include min(md) {
			margin-right: 20px;
		}
	}
}
/* #2-3 Text */
.ce_rsce_pp_02-03_text_big {
	@include padding-case;
	.rs_container {
		.row {
			.content-holder {
				.content {
					.text-holder {
						p {
							@include font-size(3.0);
							line-height: 30px;
							margin: .8em 0;
							@include min(md) {
								@include font-size(3.6);
								line-height: 36px;
							}
							@include min(lg) {
								@include font-size(4.6);
								line-height: 46px;
							}
							@include min(xl) {
								@include font-size(5.6);
								line-height: 60px;
							}
							@include min(xxl) {
								@include font-size(6.6);
								line-height: 70px;
							}
							@include min(max) {
								@include font-size(7.0);
								line-height: 80px;
							}
						}
					}
				}
			}
		}
	}
}

/* #3 Gallery */
.ce_rsce_pp_03-01_image_gallery {
	@include padding-case;
	.rs_container {
		.row {
			.content-holder {
				.content {
					.headline {
						// @include padding-case;
						margin-bottom: 0;
						@include min(lg) {
						  text-indent: 15%;
						}
						&.no_subheadline {
							padding-bottom: 65px;
							@include min(md) {
								@include padding-case;
							}
						}
					}
					.subheadline-wrapper {
						@include min(md) {
							display: flex;
						}
						.subheadline {
							margin-top: 22px;
							margin-bottom: 0;
							padding-bottom: 65px;
							@include min(md) {
								@include padding-case;
								margin-top: 70px;
								@include flex(0,1,calc(40% - 20px));
								margin-right: 20px;
							}
							@include min(xxl) {
								@include flex(0,1,calc(55% - 20px));
							}
						}

					}
					.image-line {
						/* global img/a tag style */
						.image-wrapper, .image-text-wrapper {
							.image-holder {
								figure {
									overflow: hidden;
								}
								picture {
									width: 100%;
								}
								img {
									object-fit: cover;
									display: flex;
									width: 100%;
									height: 320px;
									@include transition(all $speed ease-in);
									margin-bottom: 11px;
									@include min(md) {
										margin-bottom: 0;
									}
								}
								p {
									margin-top: 10px;
									display: none;
									@include min(md) {
										display: block;
										margin-top: 17px;
									}
									span {
										margin-left: 20px;
										@include min(md) {
											margin-left: 50px;
										}
									}
								}
								a {
									display: block;
									padding-right: 0;
									@include min(md) {
										display: flex;
									}
									&:hover {
										img {
											transform: scale(1.2);
										}
									}
									&:before, &::after {
										content: none;
									}
								}
							}
						}
						/* end global img style */

						.image-wrapper {
							@include min(md) {
								display: flex;
							}
							.image_1 {
								@include min(md) {
									@include flex(0,1,60%);
								}
								img {
									height: auto;
									/*
									@include min(md) {
										height: 480px;
									}
									@include min(lg) {
										height: 620px;
									}
									@include min(max) {
										height: 820px;
									}
									*/
								}
							}
							.image_2 {
								@include min(md) {
									@include flex(0,1,30%);
									margin: auto 0;
									margin-left: 10%;
								}
								img {
									height: auto;
									/*
									@include min(md) {
										height: 360px;
									}
									@include min(lg) {
										height: 480px;
									}
									@include min(max) {
										height: 500px;
									}
									*/
								}
							}
						}
						.image-text-wrapper {
							@include min(md) {
								display: flex;
								margin-top: 40px;
							}
							@include min(lg) {
								margin-top: 60px;
							}
							@include min(max) {
								margin-top: 100px;
							}
							.image_3 {
								@include min(md) {
									@include flex(0,1,60%);
									padding-left: 40px;
								}
								@include min(lg) {
									@include flex(0,1,60%);
									margin-right: 10%;
									padding-left: 0;
								}
								img {
									height: auto;
									/*
									@include min(md) {
										height: 600px;
									}
									*/
								}

							}
							.description {
								margin-top: 66px;
								margin-bottom: 66px;
								@include min(md) {
									@include flex(0,1,40%);
									margin-top: auto;
									margin-bottom: 61px;
									order: -1;
								}
								@include min(lg) {
									@include flex(0,1,25%);
									margin-right: 15%;
								}
								p {
									margin: 0;
								}
								.ce_hyperlink {
									@include min(md) {
										margin-top: 60px;
									}

								}

							}

						}
					}
				}
			}
		}
	}
}

/* #4 Image fullscreen */
.ce_rsce_pp_04-01_image_fullscreen {
	/*
	padding-top: 14px;
	padding-bottom: 11px;
	@include min(md) {
		padding-bottom: 35px;
	}
	&.no_headline {
		padding-top: 11px;
		@include min(md) {
			padding-top: 35px;
		}
	}
	*/
	.rs_container {
		.row {
			.content-holder {
				.content {
					.headline {
						margin-top: 0;
						margin-bottom: 22px;
						@include min(md) {
							text-indent: 15%;
							margin-bottom: 0;
						}
						&.big {
							text-indent: unset;
						}
					}
					.image-holder {
						figure {
							img {
								width: 100%;
							}
						}
					}
				}
			}
		}
	}
}
/* #4 Image with border */
.ce_rsce_pp_04-02_image_border {
	// @include padding-case;
	padding-top: 14px;
	padding-bottom: 11px;
	@include min(md) {
		padding-bottom: 35px;
	}
	&.no_headline {
		padding-top: 11px;
		@include min(md) {
			padding-top: 35px;
		}
	}
	.rs_container {
		.row {
			.content-holder {
				.content {
					.headline {
						margin-top: 0;
						margin-bottom: 22px;
						@include min(md) {
							text-indent: 15%;
							margin-bottom: 0;
						}
						&.big {
							text-indent: unset;
						}
					}
					.image-holder {
						figure {
							img {
								width: 100%;
							}
						}
					}
				}
			}
		}
	}
}
/* #4 Text - Image 50-50 */
.ce_rsce_pp_04-03_text_image {
	@include padding-case;
	.rs_container {
		.row {
			.content-holder {
				.content {
					@include min(md) {
						display: flex;
					}
					.image-holder {
						margin-bottom: 60px;
						display: flex;
						@include min(md) {
							margin-bottom: 0;
							@include flex(0,1,60%);
						}
						picture {
							width: 100%;
						}
						img {
							display: flex;
							width: 100%;
							height: 320px;
							object-fit: cover;
							@include min(md) {
								height: 480px;
							}
							@include min(lg) {
								height: 600px;
							}
							@include min(max) {
								height: 800px;
							}
						}
					}
					.text-holder {
						@include min(md) {
							display: flex;
							flex-direction: column;
							@include flex(0,1,40%);
							padding-left: 45px;
							padding-right: 45px;
						}
						.headline {
							margin-top: 0;
							margin-bottom: 35px;
						}
						.ce_text {
							p {
								margin-top: 0;
								&:last-of-type {
									@include min(md) {
										margin-bottom: 0;
									}
								}
							}
						}
						.icon-holder {
							@include min(md) {
								margin-top: auto;
							}
						}
						&.text_bottom {
							@include min(md) {
								margin-top: auto;
							}
						}
					}
					&.isMirror {
						@include min(md) {
							flex-direction: row-reverse;
						}
						.icon-holder {
							@include min(md) {
								display: flex;
							}
							img {
								@include min(md) {
									margin-left: auto;
								}
							}
						}
					}
				}
			}
		}
	}
}

/* #5 ZITAT */
.ce_rsce_pp_05-01_quote {
	@include padding-case;
	.rs_container {
		.row {
			.content-holder {
				.content {
					.text-holder {
						.quote {
							p {
								@include font-size(4.5);
								line-height: 45px;
								margin-top: 0;
								margin-bottom: 35px;
								@include min(md) {
									@include font-size(6.0);
									line-height: 60px;
									margin-bottom: 70px;
								}
								@include min(lg) {
									@include font-size(8.0);
									line-height: 80px;
								}
								@include min(xl) {
									@include font-size(10.0);
									line-height: 100px;
								}
								@include min(xxl) {
									@include font-size(13.0);
									line-height: 130px;
								}
								@include min(max) {
									@include font-size(16.0);
									line-height: 160px;
								}
							}
						}
						.name {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
}

.ce_rsce_pp_06-01_contact {
	@include padding-case;
	background-color: $black;
	.rs_container {
		.row {
			.content-holder {
				.content {
					.headline {
						p {
							color: $grey_2;
							font-family: $FactoryFont-Medium;
							letter-spacing: 0;
							@include font-size(3.0);
							line-height: 30px;
							margin: 0 0 1em 0;
							font-weight: 400;
							@include min(md) {
								@include font-size(3.6);
								line-height: 36px;
							}
							@include min(lg) {
								@include font-size(4.6);
								line-height: 46px;
							}
							@include min(xl) {
								@include font-size(5.6);
								line-height: 60px;
							}
							@include min(xxl) {
								@include font-size(6.6);
								line-height: 70px;
							}
							@include min(max) {
								@include font-size(8.9);
								line-height: 107px;
							}
						}
					}
					.text-holder {
						.ce_hyperlink {
							margin-top: 200px;
							@include min(md) {
								width: fit-content;
    							float: right;
								margin-top: 0;
							}
							@include min(lg) {
								@include flex(0,1,calc(50% - 10px));
								order: -1;
							}
							a {
								font-family: $FactoryFont-Bold;
								letter-spacing: 0;
								@include font-size(5.0);
								margin: 0.9em 0;
								line-height: 50px;
								font-weight: 700;
								color: $grey_2;
								@include min(md) {
									@include font-size(6.0);
									line-height: 60px;
								}
								@include min(lg) {
									@include font-size(10.0);
									line-height: 100px;
								}
								@include min(xl) {
									@include font-size(14.0);
									line-height: 140px;
								}
								@include min(xxl) {
									@include font-size(16.0);
									line-height: 160px;
								}
								@include min(max) {
									@include font-size(18.7);
									line-height: 208px;
								}

								padding-right: 0;
								display: flex;
								align-items: center;
								@include min(md) {
									justify-content: flex-end;
									margin-bottom: 20px;
									margin-top: 1.1em;
								}
								@include min(max) {
									margin-bottom: 10px;
								}
								&::after {
									// background-image: url("data:image/svg+xml,%3Csvg%20id%3D'uuid-0478e9c9-d145-4ce8-ad04-340382150296'%20data-name%3D'_x31_'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2032.912%2017.4'%3E%3Cdefs%3E%3Cstyle%3E.uuid-1b01a2fd-b754-48c8-803b-b3ea58b9c013%7Bfill%3Anone%3Bstroke%3A%23FF0000%3Bstroke-linecap%3Around%3Bstroke-miterlimit%3A10%3Bstroke-width%3A2px%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cline%20id%3D'uuid-822c1021-8188-486a-a700-dd6df4784cad'%20data-name%3D'_x33_'%20class%3D'uuid-1b01a2fd-b754-48c8-803b-b3ea58b9c013'%20x1%3D'1'%20y1%3D'8.698'%20x2%3D'30.505'%20y2%3D'8.698'/%3E%3Cpath%20id%3D'uuid-4cc4ec3f-b934-4a03-9538-29173ce973c2'%20data-name%3D'_x32_'%20class%3D'uuid-1b01a2fd-b754-48c8-803b-b3ea58b9c013'%20d%3D'M23.798%2C16.4l7.7-7.7-7.7-7.7'/%3E%3C%2Fsvg%3E");
									background-image: url("data:image/svg+xml,%3Csvg%20id%3D%22a%22%20data-name%3D%22Ebene%201%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20300%20150%22%3E%3Cdefs%3E%3Cstyle%3E.b%7Bfill%3A%23FF0000%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cpath%20class%3D%22b%22%20d%3D%22M293.99%2C0H6.01C2.69%2C0%2C0%2C2.69%2C0%2C6.01v137.99c0%2C3.31%2C2.69%2C6.01%2C6.01%2C6.01h287.99c3.31%2C0%2C6.01-2.69%2C6.01-6.01V6.01c0-3.31-2.69-6.01-6.01-6.01ZM290.99%2C140.99H9.01V9.01h281.98v131.98Z%22%2F%3E%3Cpath%20class%3D%22b%22%20d%3D%22M208.36%2C116.01l6.37%2C6.37%2C44.64-44.64c.84-.85%2C1.32-1.99%2C1.32-3.19s-.47-2.34-1.32-3.19l-44.01-44.01-6.37%2C6.37%2C36.64%2C36.64H34.17v9.01h210.82l-36.63%2C36.63Z%22%2F%3E%3C%2Fsvg%3E");
									position: relative;
									height: 40px;
									width: 70px;
									// border: 4px solid $red;
									// border-radius: 6px;
									margin-left: 20px;
									@include min(md) {
										height: 50px;
										width: 100px;
										margin-left: 40px;
										// background-size: 80% 80%;
										background-size: 100% 100%;
									}
									@include min(lg) {
										// border: 9px solid $red;
										// border-radius: 6px;
										height: 80px;
										width: 180px;
										margin-left: 60px;
									}
									@include min(xl) {
										height: 100px;
										width: 250px;
										margin-left: 60px;
									}
									@include min(xxl) {
										height: 120px;
										width: 290px;
										margin-left: 60px;
									}
									@include min(max) {
										height: 140px;
										width: 290px;
										margin-left: 60px;
									}
								}
								&::before {
									content: none;
								}
								&:hover {
									color: $red;
									/*
									&:after {
										border: 4px solid $white;
										background-color: transparent;
										background-image: url("data:image/svg+xml,%3Csvg%20id%3D'uuid-0478e9c9-d145-4ce8-ad04-340382150296'%20data-name%3D'_x31_'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2032.912%2017.4'%3E%3Cdefs%3E%3Cstyle%3E.uuid-1b01a2fd-b754-48c8-803b-b3ea58b9c013%7Bfill%3Anone%3Bstroke%3A%23FFFFFF%3Bstroke-linecap%3Around%3Bstroke-miterlimit%3A10%3Bstroke-width%3A2px%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cline%20id%3D'uuid-822c1021-8188-486a-a700-dd6df4784cad'%20data-name%3D'_x33_'%20class%3D'uuid-1b01a2fd-b754-48c8-803b-b3ea58b9c013'%20x1%3D'1'%20y1%3D'8.698'%20x2%3D'30.505'%20y2%3D'8.698'/%3E%3Cpath%20id%3D'uuid-4cc4ec3f-b934-4a03-9538-29173ce973c2'%20data-name%3D'_x32_'%20class%3D'uuid-1b01a2fd-b754-48c8-803b-b3ea58b9c013'%20d%3D'M23.798%2C16.4l7.7-7.7-7.7-7.7'/%3E%3C%2Fsvg%3E");
										
										@include min(lg) {
											border: 9px solid $white;
										}
									}
									*/
								}
							}
						}
					}
				}
			}
		}
	}
}

/* #7 Video fullscreen */
.ce_rsce_pp_07-01_video_fullscreen {
	&.addPadding {
		@include padding-case;
	}
	.rs_container {
		.row {
			.content-holder {
				.content {
					.headline {
						margin-top: 0;
						margin-bottom: 22px;
						@include min(md) {
							text-indent: 15%;
							margin-bottom: 0;
						}
						&.big {
							text-indent: unset;
						}
					}
					.video-holder {
						video {
							width: 100%;
							display: flex;
						}
					}
				}
			}
		}
	}
}
/* #7 Video inGrid */
.ce_rsce_pp_07-02_video_inGrid {
	padding-top: 14px;
	padding-bottom: 11px;
	@include min(md) {
		padding-bottom: 35px;
	}
	&.no_headline {
		padding-top: 11px;
		@include min(md) {
			padding-top: 35px;
		}
	}
	.rs_container {
		.row {
			.content-holder {
				.content {
					.headline {
						margin-top: 0;
						margin-bottom: 22px;
						@include min(md) {
							text-indent: 15%;
							margin-bottom: 0;
						}
						&.big {
							text-indent: unset;
						}
					}
					.video-holder {
						video {
							width: 100%;
							display: flex;
						}
					}
				}
			}
		}
	}
}