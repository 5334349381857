/* -----------------------------------
LINKS & BUTTONS
----------------------------------- */
.mod_article {
    a {
        color: $black;
        font-family: $FactoryFont-Medium;
        text-decoration: none;
        @include transition(all $speed ease-in);
        padding-right: 14px;
        position: relative;
        &:after {
            position: absolute;
            content: "";
            background-image: url("data:image/svg+xml,%3Csvg%20id%3D'Button3-Pfeil-Gross'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'72'%20height%3D'26'%20viewBox%3D'0%200%2072%2026'%3E%3Crect%20x%3D'1'%20y%3D'1'%20width%3D'70'%20height%3D'24'%20rx%3D'4'%20ry%3D'4'%20stroke%3D'%2316141d'%20stroke-width%3D'2'%20fill%3D'none'/%3E%3Cpath%20d%3D'M64.2%2C12.2a.967.967%2C0%2C0%2C1%2C0%2C1.4L58%2C19.8l-1.4-1.4L61%2C14H8.7V12H61.2L56.7%2C7.5l1.4-1.4%2C6.1%2C6.1'%20fill%3D'%2316141d'/%3E%3C%2Fsvg%3E");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center;
            @include transition(all $speed ease-in);            
            height: 21px;
            width: 40px;
            margin-left: 16px;
            margin-top: -1px;
            @include min(md) {
                height: 26px;
                width: 72px;
                margin-top: 0px;
            }
        }
        &:before {
            position: absolute;
            content: "";
            height: 2px;
            width: calc(100% - 14px);
            bottom: 0px;
            left: 0;
            background: $black;
            @include transition(all $speed ease-in);
            @include min(md) {
                width: 0%;
                bottom: -4px;
            }
        }
        /*
        @include min(md) {
            &:after {
                height: 26px;
                width: 72px;
                margin-left: 16px;
                margin-top: 0px;
                border: 2px solid $black;
            }
        }
        */
        &:hover {
            @include min(md) {
                &:before {
                    width: calc(100% - 14px);
                }
            }
            @include min(md) {
                &:after {
                    background-image: url("data:image/svg+xml,%3Csvg%20id%3D'Button3-Pfeil-Gross'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'72'%20height%3D'26'%20viewBox%3D'0%200%2072%2026'%3E%3Crect%20x%3D'1'%20y%3D'1'%20width%3D'70'%20height%3D'24'%20rx%3D'4'%20ry%3D'4'%20stroke%3D'%2316141d'%20stroke-width%3D'2'%20fill%3D'%2316141d'/%3E%3Cpath%20d%3D'M64.2%2C12.2a.967.967%2C0%2C0%2C1%2C0%2C1.4L58%2C19.8l-1.4-1.4L61%2C14H8.7V12H61.2L56.7%2C7.5l1.4-1.4%2C6.1%2C6.1'%20fill%3D'%23ffffff'/%3E%3C%2Fsvg%3E");
                }
            }
        }
    }
    #map {
        a {
            &:after {
                content: none;
            }
        }
    }

}
html body #wrapper .mod_article.font_color a:hover {
    text-decoration: none;
}