.mod_latest_customers, .mod_all_customers {
    @include min(md) {
        padding-top: 21px;
    }
	.layout_latest_customers {
        position: relative;
        /* abstand nach unten */
        @include min(md) {
            margin-bottom: 80px;
        }
        @include min(max) {
            margin-bottom: 140px;
        }
        /* grid und breite */
        @include min(md) {
            float: left;
            &:nth-of-type(even) {
                width: 35%;
            }
            &:nth-of-type(odd) {
                width: calc(65% - 165px);
            }
            &:nth-of-type(6n + 1) {
                margin-right: 165px;
                margin-left: 0;
            }
            &:nth-of-type(6n - 1) {
                margin-left: 165px;
                margin-right: 0;
            }
            &:nth-of-type(3n + 3) {
                width: 70%;
                margin-right: 15%;
                margin-left: 15%;
            }
        }

        /* höhe */
        a {
            .text-holder {
                height: 320px;
            }
        }
        &:nth-of-type(even) {
            a {
                .text-holder {
                    @include min(md) {
                        margin-top: 40px;
                        height: 320px;
                    }
                    @include min(lg) {
                        margin-top: 40px;
                        height: 340px;
                    }
                    @include min(xl) {
                        margin-top: 100px;
                        height: 420px;
                    }
                    @include min(max) {
                        margin-top: 160px;
                        height: 500px;
                    }
                }
            }
        }
        &:nth-of-type(odd) {
            a {
                .text-holder {
                    @include min(md) {
                        height: 360px;
                    }
                    @include min(lg) {
                        height: 420px;
                    }
                    @include min(xl) {
                        height: 620px;
                    }
                    @include min(max) {
                        height: 820px;
                    }
                }
            }
        }
        &:nth-of-type(3n + 3) {
            a {
                .text-holder {
                    @include min(md) {
                        /* don NOT remove margin-top for edge */
                        margin-top: 0;
                        height: 300px;
                    }
                    @include min(lg) {
                        margin-top: 0;
                        height: 360px;
                    }
                    @include min(xl) {
                        margin-top: 0;
                        height: 480px;
                    }
                    @include min(max) {
                        margin-top: 0;
                        height: 600px;
                    }
                }
            }
        }




        &.big {
            @include min(md) {
                width: 100%;
                margin-right: 0;
                margin-left: 0;
            }
            /* höhe big */
            a {
                .text-holder {
                    // height: 320px;
                    height: auto;
                    @include min(md) {
                        min-height: 360px;
                        margin-top: 0;
                    }
                    @include min(lg) {
                        min-height: 420px;
                    }
                    @include min(xl) {
                        min-height: 620px;
                    }
                    @include min(max) {
                        min-height: 820px;
                    }
                    /* for mobile */
                    .image-holder {
                        @include max(md) {
                            height: 320px;
                        }
                        @include min(md) {
                            height: auto;
                        }
                    }
                    /* end for mobile */
                }
            }
            .image-description {
                display: none;
                @include min(md) {
                    display: block;
                    margin-left: 40%;
                }
            }
        }
        a {
            @include transition(all $speed ease-in);
            padding-right: 0!important;
            position: relative;
            /*
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                background-color: $black;
                @include transition(opacity $speed ease-in);
                z-index: 3;
            }
            */
            &::before {
                content: none;
            }
            &::after {
                content: none;
            }
            @include min(md) {
                &:hover {
                    > .text-holder .headline, 
                    > .text-holder .subheadline {
                        color: $white;
                    }
                    /*
                    > .text-holder .image-holder figure img {
                        // transform: scale(1.2);
                    }
                    &::before {
                        opacity: 1;
                    }
                    */
                    /* if news has custom color */
                    > .text-holder .color-wrapper {
                        opacity: 1;
                    }
                }
            }
        }
        .image-description {
            margin-top: 17px;
            margin-bottom: 35px;
            span {
                color: $black;
                padding-right: 11px;
                @include min(md) {
                    padding-right: 35px;
                }
                @include min(xl) {
                    padding-right: 15%;
                }
            }
        }

		.image-holder {
            @include transition(all $speed ease-in);
            // position: absolute;
            overflow: hidden;
            // display: inline-block;
            position: relative;
            figure {
                position: unset;
                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    @include transition(all $speed ease-in);
                }
            }
		}
        .text-holder {
            background-color: transparent;
            // z-index: 2;
            position: relative;
            .ce_text{
                /* headline kunde */
                position: absolute;
                top: 30px;
                left: 30px;
                z-index: 4;
                display: flex;
                flex-direction: column;
                height: calc(100% - 60px);
                .headline {
                    margin-top: 0;
                    margin-bottom: 0;
                    text-transform: uppercase;
                    @include transition(color $speed ease-in);
                    @include font-size(1.8);
                    color: $white;
                    text-shadow: 0px 0px 2px $black;
                    display: none;
                    @include min(md) {
                        display: block;
                        color: transparent;
                        text-shadow: none;
                    }
                }
                
                /* headline projecttitel */
                .subheadline {
                    margin-top: auto;
                    margin-bottom: 0;
                    text-transform: uppercase;
                    @include transition(color $speed ease-in);
                    color: $white;
                    text-shadow: 0px 0px 2px $black;
                    display: none;
                    @include min(md) {
                        display: block;
                        color: transparent;
                        text-shadow: none;
                        @include font-size(2.0);
                        line-height: 1.2;
                    }
                }
            }
            /* if news has custom color */
            .color-wrapper {
                @include min(md) {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    z-index: 3;
                    opacity: 0;
                    @include transition(all $speed ease-in);
                    background-color: $black;
                }
                .image-holder {
                    display: none;
                    @include min(md) {
                        display: block;
                        padding: 30px;
                    }
                    img {
                        @include min(md) {
                            max-height: 100px;
                        }
                        @include min(lg) {
                            max-height: 120px;
                        }
                        @include min(xl) {
                            max-height: 160px;
                        }
                        @include min(xxl) {
                            max-height: 240px;
                        }
                        @include min(max) {
                            max-height: 400px;
                        }
                    }
                }
            }
            /* END if news has custom color */
        }

        /* if news has hover effect */
        a {
            &.normal {
                .text-holder {
                    .color-wrapper {
                        mix-blend-mode: normal;
                    }
                }
            }
            &.multiply {
                .text-holder {
                    .color-wrapper {
                        mix-blend-mode: multiply;
                    }
                }
            }
            &.screen {
                .text-holder {
                    .color-wrapper {
                        mix-blend-mode: screen;
                    }
                }
            }
            &.overlay {
                .text-holder {
                    .color-wrapper {
                        mix-blend-mode: overlay;
                    }
                }
            }
            &.darken {
                .text-holder {
                    .color-wrapper {
                        mix-blend-mode: darken;
                    }
                }
            }
            &.color-dodge {
                .text-holder {
                    .color-wrapper {
                        mix-blend-mode: color-dodge;
                    }
                }
            }
            &.color-burn {
                .text-holder {
                    .color-wrapper {
                        mix-blend-mode: color-burn;
                    }
                }
            }
            &.difference {
                .text-holder {
                    .color-wrapper {
                        mix-blend-mode: difference;
                    }
                }
            }
            &.exclusion {
                .text-holder {
                    .color-wrapper {
                        mix-blend-mode: exclusion;
                    }
                }
            }
            &.hue {
                .text-holder {
                    .color-wrapper {
                        mix-blend-mode: hue;
                    }
                }
            }
            &.saturation {
                .text-holder {
                    .color-wrapper {
                        mix-blend-mode: saturation;
                    }
                }
            }
            &.color {
                .text-holder {
                    .color-wrapper {
                        mix-blend-mode: color;
                    }
                }
            }
            &.luminosity {
                    .text-holder {
                    .color-wrapper {
                        mix-blend-mode: luminosity;
                    }
                }
            }
        }
        /* END if news has hover effect */

        &.big {
            a {
                .text-holder {
                    display: flex;
                    flex-wrap: wrap;
                    .ce_text {
                        @include min(md) {
                            @include flex(0,1,40%);
                            width: 40%;
                        }
                    }
                    .image-holder {
                        @include min(md) {
                            @include flex(0,1,60%);
                            width: 60%;
                        }
                    }
                    .image-holder {
                        position: relative;
                        .headline, .subheadline {
                            position: absolute;
                        }
                        /* headline kunde */
                        .headline {
                            display: none;
                            @include min(md) {
                                display: block;
                                color: transparent;
                                text-shadow: none;
                                z-index: 2;
                                top: 30px;
                                left: 30px;
                                margin-top: 0;
                                margin-bottom: 0;
                                text-transform: uppercase;
                                @include transition(color $speed ease-in);
                                @include font-size(1.8);
                            }
                        }
                        
                        /* headline projecttitel */
                        .subheadline {
                            display: none;
                            @include min(md) {
                                display: block;
                                color: transparent;
                                text-shadow: none;
                                z-index: 2;
                                bottom: 30px;
                                left: 30px;
                                margin-top: auto;
                                margin-bottom: 0;
                                text-transform: uppercase;
                                @include transition(color $speed ease-in);
                                @include font-size(2.0);
                                line-height: 1.2;
                            }
                        }
                    }
                    .ce_text {
                        position: relative;
                        top: 0;
                        left: 0;
                        color: $black;
                        height: 100%;
                        @include min(md) {
                            order: -1;
                            display: flex;
                            position: relative;
                            top: 0;
                            left: 0;
                        }
                        .headline {
                            display: none;
                        }
                        .subheadline {
                            display: none;
                        }
                        .featured-text {
                            // margin: auto 0;
                            display: block;
                            margin-bottom: 35px;
                            @include min(md) {
                                margin-bottom: 0;
                            }
                            > * {
                                @include min(md) {
                                    padding-right: 35px;
                                }
                                &:first-of-type {
                                    @include min(md) {
                                        margin-top: 0;
                                    }
                                }
                            }
                            h1, h2, h3, h4, h5, h6 {
                                margin-top: 11px;
                                margin-bottom: 22px;
                                @include min(md) {
                                    margin-top: 0;
                                    margin-bottom: 35px;
                                }
                            }
                        }
                    }
                }
                &:before {
                    content: none;
                    @include min(md) {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 40%;
                        width: 60%;
                        height: 100%;
                        opacity: 0;
                        background-color: $black;
                        @include transition(opacity $speed ease-in);
                        z-index: 1;
                    }
                    
                }
                &:hover {
                    @include min(md) {
                        &::before {
                            opacity: 1;
                        }
                        .image-holder {
                            .headline, .subheadline {
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
	}
}
