/* -----------------------------------
TYPO
----------------------------------- */
// FactoryFont - Regular
// add font-weight in global.scss and typo.scss for browsers
@font-face{
    font-family: 'FactoryFont-Medium';
    font-display: swap;
    // font-weight: 400;
    src:
        // local('FactoryFont'),
        local('FactoryFont-Medium'),
        url("../fonts/FactoryFont/FactoryFont-Medium.woff2") format("woff2"),
        url("../fonts/FactoryFont/FactoryFont-Medium.woff") format("woff");
}
// FactoryFont - Bold
@font-face{
    font-family: 'FactoryFont-Bold';
    font-display: swap;
    // font-weight: 700;
    src:
        // local('FactoryFont'),
        local('FactoryFont-Bold'),
        url("../fonts/FactoryFont/FactoryFont-Bold.woff2") format("woff2"),
        url("../fonts/FactoryFont/FactoryFont-Bold.woff") format("woff");
}

$FactoryFont-Medium:    'FactoryFont-Medium', Arial, Helvetica, sans-serif;
$FactoryFont-Bold:     'FactoryFont-Bold', Arial, Helvetica, sans-serif;




/*
// Titilium Web - Regular 
@font-face{
    font-family: 'TitilliumWeb-Regular';
    font-display: swap;
    font-weight: 400;
    src:
        // local('TitilliumWeb'),
        local('TitilliumWeb-Regular'),
        url("../fonts/Titillium_Web_Woff/TitilliumWeb-Regular.woff2") format("woff2"),
        url("../fonts/Titillium_Web_Woff/TitilliumWeb-Regular.woff") format("woff");
}
// Titilium Web - Semibold 
@font-face{
    font-family: 'TitilliumWeb-SemiBold';
    font-display: swap;
    font-weight: 600;
    src:
        // local('TitilliumWeb'),
        local('TitilliumWeb-SemiBold'),
        url("../fonts/Titillium_Web_Woff/TitilliumWeb-SemiBold.woff2") format("woff2"),
        url("../fonts/Titillium_Web_Woff/TitilliumWeb-SemiBold.woff") format("woff");
}
// Titilium Web - Bold 
@font-face{
    font-family: 'TitilliumWeb-Bold';
    font-display: swap;
    font-weight: 700;
    src:
        // local('TitilliumWeb'),
        local('TitilliumWeb-Bold'),
        url("../fonts/Titillium_Web_Woff/TitilliumWeb-Bold.woff2") format("woff2"),
        url("../fonts/Titillium_Web_Woff/TitilliumWeb-Bold.woff") format("woff");
}

$TitilliumWeb-Regular:    'TitilliumWeb-Regular', Arial, Helvetica, sans-serif;
$TitilliumWeb-SemiBold:     'TitilliumWeb-SemiBold', Arial, Helvetica, sans-serif;
$TitilliumWeb-Bold:     'TitilliumWeb-Bold', Arial, Helvetica, sans-serif;
*/