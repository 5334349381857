/* -----------------------------------
FIXES
----------------------------------- */

body {
    &.firefox {
        /* typo */
        h4 {
            font-weight: 400;
        }
        /* elements */
        .mod_article {
            .ce_rsce_cc_04-01_contact_teaser {
                .ce_hyperlink {
                    a {
                        &::after {
                            @include transition(border,background-image $speed ease-in);
                            will-change: background-image;
                        }
                    }
                }
            }
        }
    }
}

// SAFARI
body {
    &.safari {
        /* typo */
        h4 {
            font-weight: 400;
        }
        /* elements */
        .mod_article {
            // video element
            .fix-safari {
                figure {
                    display: flex;
                }
            }

            // gallery slider
            .content-swiper {
                .swiper {
                    .swiper-wrapper {
                        .swiper-slide {
                            .content-image {
                                figure {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
            // full screen image
            .content-image {
                figure {
                    img {
                        margin: 0;
                    }
                }
            }
            // logo wall with border
            &.logo_wall_border {
              .row {
                figure {
                  img {
                    margin: auto;
                  }
                }
              }
            }
            .ce_rsce_cc_01-03_hero_header {
                padding: 0;
            }
            .content-swiper {
                padding: 0;
            }
        }
        // global safari
        figure {
            display: block;
        }
    }
}