/* -----------------------------------
COLORS
----------------------------------- */

/* HOMECOLOR */
$black: #16141D;
$white: #FFFFFF;
$red: #FF0000;
$grey: #FAFAFD;
$grey_2: #E4E3EC;

// $orange: rgba(240, 79, 34, 1);       /* #F04F22 */
// $green: rgba(88, 236, 181, 1);       /* #58ecb5 */

/* ERROR */
$error-color: $red;

/* SPACE */
$gutter: 10px;
$gutter-sm: 10px;
$gutter-lg: 10px;

$speed: 0.2s;
$speed-mobilemenu: 0.5s;
$speed-scrolling: 0.6s;
$speed-animation-fast: 0.3s;
$speed-animation: 0.6s;
$speed-link: 1s;
$speed-slider: 2s;
// $radius: 30px;

/* padding element cases */
$padding-case: 90px;
$padding-case-md: 160px;
$padding-case-max: 280px;