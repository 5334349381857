/* -----------------------------------
ANIMATIONS
----------------------------------- */
.mod_article {
    // grid
    @include min(md) {
        &.below_viewport {
            .ani-bottom {
                position: relative;
                top: 200px;
                @include transition(all $speed-animation ease-in);
                &:nth-of-type(2) {
                    @include transition(all $speed-animation ease-in .1s);
                }
                &:nth-of-type(3) {
                    @include transition(all $speed-animation ease-in .2s);
                }
            }
        }
        &.iivp {
            .ani-bottom {
                position: relative;
                top: 0;
            }
        }
    }
    // cases teaser
    @include min(md) {
        &.cases {
            section {
                &.ce_rsce_pp_02-01_text {
                    &.ani-bottom {
                        // &.below_viewport {
                            .text-holder {
                                position: relative;
                                top: 120px;
                                @include transition(all $speed-animation ease-in .2s);
                            }
                        // }
                    }
                    &.iivp {
                        &.ani-bottom {
                            .text-holder {
                                position: relative;
                                top: 0;
                            }
                        }
                    }

                }
            }
            section {
                &:not(.ce_rsce_pp_02-01_text) {
                    .below_viewport {
                        &.ani-bottom {
                            position: relative;
                            top: 200px;
                            @include transition(all $speed-animation ease-in .2s);
                        }
                    }
                    .iivp {
                        &.ani-bottom {
                            position: relative;
                            top: 0;
                        }
                    }
                }
            }
        }
    }

    @include min(xl) {
        .ce_rsce_cc_02-01_services {
            &.below_viewport {
                .ani-bottom {
                    top: 200px;
                    @include transition(all $speed-animation ease-in);
                    &:nth-of-type(2) {
                        @include transition(all $speed-animation ease-in .1s);
                    }
                    &:nth-of-type(3) {
                        @include transition(all $speed-animation ease-in .2s);
                    }
                }
            }
            &.iivp {
                .ani-bottom {
                    top: 0;
                }
            }
        }
    }
    
    // a link animation
    @include min(md) {
        .content-hyperlink, .ce_hyperlink {
            &.iivp {
                &.below_viewport {
                    a {
                        &:before {
                            animation: expandAnimation $speed-link ease-in .5s;
                        }
                    }
                }
            }
        }
    }
}

// color from red to white
@include min(md) {
    .mod_article {
        &.below_viewport {
            .animate-red-white {
                div {
                    > * {
                        color: $red !important;
                        @include transition(all $speed-animation ease-in .3s);
                    }
                }
            }
        }
        &.iivp {
            .animate-red-white {
                div {
                    > * {
                        color: $white !important;
                    }
                }
            }
        }
    }
}


/* hero header startseite */
.ce_rsce_cc_01-02_hero_header {
    &.animate {
        .rs_container {
            .row {
                .content-holder {
                    .content {
                        .ce_text {
                            h1 {
                                /* B2B animation */
                                .slide-in-wrapper {
					                @include transition(all $speed-animation-fast ease-in 0.5s);
                                }
                                .slide-in {
					                @include transition(all $speed-animation-fast ease-in 1.0s);
                                    opacity: 0;
                                }
                                .b2b, .slide-in-wrapper {
                                    display: inline-block;
                                    overflow: hidden;
                                    white-space: nowrap;
                                }
                                .slide-in-wrapper {
                                    width: 0px;
                                    padding-left: 10px;
                                    @include min(md) {
                                        padding-left: 15px;
                                    }
                                    @include min(lg) {
                                        padding-left: 15px;
                                    }
                                    @include min(xl) {
                                        padding-left: 20px;
                                    }
                                    @include min(xxl) {
                                        padding-left: 25px;
                                    }
                                    @include min(max) {
                                        padding-left: 30px;
                                    }
                                    .slide-in {
                                        // margin-left: 600px;
                                        margin-left: 200px;
                                        @include min(md) {
                                            // margin-left: 650px;
                                            margin-left: 250px;
                                        }
                                        @include min(lg) {
                                            // margin-left: 700px;
                                            margin-left: 300px;
                                        }
                                        @include min(xl) {
                                            // margin-left: 800px;
                                            margin-left: 400px;
                                        }
                                        @include min(xxl) {
                                            // margin-left: 1000px;
                                            margin-left: 500px;
                                        }
                                        @include min(max) {
                                            // margin-left: 1200px;
                                            margin-left: 600px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &.iivp {
            .rs_container {
                .row {
                    .content-holder {
                        .content {
                            .ce_text {
                                h1 {
                                    .slide-in-wrapper {
                                        width: 182px;
                                        // width: 212px;
                                        @include min(md) {
                                            width: 221px;
                                            // width: 251px;
                                        }
                                        @include min(lg) {
                                            width: 289px;
                                            // width: 319px;
                                        }
                                        @include min(xl) {
                                            width: 363px;
                                            // width: 393px;
                                        }
                                        @include min(xxl) {
                                            width: 471px;
                                            // width: 501px;
                                        }
                                        @include min(max) {
                                            width: 578px;
                                            // width: 608px;
                                        }
                                        .slide-in {
                                            margin-left: 0;
                                            opacity: 1;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
/* hero header 2 über uns */
@include min(md) {
    .ce_rsce_cc_01-03_hero_header {
        &.animate {
            .rs_container {
                .row {
                    .content-holder {
                        .image-holder {
                            img {
                                margin-left: 400px;
                                @include transition(all $speed-animation ease-in);
                            }
                        }
                    }
                }
            }
            &.iivp {
                .rs_container {
                    .row {
                        .content-holder {
                            .image-holder {
                                img {
                                    margin-left: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ce_rsce_cc_03-01_image_line */
@include min(md) {
    .ce_rsce_cc_03-01_image_line {
        &.animate {
            .rs_container {
                .row {
                    .content-holder {
                        .content {
                            .image-line {
                                div {
                                    &:nth-child(4n + 1) {
                                        &.image-holder {
                                            height: 570px;
                                            min-height: 570px;
                                            img {
                                                height: 0px;
                                                width: 0;
                                                // margin-left: auto;
                                            }
                                        }
                                    }
                                    &:nth-child(4n + 2), &:nth-child(4n + 3), &:nth-child(4n + 4) {
                                        &.image-holder {
                                            height: 420px;
                                            min-height: 420px;
                                            img {
                                                height: 0px;
                                                width: 0;
                                            }
                                        }
                                    }
                                    &:nth-child(4n + 3) {
                                        &.image-holder {
                                            margin-left: calc(20% - 3px);
                                            img {
                                                max-width: 0;
                                                margin-left: 0;
                                            }
                                        }
                                    }
                                    &.image-holder {
                                        img {
                                            @include transition(width, max-width, height, min-height, $speed-animation ease-in 0.5s);
                                        }
                                    }
                                    &.ce_hyperlink {
                                        opacity: 0;
                                        @include transition(all $speed-animation ease-in 0.5s);
                                    }
                                    // animation
                                    &.iivp, &.wivp {
                                        &:nth-child(4n + 1) {
                                            &.image-holder {
                                                img {
                                                    height: 570px;
                                                }
                                            }
                                        }
                                        &:nth-child(4n + 2), &:nth-child(4n + 3), &:nth-child(4n + 4) {
                                            &.image-holder {
                                                img {
                                                    height: 420px;
                                                }
                                            }
                                        }
                                        &:nth-child(4n + 3) {
                                            &.image-holder {
                                                img {
                                                    max-width: 66.66%;
                                                }
                                            }
                                        }
                                        &.image-holder {
                                            img {
                                                width: 100%;
                                                height: 100%;
                                                object-fit: cover;
                                            }
                                        }
                                        &.ce_hyperlink {
                                            opacity: 1;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* zoom bild agenturbild */
@include min(md) {
    .mod_article {
        .content-image {
            &.animate-zoom {
                figure {
                    overflow: hidden;
                    img {
                        transform: scale(1.2);
                        @include transition(all $speed-animation ease-in);
                    }
                }
                &.iivp {
                    img {
                        transform: scale(1);
                    }
                }
            }
        }
    }
}

@include min(md) {
    .logo_wall {
        .animate-opacity {
            figure {
                img {
                    opacity: 0;
                    @include transition(all $speed-animation ease-in);
                }
            }
            &.iivp {
                figure {
                    img {
                        opacity: 1;
                    }
                }
            }
        }
    }
}
  
/* news slider sticky */
/*
@include min(md) {
    #news-slider-fixed {
        &.sticky {
            position: fixed !important;
            top: 0;
            left: 0;
            right: 0;
            z-index: 1000;
            @include transition(all $speed-animation ease-in);
            background: $black !important;
            .news-slider {
                &.animate {
                    h1, h2, h3, h4, h5, h6 {
                        color: $white;
                    }
                }
            }
            .news-slider {
                button {
                    @include transition(all $speed-animation ease-in);
                    &:not(.disabled) {
                        filter: brightness(0) invert(1);
                    }
                }
            }
        }
    }
}
*/
// right to left animation
@include min(md) {
    .animate-right-to-left {
        .news-slider {
            .owl-carousel {
                margin-left: 200px;
                @include transition(all $speed-animation ease-in);
            }
            &.iivp {
                .owl-carousel {
                    margin-left: 0;
                }
            }
        }
        .content-swiper {
            .swiper {
                margin-left: 200px;
                @include transition(all $speed-animation ease-in);
            }
            &.iivp {
                .swiper {
                    margin-left: 0;
                }
            }
        }
    }
}