.news-slider {
	position: relative;
	h1, h2, h3, h4, h5, h6 {
		@include transition(all $speed-animation ease-in);
	}
	.overlay {
		display: none;
		@include min(lg) {
			display: block;
			position: absolute;
			height: 100%;
			width: calc(14% - 2px);
			top: 0px;
			right: 0px;
			background-color: $black;
			z-index: 1;
			opacity: .7;
		}
		&.overlay-left {
			left: 0px;
		}
	}
	.owl-carousel {
		padding-top: 40px;
		@include min(md) {
			padding-top: 40px;
		}
		@include min(lg) {
			padding-top: 60px;
		}
		.owl-stage-outer {
			.owl-item {
				.layout_latest {
					@include transition(all $speed ease-in);
					.content-holder {
						.date-wrapper {
							padding-bottom: 10px;
							border-bottom: 1px solid $white;
						}
						.text-holder {
							padding: 20px;
							display: flex;
							flex-direction: column;
							// background: #000000;
							@include transition(all $speed ease-in);
							@include min(sm) {
								padding: 40px;
							}
							.headline {
								// color: $white;
								margin: 0;
								margin-top: auto;
								@include transition(all $speed ease-in);
							}
							.ce_text {
								// color: $white;
								@include transition(all $speed ease-in);
								> * {
									&:first-of-type {
										padding-top: 30px;
									}
								}
							}
						}
					}
				}
				&:hover {
				}
			}
		}
		/* special position */
		.owl-nav {
			@include min(lg) {
				position: absolute;
				top: -60px;
				right: 13%;
			}
		}
	}


	/* only slider general styles */
	.owl-carousel {
		.owl-nav {
			// width: 100%;
			button {
				&.owl-prev, &.owl-next {
					width: 72px;
					height: 26px;
					border: none;
					font-size: 0;
					z-index: 999;
					-webkit-box-shadow: none;
					-moz-box-shadow: none;
					box-shadow: none;
					background-size: 100% 100%;
					background-repeat: no-repeat;
					background-position: center;
					margin-right: 2rem;
					@include transition(all $speed ease-in);
					@include min(lg) {
						width: 72px;
						height: 26px;
						margin-right: 10px;
					}
					&.disabled {
						opacity: 0.3;
						&:hover{
							opacity: 0.3;
						}
					}
				}
				&.owl-prev {
					background-image: url("data:image/svg+xml,%3Csvg%20id%3D'Button3-Pfeil-Gross'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'72'%20height%3D'26'%20viewBox%3D'0%200%2072%2026'%3E%3Crect%20x%3D'1'%20y%3D'1'%20width%3D'70'%20height%3D'24'%20rx%3D'4'%20ry%3D'4'%20stroke%3D'%23ffffff'%20stroke-width%3D'2'%20fill%3D'none'/%3E%3Cpath%20d%3D'M64.2%2C12.2a.967.967%2C0%2C0%2C1%2C0%2C1.4L58%2C19.8l-1.4-1.4L61%2C14H8.7V12H61.2L56.7%2C7.5l1.4-1.4%2C6.1%2C6.1'%20fill%3D'%23ffffff'/%3E%3C%2Fsvg%3E");
					transform: rotateY(180deg);
					&:hover:not(.disabled){
						opacity: 0.3;
						background-image: url("data:image/svg+xml,%3Csvg%20id%3D'Button3-Pfeil-Gross'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'72'%20height%3D'26'%20viewBox%3D'0%200%2072%2026'%3E%3Crect%20x%3D'1'%20y%3D'1'%20width%3D'70'%20height%3D'24'%20rx%3D'4'%20ry%3D'4'%20stroke%3D'%23ffffff'%20stroke-width%3D'2'%20fill%3D'none'/%3E%3Cpath%20d%3D'M64.2%2C12.2a.967.967%2C0%2C0%2C1%2C0%2C1.4L58%2C19.8l-1.4-1.4L61%2C14H8.7V12H61.2L56.7%2C7.5l1.4-1.4%2C6.1%2C6.1'%20fill%3D'%23ffffff'/%3E%3C%2Fsvg%3E");
					}
				}
				&.owl-next {
					background-image: url("data:image/svg+xml,%3Csvg%20id%3D'Button3-Pfeil-Gross'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'72'%20height%3D'26'%20viewBox%3D'0%200%2072%2026'%3E%3Crect%20x%3D'1'%20y%3D'1'%20width%3D'70'%20height%3D'24'%20rx%3D'4'%20ry%3D'4'%20stroke%3D'%23ffffff'%20stroke-width%3D'2'%20fill%3D'none'/%3E%3Cpath%20d%3D'M64.2%2C12.2a.967.967%2C0%2C0%2C1%2C0%2C1.4L58%2C19.8l-1.4-1.4L61%2C14H8.7V12H61.2L56.7%2C7.5l1.4-1.4%2C6.1%2C6.1'%20fill%3D'%23ffffff'/%3E%3C%2Fsvg%3E");
					margin-right: 0;
					float: right;
					@include min(md) {
						float: inherit;
					}
					&:hover:not(.disabled){
						opacity: 0.3;
						background-image: url("data:image/svg+xml,%3Csvg%20id%3D'Button3-Pfeil-Gross'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'72'%20height%3D'26'%20viewBox%3D'0%200%2072%2026'%3E%3Crect%20x%3D'1'%20y%3D'1'%20width%3D'70'%20height%3D'24'%20rx%3D'4'%20ry%3D'4'%20stroke%3D'%23ffffff'%20stroke-width%3D'2'%20fill%3D'none'/%3E%3Cpath%20d%3D'M64.2%2C12.2a.967.967%2C0%2C0%2C1%2C0%2C1.4L58%2C19.8l-1.4-1.4L61%2C14H8.7V12H61.2L56.7%2C7.5l1.4-1.4%2C6.1%2C6.1'%20fill%3D'%23ffffff'/%3E%3C%2Fsvg%3E");
					}
				}
			}
		}
	}
}