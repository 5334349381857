.content-swiper {
	padding: 0;
	.swiper {
		overflow: visible;
		width: calc(100% - 30px);
		// width: 100%;
		margin-left: 0;
		@include min(md) {
			width: calc(100% - 200px);
		}
		.swiper-wrapper {
			// transform: translate3d(-100px, 0px, 0px) !important;
			// gap: 12px;
			.swiper-slide {
				&:not(:first-of-type) {
					// padding-left: 12px;
					@include min(max) {
						// padding-left: 0;
					}
				}
				.content-image {
					figure {
						img {
							margin: 0;
							width: calc(100% - 12px);
						}
					}
				}
			}
		}
		.swiper-button-prev, .swiper-button-next {
			position: relative;
			width: 72px;
			height: 26px;
			background-position: center;
			background-repeat: no-repeat;
			background-size: 100% 100%;
			margin-top: 22px;
			@include transition(all $speed ease-in);
			background-color: unset;
			float: left;
			left: 0;
			right: 0;
			border: 0;
			padding: 0;
			@include min(md) {
				width: 72px;
				height: 26px;
				margin-right: 10px;
				margin-top: 35px;
				margin-bottom: 8px;
			}
			&.swiper-button-disabled {
				opacity: 0.3;
				&:hover{
					opacity: 0.3;
				}
			}
			&:after {
				content: none;
			}

		}
		.swiper-button-prev {
			background-image: url("data:image/svg+xml,%3Csvg%20id%3D'Button3-Pfeil-Gross'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'72'%20height%3D'26'%20viewBox%3D'0%200%2072%2026'%3E%3Crect%20x%3D'1'%20y%3D'1'%20width%3D'70'%20height%3D'24'%20rx%3D'4'%20ry%3D'4'%20stroke%3D'%2316141d'%20stroke-width%3D'2'%20fill%3D'none'/%3E%3Cpath%20d%3D'M64.2%2C12.2a.967.967%2C0%2C0%2C1%2C0%2C1.4L58%2C19.8l-1.4-1.4L61%2C14H8.7V12H61.2L56.7%2C7.5l1.4-1.4%2C6.1%2C6.1'%20fill%3D'%2316141d'/%3E%3C%2Fsvg%3E");

			transform: rotateY(180deg);
			margin-left: calc(100% - 180px);
			@include min(md) {
				margin-left: calc(100% - 166px);
			}

			&:hover:not(.swiper-button-disabled){
				background-image: url("data:image/svg+xml,%3Csvg%20id%3D'Button3-Pfeil-Gross'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'72'%20height%3D'26'%20viewBox%3D'0%200%2072%2026'%3E%3Crect%20x%3D'1'%20y%3D'1'%20width%3D'70'%20height%3D'24'%20rx%3D'4'%20ry%3D'4'%20stroke%3D'%2316141d'%20stroke-width%3D'2'%20fill%3D'%2316141d'/%3E%3Cpath%20d%3D'M64.2%2C12.2a.967.967%2C0%2C0%2C1%2C0%2C1.4L58%2C19.8l-1.4-1.4L61%2C14H8.7V12H61.2L56.7%2C7.5l1.4-1.4%2C6.1%2C6.1'%20fill%3D'%23ffffff'/%3E%3C%2Fsvg%3E");
			}

		}
		.swiper-button-next {
			background-image: url("data:image/svg+xml,%3Csvg%20id%3D'Button3-Pfeil-Gross'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'72'%20height%3D'26'%20viewBox%3D'0%200%2072%2026'%3E%3Crect%20x%3D'1'%20y%3D'1'%20width%3D'70'%20height%3D'24'%20rx%3D'4'%20ry%3D'4'%20stroke%3D'%2316141d'%20stroke-width%3D'2'%20fill%3D'none'/%3E%3Cpath%20d%3D'M64.2%2C12.2a.967.967%2C0%2C0%2C1%2C0%2C1.4L58%2C19.8l-1.4-1.4L61%2C14H8.7V12H61.2L56.7%2C7.5l1.4-1.4%2C6.1%2C6.1'%20fill%3D'%2316141d'/%3E%3C%2Fsvg%3E");
			float: right;
			margin-left: 10px;
			margin-right: 12px;
			@include min(md) {
				margin-right: 0;
				float: inherit;
				margin-left: 4rem;
			}
			&:hover:not(.swiper-button-disabled){
				background-image: url("data:image/svg+xml,%3Csvg%20id%3D'Button3-Pfeil-Gross'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'72'%20height%3D'26'%20viewBox%3D'0%200%2072%2026'%3E%3Crect%20x%3D'1'%20y%3D'1'%20width%3D'70'%20height%3D'24'%20rx%3D'4'%20ry%3D'4'%20stroke%3D'%2316141d'%20stroke-width%3D'2'%20fill%3D'%2316141d'/%3E%3Cpath%20d%3D'M64.2%2C12.2a.967.967%2C0%2C0%2C1%2C0%2C1.4L58%2C19.8l-1.4-1.4L61%2C14H8.7V12H61.2L56.7%2C7.5l1.4-1.4%2C6.1%2C6.1'%20fill%3D'%23ffffff'/%3E%3C%2Fsvg%3E");
			}

		}
		.swiper-pagination {
			display: none;
		}
	}
}