/* -----------------------------------
FOOTER
----------------------------------- */
#footer {
    background-color: $red;
    > * {
        color: $black;
        text-transform: uppercase;
    }
    a {
        position: relative;
        color: $black;
        text-decoration: none;
        @include transition(color $speed ease-in);
        svg {
            width: auto;
            height: 27px;
            margin: auto 0;
            path, rect, polygon {
                @include transition(all $speed ease-in);
                fill: $white;
            }
        }
        &:after {
            position: absolute;
            content: "";
            height: 2px;
            width: 0;
            bottom: -2px;
            left: 0;
            background: $black;
            @include transition(all $speed ease-in);
        }
        &:hover {
            // color: $white;
            svg {
                path, rect, polygon {
                    fill: $black;
                }
            }
            &::after {
                width: 100%;
            }
        }
    }
    p {
        // margin: .2em 0;
        margin: 0;
    }
    .inside {
        .container {
            &.in_Grid {
                @include min(md) {
                    // padding-left: 0;
                    // padding-right: 0;
                }

            }
            .row {
                display: flex;
                .footer {
                    width: 100%;
                    padding: 22px 0;
                    display: flex;
                    flex-direction: column;
                    border-top: 2px solid $black;
                    @include min(md) {
                        padding: 35px 0;
                    }
                    @include min(xxl) {
                        display: block;
                    }
                    /* logo, phone, mail */
                    .footer-top {
                        display: flex;
                        flex-direction: column;
                        @include min(md) {
                            display: block;
                        }
                        @include min(xxl) {
                            display: flex;
                            flex-direction: row;
                        }
                        .logo {
                            @include min(xxl) {
                                @include flex(0,1,40%);
                            }
                            @include min(max) {
                                @include flex(0,1,50%);
                            }
                            a {
								&:after {
									content: none;
								}
                            }
                        }
                        .phone {
                            margin-top: 60px;
                            @include min(md) {
                                width: 50%;
                                float: left
                            }
                            @include min(xxl) {
                                @include flex(0,1,30%);
                                margin-top: 0;
                            }
                            @include min(max) {
                                @include flex(0,1,25%);
                            }
                            p {
                                margin: 0;
                                font-family: $FactoryFont-Bold;
                                @include font-size(1.9);
                            }
                        }
                        .mail {
                            margin-top: 30px;
                            @include min(md) {
                                width: 50%;
                                float: left;
                                margin-top: 60px;
                            }
                            @include min(xxl) {
                                @include flex(0,1,30%);
                                margin-top: 0;
                                text-align: right;
                            }
                            @include min(max) {
                                @include flex(0,1,25%);
                            }
                            p {
                                margin: 0;
                                font-family: $FactoryFont-Bold;
                                @include font-size(1.9);
                            }
                        }
                    }
                    /* adress & sozials & impr */
                    .footer-right {
                        margin-top: 60px;
                        @include min(md) {
                            display: flex;
                        }
                        @include min(xl) {
                            margin-top: 55px;
                        }
                        @include min(xxl) {
                            margin-left: 40%;
                            margin-top: 100px;
                        }
                        @include min(max) {
                            margin-left: 50%;
                            margin-top: 100px;
                        }
                        .adress {
                            @include min(md) {
                                width: 50%;
                            }
                            @include min(xxl) {
                                width: 50%;
                                float: left;
                            }
                            @include min(max) {
                                width: 50%;
                            }
                            p {
                                @include font-size(1.9);
                                line-height: 34px;
                            }
                        }
                        .social {
                            width: 50%;
                            float: left;
                            margin-top: 30px;
                            @include min(md) {
                                width: 20%;
                                margin-top: 0;
                            }
                            @include min(lg) {
                                width: 20%;
                            }
                            @include min(xxl) {
                                width: 30%;
                            }
                            p {
                                @include font-size(1.8);
                                line-height: 34px;
                                a {
                                    img {
                                        width: 21px;
                                        height: 21px;
                                        margin-right: 8px;
                                        margin-bottom: -4px;
                                        @include transition(all $speed ease-in);
                                    }
                                    &:after {
                                        left: 30px;
                                    }
                                    &:hover {
                                        img {
                                            // filter: invert(1);
                                        }
                                        &::after {
                                            width: calc(100% - 30px);
                                        }
                                    }
                                }
                                /* LinkedIn */
                                &:nth-of-type(1) {
                                    a {
                                        &:hover {
                                            img {
                                                content: url("/files/images/layout/icons/BF-Icon-Linkedin-hover.svg");
                                            }
                                        }
                                    }
                                }
                                /* Instagram */
                                &:nth-of-type(2) {
                                    a {
                                        &:hover {
                                            img {
                                                content: url("/files/images/layout/icons/BF-Icon-Instagram-hover.svg");
                                            }
                                        }
                                    }
                                }
                                /* Facebook */
                                &:nth-of-type(3) {
                                    a {
                                        &:hover {
                                            img {
                                                content: url("/files/images/layout/icons/BF-Icon-FB-hover.svg");
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .impress {
                            display: flex;
                            align-items: end;
                            margin-top: 30px;
                            @include min(md) {
                                width: 20%;
                                margin-top: 0;
                            }
                            @include min(lg) {
                                width: 20%;
                            }
                            @include min(xxl) {
                                width: 20%;
                            }
                            p {
                                @include font-size(1.8);
                                line-height: 34px;
                            }
                        }

                    }
                    /* copyright */
                    .footer-bottom {
                        margin-top: 30px;
                        order: 2;
                        @include min(md) {
                            display: flex;
                        }
                        @include min(xxl) {
                            width: 40%;
                            margin-top: -32px;
                            float: left;
                            height: 100%;
                            order: 0;
                        }
                        @include min(max) {
                            width: 50%;
                        }
                        .copy {
                            float: left;
                            @include font-size(1.9);
                            @include min(md) {
                                margin-top: auto;
                            }
                        }
                    }
                }
            }
        }
        .footer-icons {
            display: none;
            @include min(md) {
                display: block;
            }
            .toTop {
                position: fixed;
                width: 50px;
                height: 50px;
                bottom: 30px;
                right: -100px;
                z-index: 999;
                @include transition(left, $speed-scrolling ease);
                // opacity: .5;
                .content-hyperlink {
                    padding: 0;
                    a {
                        &:hover {
                            opacity: .8;
                        }
                    }
                    img {
                        width: 50px;
                        height: 100%;
                    }
                }
            }
            &.is-scrolled {
                .toTop {
                    right: 35px;
                }
            }
        }
    }
}


body {
    &.cases {
        #footer {
            background-color: $black;
            > * {
                color: $grey_2;
            }
            a {
                color: $grey_2;
                svg {
                    path, rect, polygon {
                        fill: $red;
                    }
                }
                &:after {
                    background: $grey_2;
                }
                &:hover {
                    // color: $red;
                    svg {
                        path, rect, polygon {
                            fill: $grey_2;
                        }
                    }
                }
            }
            .inside {
                .container {
                    .row {
                        .footer {
                            border-top: 2px solid $grey_2;
                            .footer-right {
                                .social {
                                    p {
                                        a {
                                            img {
                                                filter: invert(1);
                                            }
                                            &:hover {
                                                img {
                                                    filter: invert(1);
                                                }
                                            }
                                        }
                                        /* LinkedIn */
                                        &:nth-of-type(1) {
                                            a {
                                                &:hover {
                                                    img {
                                                        content: url("/files/images/layout/icons/BF-Icon-Linkedin-hover-cases.svg");
                                                    }
                                                }
                                            }
                                        }
                                        /* Instagram */
                                        &:nth-of-type(2) {
                                            a {
                                                &:hover {
                                                    img {
                                                        content: url("/files/images/layout/icons/BF-Icon-Instagram-hover-cases.svg");
                                                    }
                                                }
                                            }
                                        }
                                        /* Facebook */
                                        &:nth-of-type(3) {
                                            a {
                                                &:hover {
                                                    img {
                                                        content: url("/files/images/layout/icons/BF-Icon-FB-hover-cases.svg");
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}