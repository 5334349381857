.bg-red {
  background-color: $red;
  > * {
      color: $white;
  }
  p, li, th, td, a {
      color: $white;
  }
}
/* hero header */
.special-bg {
	background: linear-gradient(180deg, rgba(234,240,242,1) 0%, rgba(234,240,242,1) 100vh, rgba(255,255,255,1) 100vh, rgba(255,255,255,1) 100%);
}
.bg-white-to-grey-50 {
	background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(234,240,242,1) 50%, rgba(234,240,242,1) 100%);
}
.bg-grey-to-white-50 {
	background: linear-gradient(180deg, rgba(234,240,242,1) 0%, rgba(234,240,242,1) 50%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
}

.bg-grey {
  background-color: $grey;
}