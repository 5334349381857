/* -----------------------------------
NAVIGATION
----------------------------------- */
html.mobile_menu_active {
    .mod_mobile_menu {
        .nav_toggle {
            &:hover {
                > div {
                    background-color: $black;
                }
            }
        }
    }
    #header {
        .header-bar {
            margin-top: 0!important;
            .container {
                .row {
                    .text {
                        display: none;
                    }
                }
            }
        }
    }
}
.mobile_menu {
    .inner {
        background-color: $red;
        overflow: auto;
        @include min(lg) {
            display: flex;
        }
        .menu-left {
            display: none;
            @include min(lg) {
                display: block;
                @include flex(0,1,50%);
            }
            .content-text {
                @include min(lg) {
                    margin-left: 35px;
                    height: 100%;
                    display: flex;
                }
                .rte {
                    @include min(lg) {
                        margin-top: auto;
                        margin-bottom: 35px;
                        color: $grey_2;
                    }
                    a {
                        @include min(lg) {
                            color: $grey_2;
                            text-decoration: none;
                            @include transition(color $speed ease-in);
                            &:hover {
                                color: $black;
                            }
                        }
                    }
                }
            }
        }
        .menu-right {
            display: flex;
            flex-direction: column;
            padding-top: 90px;
            padding-left: 11px;
            padding-right: 11px;
            min-height: 100%;
            @include min(md) {
                padding-left: 35px;
                padding-right: 35px;
            }
            @include min(lg) {
                @include flex(0,1,50%);
                padding-top: 75px;
                padding-left: 129px;
                padding-right: 0;
                min-height: auto;
            }
            a, strong {
                &:not(.invisible) {
                    position: relative;
                    text-decoration: none;
                    color: $grey_2;
                    // text-transform: uppercase;
                }
            }
            a {
                @include transition(color $speed ease-in);
                &::before {
                    @include min(lg) {
                        position: absolute;
                        content: "";
                        height: 2px;
                        width: 0;
                        bottom: 20px;
                        left: 101%;
                        background: $black;
                        @include transition(all $speed ease-in);
                    }
                }
                &:hover {
                    color: $black;
                    &::before {
                        @include min(lg) {
                            width: 150%;
                        }
                    }
                }
            }
            /* activ style */
            .mod_navigation, .mod_customnav {
                ul {
                    li {
                        &.active {
                            strong {
                                // opacity: .7;
                                color: $black;
                                font-family: $FactoryFont-Medium;
                                font-weight: normal;
                            }
                        }
                    }
                }
            }
            /* MainNavigation */
            .mod_navigation {
                .level_1 {
                    // width: fit-content;
                    padding-left: 0;
                    @include min(lg) {
                        margin-right: 35px;
                    }
                    > li {
                        &:nth-of-type(1) {
                            display: none;
                        }
                        /* font-size */
                        font-family: $FactoryFont-Medium;
                        font-weight: normal;
                        @include font-size(4.8);
                        line-height: 80px;
                        text-align: right;
                        @include min(md) {
                            text-align: left;
                        }
                        @include min(lg) {
                            @include font-size(7.0);
                            line-height: 80px;
                            overflow: hidden;
                        }
                        @include min(xl) {
                            @include font-size(9.0);
                            line-height: 110px;
                        }

                        /* open menu on hover */
                        &:hover, &:focus, &:focus-visible, &:focus-within {
                            ul.level_2 {
                                @include transition(all $speed ease-in);
                                margin-top: 0;
                                opacity: 1;
                            }
                        }
                        &:before {
                            content: none;
                        }
                    }
                }
                .level_2 {
                    display: flex;
                    padding-left: 0;
                    @include min(lg) {
                        opacity: 0;
                        margin-top: -55px;
                        @include transition(all $speed ease-in);
                    }
                    > li {
                        /* font-size */
                        font-family: $FactoryFont-Medium;
                        font-weight: normal;
                        @include font-size(1.7);
                        line-height: 26px;
                        padding-right: 21px;
                        padding-left: 3px;
                        margin-bottom: 20px;

                        @include min(lg) {
                            @include font-size(2.0);
                            line-height: 30px;
                            padding-right: 35px;
                            padding-left: 5px;
                            margin-bottom: 25px;
                        }
                        &:before {
                            content: none;
                        }
                        
                    }
                }
            }
            /* FooterNavigation like Imprint ect.*/
            .menu-right-contact {
                margin-top: auto;
                margin-bottom: 35px;
                @include min(lg) {
                    margin-top: auto;
                    margin-bottom: 35px;
                }
                .mod_customnav {
                    ul {
                        display: flex;
                        padding-left: 0;
                        li {
                            width: 100%;
                            @include font-size(1.7);
                            text-transform: uppercase;
                            @include min(lg) {
                                @include font-size(2.0);
                            }
                            &:nth-child(2) {
                                text-align: right;
                                @include min(md) {
                                    text-align: left;
                                }
                            }
                            &:before {
                                content: none;
                            }
                            a {
                                &:before {
                                    content: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}