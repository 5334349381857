@mixin padding {
    padding-left: $gutter;
    padding-right: $gutter;
    @include min(sm) {
        padding-left: $gutter-sm;
        padding-right: $gutter-sm;
    }
    @include min(lg) {
        padding-left: $gutter-lg;
        padding-right: $gutter-lg;
    }
}

@mixin font-size($sizeValue: 1) {
	font-size: ($sizeValue * 10) + px;
	// font-size: $sizeValue + rem;
}

@mixin transition($args...) {
	-webkit-transition: $args;
	-moz-transition: $args;
	-ms-transition: $args;
	-o-transition: $args;
	transition: $args;
}

@mixin min($point) {
    @if $point == xs {
        @media (min-width: 375px) { @content; }
    }
    @else if $point == sm {
        @media (min-width: 768px) { @content; }
    }
    @else if $point == md {
        @media (min-width: 992px) { @content; }
    }
    @else if $point == lg {
        @media (min-width: 1200px) { @content; }
    }
    @else if $point == xl {
        @media (min-width: 1400px)  { @content; }
    }
    @else if $point == xxl {
        @media (min-width: 1600px)  { @content; }
    }
	@else if $point == max {
		@media (min-width: 1920px)  { @content; }
        // Safari
        body.safari & {
            @media (min-width: 1905px) { @content; }
        }
        // Edge
        body.edge & {
            @media (min-width: 1900px) { @content; }
        }
	}
	@else if $point == 2k {
		@media (min-width: 2048px)  { @content; }
	}
}

@mixin max($point) {
    @if $point == xs {
        @media (max-width: 374px) { @content; }
    }
    @else if $point == sm {
        @media (max-width: 767px) { @content; }
    }
    @else if $point == md {
        @media (max-width: 991px) { @content; }
    }
    @else if $point == lg {
        @media (max-width: 1199px) { @content; }
    }
    @else if $point == xl {
        @media (max-width: 1399px)  { @content; }
    }
    @else if $point == xxl {
        @media (max-width: 1599px)  { @content; }
    }
	@else if $point == max {
		@media (max-width: 1919px)  { @content; }
        // Safari
        body.safari & {
            @media (max-width: 1904px) { @content; }
        }
        // Edge
        body.edge & {
            @media (max-width: 1899px) { @content; }
        }
	}
	@else if $point == 2k {
		@media (max-width: 2047px)  { @content; }
	}
}

@mixin iconmoon {
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin flexbox {
  -js-display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex($grow, $shrink, $basis) {
  -webkit-box-flex: $grow $shrink $basis;
  -moz-box-flex: $grow $shrink $basis;
  -webkit-flex: $grow $shrink $basis;
  -ms-flex:  $grow $shrink $basis;
  flex: $grow $shrink $basis;
  flex-basis: $basis;
}


@mixin padding-case {
    padding-top: 11px;
    padding-bottom: $padding-case;
    @include min(md) {
        padding-top: 14px;
        padding-bottom: $padding-case-md;
    }
    @include min(max) {
        padding-bottom: $padding-case-max;
    }
}