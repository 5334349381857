.ce_form{
	.widget{
		input:not(.checkbox), select, textarea{
			width: 100%;
		    -webkit-box-sizing: border-box;
		    -moz-box-sizing: border-box;
		    box-sizing: border-box;
            font-family: $FactoryFont-Medium;
		    margin-bottom: 20px;
		    border: none;
		    border-bottom: 2px solid $black;
		    padding: 10px 10px;
		    padding-left: 0px;
	    	background-color: transparent;
		    @include transition(all, $speed ease-in);
			
		    &::placeholder{
		    	color: $black;
		    }
			@include min(md) {
				margin-bottom: 80px;
			}
		}
		input:not(.checkbox), select {
			height: 52px;
		}
		fieldset{
            font-family: $FactoryFont-Medium;
		    // @include font-size(1.4);
		    border: none;
		    padding: 10px 0px;
		    @include min(md) {
			    label{
			    	padding-left: 20px;
			    }
			    input{
			    	// transform: scale(1.2);
			    }
			    span{
			    	display: flex;
			    }
		    }

		}
		input:not(.checkbox), select, textarea{
			&:focus{
				outline: none;
		    	border-bottom: 2px solid $red;
			}
		}
	}
	/* hover animation */
	.widget-text, .widget-select{
        &:after {
            content: '';
            display: block;
            width: 0;
            height: 2px;
            background: $red;
            position: relative;
            top: -22px;
            left: 0;
            @include transition(width $speed ease-in);
			@include min(md) {
				top: -82px;
			}
		}
	    &:hover{
    		&:after {
	    		width: 100%;
	    	}
	    }
	}
	/* hover animation TEXTAREA*/
	// remove because of different browsers
	.widget-textarea{
		/*
        &:after {
            content: '';
            display: block;
            width: 0;
            height: 2px;
            background: $red;
            position: relative;
            top: -29px;
            left: 0;
            @include transition(width $speed ease-in);
			@include min(md) {
				top: -92px;
			}
		}
	    &:hover{
    		&:after {
	    		width: 100%;
	    	}
	    }
		*/
	}
	/* checkbox animation */
	.widget-select{
		select{
			color: $black;
		    -moz-appearance:none; /* Firefox */
		    -webkit-appearance:none; /* Safari and Chrome */
		    appearance:none;
		    background-image: url("data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20width='24'%20height='24'%20viewBox='0%200%2024%2024'%20fill='none'%20stroke='%23141414'%20stroke-width='1'%20stroke-linecap='round'%20stroke-linejoin='round'%20class='feather%20feather-chevron-down'%3E%3Cpolyline%20points='6%209%2012%2015%2018%209'%3E%3C/polyline%3E%3C/svg%3E");
		    background-position-x: 100%;
			background-position-y: 100%;
			background-size: 40px;
			background-repeat: no-repeat;
		    &:hover{
		    	// background: url(../../../files/images/icons/chevron-down-magenta.svg) no-repeat right;
		    }
			option {
				padding: 0 20px;
			}
		}
	}
	.widget-checkbox {
		span {
			display: flex;
			position: relative;
			@include min(md) {
				margin-top: 10px;
			}
			input {
				min-width: 30px;
				height: 30px;
				@include min(md) {
					min-width: 35px;
					margin-right: 35px;
					height: 35px;
				}
				opacity: 0;
			}
			label {
				line-height: 22px;
				@include font-size(1.4);
				padding-left: 20px;
				@include min(md) {
					padding-left: 0;
				}
				a {
					padding-right: 0;
					text-decoration: underline;
					&:after {
						content: none;
					}
				}
				&::before {
					content: "";
					position: absolute;
					top: 4px;
					left: 0;
					width: 30px;
					height: 30px;
					background-image: url("data:image/svg+xml,%3Csvg%20id%3D'a'%20data-name%3D'Ebene%201'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2035%2035'%3E%3Crect%20x%3D'0'%20y%3D'0'%20width%3D'35'%20height%3D'35'%20rx%3D'4'%20ry%3D'4'%20fill%3D'none'/%3E%3Cpath%20d%3D'M31%2C35H4C1.79%2C35%2C0%2C33.21%2C0%2C31V4C0%2C1.79%2C1.79%2C0%2C4%2C0h27C33.21%2C0%2C35%2C1.79%2C35%2C4v27c0%2C2.21-1.79%2C4-4%2C4ZM4%2C2c-1.1%2C0-2%2C.9-2%2C2v27c0%2C1.1.9%2C2%2C2%2C2h27c1.1%2C0%2C2-.9%2C2-2V4c0-1.1-.9-2-2-2H4Z'%20fill%3D'%2316141d'/%3E%3C%2Fsvg%3E");
					@include min(md) {
						width: 35px;
						height: 35px;
					}
				}
			}
			input:checked + label::before {
				content: "";
				background-image: url("data:image/svg+xml,%3Csvg%20id%3D'a'%20data-name%3D'Ebene%201'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2035%2035'%3E%3Crect%20y%3D'0'%20width%3D'35'%20height%3D'35'%20rx%3D'4'%20ry%3D'4'%20fill%3D'%2316141d'/%3E%3Cpath%20d%3D'M12.79%2C26.73c-.26%2C0-.51-.1-.71-.29l-6.02-6.02%2C1.41-1.41l5.32%2C5.31%2C15.33-15.33%2C1.41%2C1.41L13.49%2C26.43c-.2.2-.45.29-.71.29Z'%20fill%3D'%23fafafd'/%3E%3C%2Fsvg%3E");
			}
		}
	}
	/* REMOVE AUTOFILL BLUE BACKGROUND */
	input:-webkit-autofill,
	input:-webkit-autofill:hover, 
	input:-webkit-autofill:focus, 
	input:-webkit-autofill:active  {
	    -webkit-box-shadow: 0 0 0 30px white inset !important;
	}


	/* SUBMIT Absende-Button */
	.widget-submit {
		margin-top: 40px;
		min-width: 200px;
		padding-bottom: 5px;
		@include min(md) {
			margin-top: 0;
			margin-left: auto;
			margin-right: 5px;
		}
		@include max(xl) {
			grid-column: span 12 / span 12;
		}
		button.submit{
			all: unset;
			text-transform: uppercase;
		    @include transition(all, $speed ease-in);
			cursor: pointer;
			position: relative;
			@include min(md) {
				margin-top: 40px;
			}
		    &:hover{
				// color: $red;
				@include min(md) {
					&:before {
						width: 100%;
					}
				}
				@include min(md) {
					&:after {
						background-image: url("data:image/svg+xml,%3Csvg%20id%3D'Button3-Pfeil-Gross'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'72'%20height%3D'26'%20viewBox%3D'0%200%2072%2026'%3E%3Crect%20x%3D'1'%20y%3D'1'%20width%3D'70'%20height%3D'24'%20rx%3D'4'%20ry%3D'4'%20stroke%3D'%2316141d'%20stroke-width%3D'2'%20fill%3D'%2316141d'/%3E%3Cpath%20d%3D'M64.2%2C12.2a.967.967%2C0%2C0%2C1%2C0%2C1.4L58%2C19.8l-1.4-1.4L61%2C14H8.7V12H61.2L56.7%2C7.5l1.4-1.4%2C6.1%2C6.1'%20fill%3D'%23ffffff'/%3E%3C%2Fsvg%3E");
					}
				}
		    }
			&:before {
				position: absolute;
				content: "";
				height: 2px;
				width: 100%;
				bottom: 0px;
				left: 0;
				background: $black;
				@include transition(all $speed ease-in);
				@include min(md) {
					width: 0%;
					bottom: -4px;
				}
			}
			&:after {
				position: absolute;
				content: "";
				background-image: url("data:image/svg+xml,%3Csvg%20id%3D'Button3-Pfeil-Gross'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'72'%20height%3D'26'%20viewBox%3D'0%200%2072%2026'%3E%3Crect%20x%3D'1'%20y%3D'1'%20width%3D'70'%20height%3D'24'%20rx%3D'4'%20ry%3D'4'%20stroke%3D'%2316141d'%20stroke-width%3D'2'%20fill%3D'none'/%3E%3Cpath%20d%3D'M64.2%2C12.2a.967.967%2C0%2C0%2C1%2C0%2C1.4L58%2C19.8l-1.4-1.4L61%2C14H8.7V12H61.2L56.7%2C7.5l1.4-1.4%2C6.1%2C6.1'%20fill%3D'%2316141d'/%3E%3C%2Fsvg%3E");
				background-size: 100% 100%;
				background-repeat: no-repeat;
				background-position: center;
				@include transition(all $speed ease-in);            
				height: 21px;
				width: 40px;
				margin-left: 16px;
				margin-top: -1px;
			}
			@include min(md) {
				&:after {
					height: 26px;
					width: 72px;
					margin-left: 16px;
					margin-top: 0px;
				}
			}
		}
	}
}

#main {
	.inside {
		.formbody {
			.row {
				display: block;
				@include min(xxl) {
					display: grid;
					gap: 30px;
				}
			}
		}
	}
}

/* FIX FIREFOX red borders */
body.firefox{
	.contact-form {
		/*
		.widget-textarea{
	        &:after {
	            top: -23px;
				content: none;
				@include min(md) {
					top: -84px;
				}
			}
		}
		*/
		input:required, textarea:required {
		    box-shadow:none;
		}
	}
}