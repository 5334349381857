/*!
 * Bootstrap Grid v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@-ms-viewport {
  width: device-width;
}
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.full_width {
  width: 100%;
  max-width: 100%;
}

.in_Grid {
  width: 100%;
  padding-right: 11px;
  padding-left: 11px;
  margin-right: auto;
  margin-left: auto;
  @include min(md) {
    width: 100%;
    padding-right: 35px;
    padding-left: 35px;
	}
}
.flex-column {
  display: flex;
  flex-direction: column;
}

.mod_article {
  & > * {
    width: 100%;
    padding-right: 11px;
    padding-left: 11px;
    margin-right: auto;
    margin-left: auto;
    @include min(md) {
      width: 100%;
      padding-right: 35px;
      padding-left: 35px;
      margin-right: auto;
      margin-left: auto;
    }
    @include min(max) {
      // max-width: 1920px;
      width: 100%;
      padding-right: 35px;
      padding-left: 35px;
      margin-right: auto;
      margin-left: auto;
    }
  }
  &.full_width > * {
    width: 100%;
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;
    @include min(md) {
    }
	}
}

/*
.mod_newsreader {
  @include min(max) {
    width: 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .layout_full_customers {
    > * {
      > * {
        @include min(max) {
          max-width: 1920px;
          padding-right: 35px;
          padding-left: 35px;
          margin-right: auto;
          margin-left: auto;
        }
      }
    }
  }
}
*/

#wrapper {
  #container{
    #main {
      .inside {
        > * {
          padding-top: 8px;
          padding-bottom: 90px;
          @include min(md) {
            padding-top: 30px;
            padding-bottom: 160px;
          }
          @include min(max) {
              // padding-top: 90px;
              padding-bottom: 280px;
          }
          &:first-child {
            padding-top: 80px;
          }
          &:last-child {
            // padding-bottom: 0;
          }
          &.cases {
            &:first-child {
              padding-top: 80px;
              padding-bottom: 0;
            }

          }
        }
      }
    }
  }
}
/*
#footer {
  .inside {
    .in_Grid {
      @include min(max) {
        max-width: 1920px;
        padding-right: 35px;
        padding-left: 35px;
        margin-right: auto;
        margin-left: auto;
      }
    }
  }
}
*/