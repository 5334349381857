.ce_accordionStart {
	&:first-of-type {
		.toggler {
			border-top: 1px solid $black;
		}
	}
	.toggler {
		position: relative;
		z-index: 1;
		cursor: pointer;
		padding: 20px 0 44px 0;
		padding-right: 40px;
		border-bottom: 1px solid $black;
		@include transition(all, $speed ease);
		@include min(md) {
			padding: 20px 0 74px 0;
		}

		/* font-style */
		font-family: $FactoryFont-Bold;
		letter-spacing: 0;
		@include font-size(2.8);
		font-weight: 700;
		line-height: 34px;
		@include min(md) {
			@include font-size(3.0);
			line-height: 34px;
		}
		@include min(lg) {
			@include font-size(3.2);
			line-height: 34px;
		}
		@include min(xl) {
			@include font-size(3.4);
			line-height: 40px;
		}
		@include min(xxl) {
			@include font-size(3.4);
			line-height: 40px;
		}
		@include min(max) {
			@include font-size(3.4);
			line-height: 40px;
		}
		/* end font-style */
		&:hover {
			color: $red;
			.ui-accordion-header-icon {
				transform: rotate(-90deg);
				background-image: url("data:image/svg+xml,%3Csvg id='uuid-e5a2d743-9d9d-4c62-877b-98ecdaeeb80a' data-name='uuid-ab0e14ab-e352-4af3-adcb-585c273bf7f4' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19.0292 19.0003'%3E%3Cdefs%3E%3Cstyle%3E.uuid-fe0f8376-7325-4def-906a-f744c38fe563%7Bstroke-width:2.0292px;%7D.uuid-fe0f8376-7325-4def-906a-f744c38fe563,.uuid-ede1580c-e673-4d9b-b04a-5eec5d2a6dd4%7Bfill:none;stroke:%23FF0046;stroke-linecap:round;%7D.uuid-ede1580c-e673-4d9b-b04a-5eec5d2a6dd4%7Bstroke-width:2.0003px;%7D%3C/style%3E%3C/defs%3E%3Cg id='uuid-5393c953-7075-4b99-ba50-f6b89928ed43' data-name='uuid-f2074167-a504-4ef8-9408-e8f04abc5de6'%3E%3Cline id='uuid-085893eb-b02a-4d99-81c8-fc1876f5b1d1' data-name='uuid-62203405-b1fe-4252-9690-165df65e46b0' class='uuid-ede1580c-e673-4d9b-b04a-5eec5d2a6dd4' x1='9.5146' y1='1.0001' x2='9.5146' y2='18.0001'/%3E%3Cline id='uuid-5902f361-0ea9-44a9-bbcd-1bb620dbb61e' data-name='uuid-5140f4f9-bcc8-468c-8aea-c23576ebb0b9' class='uuid-fe0f8376-7325-4def-906a-f744c38fe563' x1='18.0146' y1='9.5001' x2='1.0146' y2='9.5001'/%3E%3C/g%3E%3C/svg%3E");
			}
		}
		&.ui-state-active {
			border-bottom: none;
			// color: $red;
			.ui-accordion-header-icon {
				transform: rotate(-180deg);
				height: 3px;
				width: 23px;
				top: 30px;
				background-image: url("data:image/svg+xml,%3Csvg id='uuid-db37194a-bfea-430d-972b-634d95e18e09' data-name='uuid-521e11ab-b0fd-4378-a331-0c4bf093542a' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17.9686 1.9686'%3E%3Cdefs%3E%3Cstyle%3E.uuid-15490649-c3ca-446f-8c33-e3362d8c6dc6%7Bfill:none;stroke:%23FF0046;stroke-linecap:round;stroke-width:1.9686px;%7D%3C/style%3E%3C/defs%3E%3Cg id='uuid-0f319303-671d-42ef-9e57-57cbdbf1ed76' data-name='uuid-e552ec49-8f5a-428a-8382-8f63987096f4'%3E%3Cline id='uuid-e3bb161d-2c7c-49ce-b2a7-1f197ca8d84b' data-name='uuid-463e1627-aa23-442e-84f1-3073c715c154' class='uuid-15490649-c3ca-446f-8c33-e3362d8c6dc6' x1='16.9843' y1='0.9843' x2='0.9843' y2='0.9843'/%3E%3C/g%3E%3C/svg%3E");

				@include min(md) {
					top: 36px;
					height: 4px;
					width: 27px;
				}
			}
		}
		.ui-accordion-header-icon {
			position: absolute;
			right: 0px;
			top: 20px;
			width: 22px;
			height: 22px;
			background-image: url("data:image/svg+xml,%3Csvg id='uuid-e5a2d743-9d9d-4c62-877b-98ecdaeeb80a' data-name='uuid-ab0e14ab-e352-4af3-adcb-585c273bf7f4' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19.0292 19.0003'%3E%3Cdefs%3E%3Cstyle%3E.uuid-fe0f8376-7325-4def-906a-f744c38fe563%7Bstroke-width:2.0292px;%7D.uuid-fe0f8376-7325-4def-906a-f744c38fe563,.uuid-ede1580c-e673-4d9b-b04a-5eec5d2a6dd4%7Bfill:none;stroke:%23141414;stroke-linecap:round;%7D.uuid-ede1580c-e673-4d9b-b04a-5eec5d2a6dd4%7Bstroke-width:2.0003px;%7D%3C/style%3E%3C/defs%3E%3Cg id='uuid-5393c953-7075-4b99-ba50-f6b89928ed43' data-name='uuid-f2074167-a504-4ef8-9408-e8f04abc5de6'%3E%3Cline id='uuid-085893eb-b02a-4d99-81c8-fc1876f5b1d1' data-name='uuid-62203405-b1fe-4252-9690-165df65e46b0' class='uuid-ede1580c-e673-4d9b-b04a-5eec5d2a6dd4' x1='9.5146' y1='1.0001' x2='9.5146' y2='18.0001'/%3E%3Cline id='uuid-5902f361-0ea9-44a9-bbcd-1bb620dbb61e' data-name='uuid-5140f4f9-bcc8-468c-8aea-c23576ebb0b9' class='uuid-fe0f8376-7325-4def-906a-f744c38fe563' x1='18.0146' y1='9.5001' x2='1.0146' y2='9.5001'/%3E%3C/g%3E%3C/svg%3E");
			background-size: contain;
			@include transition(transform, $speed ease);
			@include min(md) {
				top: 23px;
				width: 30px;
				height: 30px;
			}
		}
	}
	.ui-accordion-content-active {
		border-bottom: 1px solid $black;
	}
}
