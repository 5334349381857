/* -----------------------------------
HEADER
----------------------------------- */
#header {
    .header-bar {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1001;
        height: 80px;
        background: $white;
        @include min(md) {
            @include transition(all $speed ease-in);
        }
        .container {
            .row {
                display: flex;
                .logo {
                    position: absolute;
                    left: 11px;
                    top: 33px;
                    margin: auto 0;
                    line-height: 1;
                    z-index: 101;
                    @include min(md) {
                        top: 0;
                        left: 35px;
                        height: 80px;
                    }
                    a {
                        height: 100%;
                        display: flex;
                        svg {
                            width: auto;
                            height: 15px;
                            margin: auto 0;
                            path, rect, polygon {
                                @include transition(all $speed-mobilemenu ease-in);
                                fill: $red;
                            }
                        }
                        &:hover {
                            svg {
                                path, rect, polygon {
                                    fill: $black;
                                }
                            }
                        }
                    }
                    p {
                        margin: 0;
                        @include min(md) {
                            display: flex;
                            height: 80px;
                        }
                        a {
                            @include font-size(2.4);
                            font-family: $FactoryFont-Bold;
                            color: $red;
                            text-decoration: none;
                            @include transition(all $speed-mobilemenu ease-in);
                            margin: auto 0;
                            @include min(md) {
                                @include font-size(2.9);
                            }
                            &:hover {
                                color: $black;
                            }
                        }
                    }
                }
            }
        }
        &.is-scrolled {
            // background-color: transparent;
            @include min(sm) {
                .container{
                    .row{
                        .text {
                            opacity: 0;
                            margin-top: -50px;
                        }
                    }
                }
            }
        }
    }
}
body {
    &.header-fixed {
        #header {
            .inside {
                .header-bar {
                    @include min(md) {
                        margin-top: -80px;
                    }
                }
            }
        }
        #nav-toggle {
            .nav_toggle {
                @include min(md) {
                    margin-top: -80px;
                }
            }
        }
    }
    &.news-slider-animation {
        #header {
            .inside {
                .header-bar {
                    @include min(md) {
                        margin-top: -80px;
                    }
                }
            }
        }
        #nav-toggle {
            .nav_toggle {
                @include min(md) {
                    margin-top: -80px;
                }
            }
        }
    }
}


// Burger Menu
.nav_toggle {
    all: unset;
    cursor: pointer;
    overflow: visible;
    // position: fixed;
    position: absolute;
    z-index: 100002;
    width: 30px;
    height: 30px;
    top: 25px;
    right: 5px;
    @include min(md) {
        right: 35px;
        @include transition(all $speed ease-in);
    }
    &:hover {
        > div {
            background-color: $black;
        }
        &:before {
            @include min(md) {
                color: $black;
                // color: $white;
            }
        }
    }
    &:focus-visible {
        outline: -webkit-focus-ring-color auto 1px;
        outline: 1px auto Highlight;
        outline: 1px auto $black;
    }
    @include min(md) {
        &:before {
            content: "MENU";
            position: absolute;
            left: -80px;
            top: 4px;
            // color: $red;
            color: transparent;
            padding-right: 30px;
            @include font-size(1.9);
            font-family: $FactoryFont-Medium;
            @include transition(all $speed ease-in);
        }
    }
    @include min(lg) {
        &:before {
            top: 4px;
        }
    }
    @include min(max) {
        &:before {
            top: 4px;
        }
    }
    > div {
        position: absolute;
        width: 24px;
        height: 2px;
        // border-radius: 3px;
        background-color: $red;
        left: calc(50% - 15px);
        top: calc(50% - 1.5px);
        transform-origin: left center;
        @include transition(all $speed ease-in);
        opacity: 1;
        @include min(md) {
            width: 30px;
            height: 3px;
        }
        &:nth-child(1) {
            margin-top: -7px;
            @include min(md) {
                margin-top: -7px;
            }
        }
        &:nth-child(3) {
            margin-top: 7px;
            @include min(md) {
                margin-top: 7px;
            }
        }
        .mobile_menu_active & {
            &:nth-child(1) {
                transform: translate(2px,-2px) rotate(45deg);
                background-color: $white;
                @include min(md) {
                    transform: translate(3px, -3px) rotate(45deg);
                }
            }
            &:nth-child(2) {
                opacity: 0;
            }
            &:nth-child(3) {
                transform: translate(2px,1px) rotate(-45deg);
                background-color: $white;
                @include min(md) {
                    transform: translate(2px, 4px) rotate(-45deg);
                }
            }
        }
    }
}


.mobile_menu_active {
    #header {
        .header-bar {
            background-color: transparent;
            .container {
                .row {
                    .logo {
                        a {
                            color: $white;
                            @include transition(all $speed-mobilemenu ease-in);
                            &:hover {
                                color: $black;
                                svg {
                                    path, rect, polygon {
                                        fill: $black;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .nav_toggle {
        @include min(md) {
            &:before {
                opacity: 0;
                visibility: hidden;
                @include transition(all $speed ease-in);
            }
        }
    }
}

/* header color */
body {
    &.red {
        #header {
            .header-bar {
                @include transition(all $speed ease-in);
                background: $red;
                .container { 
                    .row {
                        .logo {
                            p {
                                a {
                                    color: $white;
                                    &:hover {
                                        color: $black;
                                    }
                                }
                            }
                            a {
                                svg {
                                    path, rect, polygon {
                                        fill: $white;
                                    }
                                }
                                &:hover {
                                    svg {
                                        path, rect, polygon {
                                            fill: $black;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .nav_toggle {
            > div {
                // background-color: $white;
                background-color: $black;
            }
            &:before {
                // color: $white;
                color: transparent;
            }
            &:hover {
                > div {
                    // background-color: $black;
                    background-color: $white;
                }
                @include min(md) {
                    &:before {
                        // color: $black;
                        color: $white;
                    }
                }
            }
        }
    }
}
.mobile_menu_active {
    body {
        &.red {
            #header {
                .header-bar {
                    background: transparent;
                }
            }
        }
    }
}

/* grey */
body {
    &.grey {
        #header {
            .header-bar {
                @include transition(all $speed ease-in);
                background: $grey;
            }
        }
    }
}
.mobile_menu_active {
    body {
        &.grey {
            #header {
                .header-bar {
                    background: transparent;
                }
            }
        }
    }
}
/* grey_2 */
body {
    &.grey_2 {
        #header {
            .header-bar {
                @include transition(all $speed ease-in);
                background: $grey_2;
            }
        }
    }
}
.mobile_menu_active {
    body {
        &.grey_2 {
            #header {
                .header-bar {
                    background: transparent;
                }
            }
        }
    }
}
/* black bg */
body {
    &.black {
        #header {
            .header-bar {
                @include transition(all $speed ease-in);
                background: $black;
                .container { 
                    .row {
                        .logo {
                            p {
                                a {
                                    color: $white;
                                    &:hover {
                                        color: $red;
                                    }
                                }
                            }
                            a {
                                svg {
                                    path, rect, polygon {
                                        fill: $white;
                                    }
                                }
                                &:hover {
                                    svg {
                                        path, rect, polygon {
                                            fill: $red;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .nav_toggle {
            > div {
                background-color: $white;
            }
            &:before {
                color: $white;
            }
            &:hover {
                > div {
                    background-color: $red;
                }
                @include min(md) {
                    &:before {
                        color: $red;
                    }
                }
            }
        }
    }
}
.mobile_menu_active {
    body {
        &.black {
            #header {
                .header-bar {
                    background: transparent;
                }
            }
        }
    }
}

/* black color(not bg) */
body {
    &.nav-black-color {
        #header {
            .header-bar {
                @include transition(all $speed ease-in);
                // background: $red;
                .container {
                    .row {
                        .logo {
                            p {
                                a {
                                    color: $black;
                                    &:hover {
                                        color: $red;
                                    }
                                }
                            }
                            a {
                                svg {
                                    path, rect, polygon {
                                        fill: $black;
                                    }
                                }
                                &:hover {
                                    svg {
                                        path, rect, polygon {
                                            fill: $red;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .nav_toggle {
            > div {
                background-color: $black;
            }
            &:before {
                // color: $black;
            }
            &:hover {
                > div {
                    background-color: $red;
                }
                @include min(md) {
                    &:before {
                        color: $red;
                    }
                }
            }
        }
    }
}

.mobile_menu_active {
    body {
        &.nav-black-color {
            #header {
                .header-bar {
                    // background: transparent;
                    .container {
                        .row {
                            .logo {
                                p {
                                    a {
                                        color: $white;
                                        &:hover {
                                            color: $black;
                                        }
                                    }
                                }
                                a {
                                    svg {
                                        path, rect, polygon {
                                            fill: $white;
                                        }
                                    }
                                    &:hover {
                                        svg {
                                            path, rect, polygon {
                                                fill: $black;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* cases transparent */
/* black bg */
body {
    &.cases {
        #header {
            .header-bar {
                @include transition(all $speed ease-in);
                background: transparent;
                .container { 
                    .row {
                        .logo {
                            p {
                                a {
                                    color: $white;
                                    &:hover {
                                        color: $red;
                                    }
                                }
                            }
                            a {
                                svg {
                                    path, rect, polygon {
                                        fill: $white;
                                    }
                                }
                                &:hover {
                                    svg {
                                        path, rect, polygon {
                                            fill: $red;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .nav_toggle {
            > div {
                background-color: $white;
            }
            &:before {
                // color: $white;
            }
            &:hover {
                > div {
                    background-color: $red;
                }
                @include min(md) {
                    &:before {
                        color: $red;
                    }
                }
            }
        }
        /*
        &.is-scrolled-down {
            #header {
                .header-bar {
                    background: $black;
                }
            }
        }
        */
    }
}
.mobile_menu_active {
    body {
        &.cases {
            #header {
                .header-bar {
                    background: transparent;
                }
            }
        }
    }
}