.inside {
  .no-padding-top {
    padding-top: 0 !important;
  }
  .no-padding-bottom {
    padding-bottom: 0 !important;
  }
  .half-padding-bottom {
    padding-bottom: 45px !important;
    @include min(md) {
      padding-bottom: 80px !important;
    }
    @include min(max) {
        padding-bottom: 140px !important;
    }
  }
  .full-padding-bottom {
    padding-bottom: 90px !important;
    @include min(md) {
      padding-bottom: 160px !important;
    }
    @include min(max) {
        padding-bottom: 280px !important;
    }
  }
  .no-margin {
    margin: 0 !important;
    > * {
      margin: 0 !important;
    }
  }
  .no-margin-top {
    margin-top: 0 !important;
  }
  .no-margin-bottom {
    margin-bottom: 0 !important;
  }
  .margin-top-auto {
    margin-top: auto;
  }
  .rm-on-mobile {
    display: none;
    @include min(md) {
      display: block;
    }
  }
  .rm-on-desktop {
    display: block;
    @include min(md) {
      display: none;
    }
  }
  /*
  br {
    display: none;
    @include min(md) {
      display: block;
    }
  }
  */
  .d-flex {
    @include min(md) {
      display: flex;
      flex-direction: column;
    }
  }
  .volleBreite {
    video {
      width: 100vw;
    }
  }

  .logo_wall {
    .content-image {
      height: 100%;
    }
    figure {
      height: 100%;
      img {
        height: 100%;
				@include transition(all $speed-scrolling ease-in);
        @include max(md) {
          transition-delay: 0s !important;
        }
      }
    }
    .grid {
      margin-top: 60px;
      @include min(md) {
        margin-top: 0;
        row-gap: 40px;
      }
      @include min(lg) {
        row-gap: 50px;
      }
      @include min(xl) {
        row-gap: 60px;
      }
      @include min(max) {
        row-gap: 70px;
      }
    }
  }
  .logo_wall_border {
    .row {
      display: grid;
      gap: 6px;
      @include min(md) {
        gap: 12px;
      }
      figure {
        border: 1px solid $grey_2;
        border-radius: 10px;
        img {
          filter: brightness(0) invert(0);
        }
      }
    }
  }
  /* datenschutz, impressu. */
  .normal-links {
    .rte {
      a {
        padding-right: 0;
        text-decoration: underline;
        font-family: $FactoryFont-Medium;
        &:after {
          content: none;
        }
      }
    }
  }
  .rm-padding-1600 {
    .row {
      @media(max-width:1599px) {
        display: block !important;
      }
      > div {
        &:first-of-type {
          > * {
            &.AbstandObenL {
              @media(max-width:1599px) {
                padding-top: 20px !important;
              }
            }
          }
        }
        > * {
          &.AbstandObenL {
            @media(max-width:1599px) {
              padding-top: 0 !important;
            }
          }
        }
      }
    }
  }
  /* icon color */
  .header-icon-white {
    a {
      &:before {
        background-image: url("data:image/svg+xml,%3Csvg%20id%3D'uuid-0478e9c9-d145-4ce8-ad04-340382150296'%20data-name%3D'_x31_'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2032.912%2017.4'%3E%3Cdefs%3E%3Cstyle%3E.uuid-1b01a2fd-b754-48c8-803b-b3ea58b9c013%7Bfill%3Anone%3Bstroke%3A%23FFFFFF%3Bstroke-linecap%3Around%3Bstroke-miterlimit%3A10%3Bstroke-width%3A2px%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cline%20id%3D'uuid-822c1021-8188-486a-a700-dd6df4784cad'%20data-name%3D'_x33_'%20class%3D'uuid-1b01a2fd-b754-48c8-803b-b3ea58b9c013'%20x1%3D'1'%20y1%3D'8.698'%20x2%3D'30.505'%20y2%3D'8.698'/%3E%3Cpath%20id%3D'uuid-4cc4ec3f-b934-4a03-9538-29173ce973c2'%20data-name%3D'_x32_'%20class%3D'uuid-1b01a2fd-b754-48c8-803b-b3ea58b9c013'%20d%3D'M23.798%2C16.4l7.7-7.7-7.7-7.7'/%3E%3C%2Fsvg%3E") !important;
        // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Ctitle%3Earrow-sm-right%3C/title%3E%3Cpath fill='none' stroke-linejoin='round' stroke-linecap='round' stroke-miterlimit='4' stroke-width='2' stroke='%23FFFFFF' d='M13 7l5 5M18 12l-5 5M18 12h-12'/%3E%3C/svg%3E") !important;
      }
      &:hover {
        &:before {
          background-image: url("data:image/svg+xml,%3Csvg%20id%3D'uuid-0478e9c9-d145-4ce8-ad04-340382150296'%20data-name%3D'_x31_'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2032.912%2017.4'%3E%3Cdefs%3E%3Cstyle%3E.uuid-1b01a2fd-b754-48c8-803b-b3ea58b9c013%7Bfill%3Anone%3Bstroke%3A%23141414%3Bstroke-linecap%3Around%3Bstroke-miterlimit%3A10%3Bstroke-width%3A2px%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cline%20id%3D'uuid-822c1021-8188-486a-a700-dd6df4784cad'%20data-name%3D'_x33_'%20class%3D'uuid-1b01a2fd-b754-48c8-803b-b3ea58b9c013'%20x1%3D'1'%20y1%3D'8.698'%20x2%3D'30.505'%20y2%3D'8.698'/%3E%3Cpath%20id%3D'uuid-4cc4ec3f-b934-4a03-9538-29173ce973c2'%20data-name%3D'_x32_'%20class%3D'uuid-1b01a2fd-b754-48c8-803b-b3ea58b9c013'%20d%3D'M23.798%2C16.4l7.7-7.7-7.7-7.7'/%3E%3C%2Fsvg%3E") !important;
          // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Ctitle%3Earrow-sm-right%3C/title%3E%3Cpath fill='none' stroke-linejoin='round' stroke-linecap='round' stroke-miterlimit='4' stroke-width='2' stroke='%23141414' d='M13 7l5 5M18 12l-5 5M18 12h-12'/%3E%3C/svg%3E") !important;
        }
      }
    }
  }
  .header-icon-red {
    a {
      &:before {
        // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Ctitle%3Earrow-sm-right%3C/title%3E%3Cpath fill='none' stroke-linejoin='round' stroke-linecap='round' stroke-miterlimit='4' stroke-width='2' stroke='%23FF0046' d='M13 7l5 5M18 12l-5 5M18 12h-12'/%3E%3C/svg%3E") !important;
        background-image: url("data:image/svg+xml,%3Csvg%20id%3D'uuid-0478e9c9-d145-4ce8-ad04-340382150296'%20data-name%3D'_x31_'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2032.912%2017.4'%3E%3Cdefs%3E%3Cstyle%3E.uuid-1b01a2fd-b754-48c8-803b-b3ea58b9c013%7Bfill%3Anone%3Bstroke%3A%23FF0046%3Bstroke-linecap%3Around%3Bstroke-miterlimit%3A10%3Bstroke-width%3A2px%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cline%20id%3D'uuid-822c1021-8188-486a-a700-dd6df4784cad'%20data-name%3D'_x33_'%20class%3D'uuid-1b01a2fd-b754-48c8-803b-b3ea58b9c013'%20x1%3D'1'%20y1%3D'8.698'%20x2%3D'30.505'%20y2%3D'8.698'/%3E%3Cpath%20id%3D'uuid-4cc4ec3f-b934-4a03-9538-29173ce973c2'%20data-name%3D'_x32_'%20class%3D'uuid-1b01a2fd-b754-48c8-803b-b3ea58b9c013'%20d%3D'M23.798%2C16.4l7.7-7.7-7.7-7.7'/%3E%3C%2Fsvg%3E") !important;
      }
      &:hover {
        &:before {
          background-image: url("data:image/svg+xml,%3Csvg%20id%3D'uuid-0478e9c9-d145-4ce8-ad04-340382150296'%20data-name%3D'_x31_'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2032.912%2017.4'%3E%3Cdefs%3E%3Cstyle%3E.uuid-1b01a2fd-b754-48c8-803b-b3ea58b9c013%7Bfill%3Anone%3Bstroke%3A%23FFFFFF%3Bstroke-linecap%3Around%3Bstroke-miterlimit%3A10%3Bstroke-width%3A2px%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cline%20id%3D'uuid-822c1021-8188-486a-a700-dd6df4784cad'%20data-name%3D'_x33_'%20class%3D'uuid-1b01a2fd-b754-48c8-803b-b3ea58b9c013'%20x1%3D'1'%20y1%3D'8.698'%20x2%3D'30.505'%20y2%3D'8.698'/%3E%3Cpath%20id%3D'uuid-4cc4ec3f-b934-4a03-9538-29173ce973c2'%20data-name%3D'_x32_'%20class%3D'uuid-1b01a2fd-b754-48c8-803b-b3ea58b9c013'%20d%3D'M23.798%2C16.4l7.7-7.7-7.7-7.7'/%3E%3C%2Fsvg%3E") !important;
          // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Ctitle%3Earrow-sm-right%3C/title%3E%3Cpath fill='none' stroke-linejoin='round' stroke-linecap='round' stroke-miterlimit='4' stroke-width='2' stroke='%23FFFFFF' d='M13 7l5 5M18 12l-5 5M18 12h-12'/%3E%3C/svg%3E") !important;
        }
      }
    }
  }
  .header-icon-red_02 {
    a {
      color: $red !important;
      &:before {
        background-image: url("data:image/svg+xml,%3Csvg%20id%3D'uuid-0478e9c9-d145-4ce8-ad04-340382150296'%20data-name%3D'_x31_'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2032.912%2017.4'%3E%3Cdefs%3E%3Cstyle%3E.uuid-1b01a2fd-b754-48c8-803b-b3ea58b9c013%7Bfill%3Anone%3Bstroke%3A%23FF0046%3Bstroke-linecap%3Around%3Bstroke-miterlimit%3A10%3Bstroke-width%3A2px%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cline%20id%3D'uuid-822c1021-8188-486a-a700-dd6df4784cad'%20data-name%3D'_x33_'%20class%3D'uuid-1b01a2fd-b754-48c8-803b-b3ea58b9c013'%20x1%3D'1'%20y1%3D'8.698'%20x2%3D'30.505'%20y2%3D'8.698'/%3E%3Cpath%20id%3D'uuid-4cc4ec3f-b934-4a03-9538-29173ce973c2'%20data-name%3D'_x32_'%20class%3D'uuid-1b01a2fd-b754-48c8-803b-b3ea58b9c013'%20d%3D'M23.798%2C16.4l7.7-7.7-7.7-7.7'/%3E%3C%2Fsvg%3E") !important;
        // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Ctitle%3Earrow-sm-right%3C/title%3E%3Cpath fill='none' stroke-linejoin='round' stroke-linecap='round' stroke-miterlimit='4' stroke-width='2' stroke='%23FF0046' d='M13 7l5 5M18 12l-5 5M18 12h-12'/%3E%3C/svg%3E") !important;
      }
      &:hover {
        &:before {
          background-image: url("data:image/svg+xml,%3Csvg%20id%3D'uuid-0478e9c9-d145-4ce8-ad04-340382150296'%20data-name%3D'_x31_'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2032.912%2017.4'%3E%3Cdefs%3E%3Cstyle%3E.uuid-1b01a2fd-b754-48c8-803b-b3ea58b9c013%7Bfill%3Anone%3Bstroke%3A%23141414%3Bstroke-linecap%3Around%3Bstroke-miterlimit%3A10%3Bstroke-width%3A2px%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cline%20id%3D'uuid-822c1021-8188-486a-a700-dd6df4784cad'%20data-name%3D'_x33_'%20class%3D'uuid-1b01a2fd-b754-48c8-803b-b3ea58b9c013'%20x1%3D'1'%20y1%3D'8.698'%20x2%3D'30.505'%20y2%3D'8.698'/%3E%3Cpath%20id%3D'uuid-4cc4ec3f-b934-4a03-9538-29173ce973c2'%20data-name%3D'_x32_'%20class%3D'uuid-1b01a2fd-b754-48c8-803b-b3ea58b9c013'%20d%3D'M23.798%2C16.4l7.7-7.7-7.7-7.7'/%3E%3C%2Fsvg%3E") !important;
          // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Ctitle%3Earrow-sm-right%3C/title%3E%3Cpath fill='none' stroke-linejoin='round' stroke-linecap='round' stroke-miterlimit='4' stroke-width='2' stroke='%23141414' d='M13 7l5 5M18 12l-5 5M18 12h-12'/%3E%3C/svg%3E") !important;
        }
      }
    }
  }
  /* leistung link */
  .leistung-link {
    margin: 20px 0;
    padding-right: 40px;
    text-align: right;
    &:first-of-type {
      margin-top: 60px;
      @include min(md) {
        margin-top: auto;
      }
    }
    @include min(md) {
        margin: auto 0;
        display: flex;
        padding-right: 0;
    }
    a {
      color: $black;
      @include font-size(4.0);
      width: 100%;
      padding-right: 30px;
      @include min(md) {
          @include font-size(3.0);
      }
      @include min(lg) {
          @include font-size(4.0);
      }
      @include min(xl) {
          @include font-size(5.0);
      }
      @include min(xxl) {
          @include font-size(6.0);
      }
      @include min(max) {
          @include font-size(7.0);
      }
      &:hover {
        color: $red;
        &:after {
          // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Ctitle%3Earrow-sm-right%3C/title%3E%3Cpath fill='none' stroke-linejoin='round' stroke-linecap='round' stroke-miterlimit='4' stroke-width='2' stroke='%23FF0046' d='M13 7l5 5M18 12l-5 5M18 12h-12'/%3E%3C/svg%3E");
          background-image: url("data:image/svg+xml,%3Csvg%20id%3D'uuid-0478e9c9-d145-4ce8-ad04-340382150296'%20data-name%3D'_x31_'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2032.912%2017.4'%3E%3Cdefs%3E%3Cstyle%3E.uuid-1b01a2fd-b754-48c8-803b-b3ea58b9c013%7Bfill%3Anone%3Bstroke%3A%23FF0046%3Bstroke-linecap%3Around%3Bstroke-miterlimit%3A10%3Bstroke-width%3A2px%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cline%20id%3D'uuid-822c1021-8188-486a-a700-dd6df4784cad'%20data-name%3D'_x33_'%20class%3D'uuid-1b01a2fd-b754-48c8-803b-b3ea58b9c013'%20x1%3D'1'%20y1%3D'8.698'%20x2%3D'30.505'%20y2%3D'8.698'/%3E%3Cpath%20id%3D'uuid-4cc4ec3f-b934-4a03-9538-29173ce973c2'%20data-name%3D'_x32_'%20class%3D'uuid-1b01a2fd-b754-48c8-803b-b3ea58b9c013'%20d%3D'M23.798%2C16.4l7.7-7.7-7.7-7.7'/%3E%3C%2Fsvg%3E");
        
          @include transition(all $speed ease-in);
          width: 52px;
          @include min(md) {
            width: 48px;
          }
          @include min(lg) {
            width: 60px;
          }
          @include min(xl) {
            width: 72px;
          }
          @include min(xxl) {
            width: 84px;
          }
          @include min(max) {
            width: 100px;
          }
        }
      }
      &:after {
        height: 26px;
        width: 26px;
        margin-top: 0px;
        margin-left: 26px;
        @include transition(all $speed ease-in);
        // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Ctitle%3Earrow-sm-right%3C/title%3E%3Cpath fill='none' stroke-linejoin='round' stroke-linecap='round' stroke-miterlimit='4' stroke-width='2' stroke='%23242424' d='M13 7l5 5M18 12l-5 5M18 12h-12'/%3E%3C/svg%3E");          
        background-image: url("data:image/svg+xml,%3Csvg%20id%3D'uuid-0478e9c9-d145-4ce8-ad04-340382150296'%20data-name%3D'_x31_'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2032.912%2017.4'%3E%3Cdefs%3E%3Cstyle%3E.uuid-1b01a2fd-b754-48c8-803b-b3ea58b9c013%7Bfill%3Anone%3Bstroke%3A%23141414%3Bstroke-linecap%3Around%3Bstroke-miterlimit%3A10%3Bstroke-width%3A2px%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cline%20id%3D'uuid-822c1021-8188-486a-a700-dd6df4784cad'%20data-name%3D'_x33_'%20class%3D'uuid-1b01a2fd-b754-48c8-803b-b3ea58b9c013'%20x1%3D'1'%20y1%3D'8.698'%20x2%3D'30.505'%20y2%3D'8.698'/%3E%3Cpath%20id%3D'uuid-4cc4ec3f-b934-4a03-9538-29173ce973c2'%20data-name%3D'_x32_'%20class%3D'uuid-1b01a2fd-b754-48c8-803b-b3ea58b9c013'%20d%3D'M23.798%2C16.4l7.7-7.7-7.7-7.7'/%3E%3C%2Fsvg%3E");
        @include min(md) {
          height: 24px;
          width: 24px;
          margin-top: 3px;
          margin-left: 24px;
        }
        @include min(lg) {
          height: 30px;
          width: 30px;
          margin-top: 0px;
          margin-left: 30px;
        }
        @include min(xl) {
          height: 36px;
          width: 36px;
          margin-top: -2px;
          margin-left: 36px;
        }
        @include min(xxl) {
          height: 42px;
          width: 42px;
          margin-top: -5px;
          margin-left: 42px;
        }
        @include min(max) {
          height: 50px;
          width: 50px;
          margin-top: -6px;
          margin-left: 50px;
        }
      }
    }
  }
  .special-bg-black-white {
    background: linear-gradient(to bottom, $black 50%, $white 50%);
  }
  .mod_article {
    h1, h2, h3, h4, h5, h6 {
      &.fix-width {
        display: table-cell;
        padding: .9em 0;
      }
    }
    .text-indent-15 {
      @include min(lg) {
        text-indent: 15%;
      }
    }
  }
}