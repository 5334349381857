@keyframes expandAnimation {
    0% {
        width: 0%;
    }
    50% {
        width: calc(100% - 14px);
    }
    100% {
        width: 0%;
    }
}