/* -----------------------------------
BASE
----------------------------------- */
html {
    scroll-behavior: smooth;
    overflow-x: hidden;
    // scroll-padding: 90px;
    @include min(md) {
        // scroll-padding: 80px;
    }
}
body {
	width: 100%;
    font-family: $FactoryFont-Medium;
    @include font-size(1.5);
    color: $black;
    line-height: 21px;
    letter-spacing: 0;
    @include min(md) {
        @include font-size(1.9);
        line-height: 25px;
    }
    @include min(lg) {
        @include font-size(1.9);
        line-height: 25px;
    }
    @include min(max) {
        @include font-size(1.9);
        line-height: 25px;
    }
}
#main {
    .inside {
        .rs_container {
            .row {
                position: relative;
                display: flex;
                flex-direction: column;
            }
        }
    }
}
#header, #container, #footer {
    position: relative;
    width: 100%;
}
.revealOnScroll, .revealOnScrollApp { 
    opacity: 0; 
}

.image_container {
    position: relative;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    & > a {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
    }
}
.image-holder {
    position: relative;
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
}
figure {
    display: flex;
    img {
        display: flex;
        margin: auto;
    }
}
img {
    // width: 100%;
    max-width: 100%;
    height: auto;
}
.video-container {
    width: 100%;
    max-width: 100%;
    height: auto;
    video {
        display: flex;
        width: 100%;
        max-width: 100%;
        height: auto;
    }
}
/* global mobile video */
.content-player {
    figure {
        video {
            @include max(md) {
                height: 320px;
                object-fit: cover;
            }
        }
    }
}
/* global mobile images */
.content-image {
    figure {
        img {
            @include max(md) {
                height: 320px;
                object-fit: cover;
            }
        }
    }
}
/* global mobile images cases */
.cases {
    figure {
        picture {
            @include max(sm) {
                width: 100%;
            }
        }
        img {
            @include max(sm) {
                height: 320px;
                object-fit: cover;
            }
        }
    }
}

table {
    width: 100%;
}

ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 0;
    li {
        position: relative;
    }
}
.inside {
    ul {
        list-style-type: none;
        padding-left: 18px;
        margin-bottom: 0;
        @include min(md) {
            padding-left: 23px;
        }
        li {
            position: relative;
            &:marker {
                color: $grey_2;
            }
            &:before {
                content: "";
                display: block;
                width: 4px;
                height: 4px;
                position: absolute;
                top: 7px;
                left: -17px;
                background: $black;
                @include min(md) {
                    width: 6px;
                    height: 6px;
                    top: 10px;
                    left: -23px;
                }
            }
        }
    }
}
p, li, th, td {
    strong {
        font-family: $FactoryFont-Bold;
    }
}
.rte {
    > * {
        &:first-of-type {
            margin-top: 0;
        }
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}