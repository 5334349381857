.contao-cookiebar {
    padding: 0;
    z-index: 999999;
    &.cc-active {
        &:not(.cc-saved) {
            background-color: #141414e6;
        }
    }
    .cc-inner {
        width: 100%;
        max-width: 100%;
        border: none;
        box-shadow: none;
        padding: 0;
        /* HEADLINE */
        .cc-head {
        }
        /* DESCRIPTION */
        .cc-info {
            text-align: left;
            margin: 0;
            padding: 40px 20px;
            background: $white;
            @include min(md) {
                padding: 60px 130px;
            }
            p {
                margin: 0;
                @include min(md) {
                    @include font-size(2.0);
                }
                a {
                    color: $black;
                }
            }
        }
        /* TOGGLER */
        .cc-groups {
            display: none;
            padding: 40px 20px;
            background: #F5F5F5;
            @include min(md) {
                padding: 60px 130px;
            }
            &.show {
                display: block;
            }
            .cc-group {
                font-family: $FactoryFont-Medium;
                font-weight: 400;
                border: none;
                color: $black;
                @include min(md) {
                    @include font-size(2.0);
                }
                label {
                    margin-left: 0;
                    &.group {
                        font-family: $FactoryFont-Medium;
                        font-weight: 400;
                    }
                    &::before {
                        border-color: $white;
                        background: $white;
                    }
                    &::after {
                        background: $black;
                    }
                }
                &:first-of-type {
                    label {
                        &.group {
                            color: $grey_2;
                        }
                        &::before {
                            border-color: $grey_2;
                            background: $grey;
                        }
                        &::after {
                            background: $grey_2;
                        }
                    }

                }
                input {
                    &:checked {
                        &:not(:disabled) {
                            +label {
                                &.group {
                                    &:after {
                                        background: $red;
                                    }
                                }
                            }
                        }
                    }
                }
                .cc-detail-btn {
                    margin-right: 20px;
                    span {
                        position: relative;
                        color: $black;
                        @include min(md) {
                            @include font-size(2.0);
                        }
                        &:first-of-type {
                            &:after {
                                position: absolute;
                                content: "";
                                height: 13px;
                                width: 13px;
                                right: -30px;
                                top: 0px;
                                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19.0292 19.0003'%3E%3Cg%3E%3Cline x1='9.5146' y1='1.0001' x2='9.5146' y2='18.0001' stroke='%23141414' stroke-width='2.0003' fill='none' stroke-linecap='round'/%3E%3Cline x1='18.0146' y1='9.5001' x2='1.0146' y2='9.5001' stroke='%23141414' stroke-width='2.0292' fill='none' stroke-linecap='round'/%3E%3C/g%3E%3C/svg%3E");
                                background-position: center center;
                                background-size: contain;
                                @include min(md) {
                                    height: 17px;
                                    width: 17px;
                                    right: -30px;
                                    top: 3px;
                                }
                            }
                        }
                        &:last-of-type {
                            &:after {
                                position: absolute;
                                content: "";
                                height: 2px;
                                width: 13px;
                                right: -30px;
                                top: 7px;
                                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17.9686 1.9686'%3E%3Cg%3E%3Cline x1='16.9843' y1='0.9843' x2='0.9843' y2='0.9843' stroke='%23141414' stroke-width='1.9686' fill='none' stroke-linecap='round'/%3E%3C/g%3E%3C/svg%3E");
                                background-position: center center;
                                background-size: contain;
                                @include min(md) {
                                    height: 3px;
                                    width: 17px;
                                    right: -30px;
                                    top: 10px;
                                }
                            }
                        }
                    }
                }
                .cc-cookies {
                    .cc-cookie {
                        input {
                            &:checked {
                                &:not(:disabled) {
                                    +label {
                                        &:after {
                                            background: $red;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        /* BOTTONS */
        .cc-footer {
            .cc-btn {
                display: flex;
                align-items: center;
                padding: 30px;
                border-radius: 0;
                text-transform: uppercase;
                width: 100%;
                border: none;
                float: left;
                @include transition(all, $speed ease);
                @include min(md) {
                    width: 50%;
                }
                &:hover{
                    color: $white;
                    background: $grey_2;
                    svg {
                        path {
                            stroke: $white;
                        }
                    }
                }
                /* button text */
                span {
                    margin-left: auto;
                }
                svg {
                    margin-right: auto;
                    margin-left: 10px;
                    @include min(md) {
                        width: 30px;
                        height: 30px;
                    }
                    path {
                        @include transition(all, $speed ease);
                    }
                }
            }
            .save {
                background: $grey;
                display: none;
                &.show {
                    display: flex;
                }
            }
            .change {
                background: $grey;
                &.hide {
                    display: none;
                }
            }
            .success {
                background: $red;
                // color: $text-color;
                // border: 1px solid $blue_light;
                &:hover {
                    // background: $grey_2;
                }
            }
        }
    }
}