/* -----------------------------------
TYPO
----------------------------------- */

h1, h2, h3, h4, h5, h6 {
    color: $black;
    &.bold {
        font-family: $FactoryFont-Bold;
    }
    span {
        color: $red;
    }
}

h1 {
    font-family: $FactoryFont-Bold;
    letter-spacing: 0;
    @include font-size(4.8);
    margin: 0.9em 0;
    line-height: 52px;
    font-weight: 700;
    @include min(md) {
        @include font-size(6.0);
        line-height: 60px;
    }
    @include min(lg) {
        @include font-size(10.0);
        line-height: 100px;
    }
    @include min(xl) {
        @include font-size(14.0);
        line-height: 140px;
    }
    @include min(xxl) {
        @include font-size(16.0);
        line-height: 160px;
    }
    @include min(max) {
        @include font-size(18.7);
        line-height: 208px;
    }
    &.small {
        font-family: $FactoryFont-Medium;
        letter-spacing: 0;
        @include font-size(3.6);
        line-height: 40px;
        margin: 0 0 1em 0;
        font-weight: 400;
        @include min(md) {
            @include font-size(4.0);
            line-height: 40px;
        }
        @include min(lg) {
            @include font-size(4.6);
            line-height: 46px;
        }
        @include min(xl) {
            @include font-size(5.6);
            line-height: 60px;
        }
        @include min(xxl) {
            @include font-size(6.6);
            line-height: 70px;
        }
        @include min(max) {
            @include font-size(8.9);
            line-height: 107px;
        }
    }
    &.small_special {
        font-family: $FactoryFont-Bold;
        color: $red;
        margin-top: 0;
        @include font-size(3.2);
        line-height: 32px;
        @include min(md) {
            @include font-size(3.4);
            line-height: 34px;
        }
        @include min(lg) {
            @include font-size(3.6);
            line-height: 36px;
        }
        @include min(xl) {
            @include font-size(3.8);
            line-height: 38px;
        }
        @include min(xxl) {
            @include font-size(4.0);
            line-height: 40px;
        }
        @include min(max) {
            @include font-size(4.2);
            line-height: 42px;
        }
    }
}
h2 {
    font-family: $FactoryFont-Medium;
    letter-spacing: 0;
    // @include font-size(3.6);
    // line-height: 40px;
    @include font-size(2.4);
    line-height: 32px;
    margin: 0 0 1em 0;
    font-weight: 400;
    @include min(md) {
        @include font-size(4.0);
        line-height: 40px;
    }
    @include min(lg) {
        @include font-size(4.6);
        line-height: 46px;
    }
    @include min(xl) {
        @include font-size(5.6);
        line-height: 60px;
    }
    @include min(xxl) {
        @include font-size(6.6);
        line-height: 70px;
    }
    @include min(max) {
        @include font-size(8.9);
        line-height: 107px;
    }
    &.big {
        font-family: $FactoryFont-Bold;
        letter-spacing: 0;
        @include font-size(4.8);
        margin: 0.9em 0;
        line-height: 52px;
        font-weight: 700;
        @include min(md) {
            @include font-size(6.0);
            line-height: 60px;
        }
        @include min(lg) {
            @include font-size(10.0);
            line-height: 100px;
        }
        @include min(xl) {
            @include font-size(14.0);
            line-height: 140px;
        }
        @include min(xxl) {
            @include font-size(16.0);
            line-height: 160px;
        }
        @include min(max) {
            @include font-size(18.7);
            line-height: 208px;
        }
    }
    &.small_special {
        font-family: $FactoryFont-Bold;
        color: $red;
        margin-top: 0;
        @include font-size(1.5);
        line-height: 26px;
        font-weight: inherit;
        @include min(md) {
            @include font-size(1.6);
            line-height: 28px;
        }
        @include min(lg) {
            @include font-size(1.8);
            line-height: 30px;
        }
        @include min(xxl) {
            text-align: right;
        }
        @include min(max) {
            line-height: 34px;
            @include font-size(2.0);
        }
    }
}

h3 {
    font-family: $FactoryFont-Medium;
    letter-spacing: 0;
    text-transform: uppercase;
    @include font-size(1.5);
    font-weight: 400;
    line-height: 23px;
    margin: 0 0 1em 0;
    @include min(md) {
        line-height: 25px;
        @include font-size(1.9);
    }
    @include min(lg) {
        line-height: 25px;
        @include font-size(1.9);
    }
    @include min(xl) {
        line-height: 25px;
        @include font-size(1.9);
    }
    @include min(xxl) {
        line-height: 25px;
        @include font-size(1.9);
    }
    @include min(max) {
        line-height: 25px;
        @include font-size(1.9);
    }
    &.small_special {
        margin-top: 0;
        @include min(md) {
            margin-bottom: 0;
        }
        @include min(xxl) {
            text-align: right;
        }
    }
}

h4 {
    font-family: $FactoryFont-Bold;
    letter-spacing: 0;
    @include font-size(2.8);
    font-weight: 700;
    line-height: 34px;
    @include min(md) {
        @include font-size(3.0);
        line-height: 34px;
    }
    @include min(lg) {
        @include font-size(3.2);
        line-height: 34px;
    }
    @include min(xl) {
        @include font-size(3.4);
        line-height: 40px;
    }
    @include min(xxl) {
        @include font-size(3.4);
        line-height: 40px;
    }
    @include min(max) {
        @include font-size(3.4);
        line-height: 40px;
    }
    &.small_special {
        color: $red;
        margin-top: 0;
        @include min(xxl) {
            text-align: right;
        }
    }
}

h5 {
    font-family: $FactoryFont-Bold;
    letter-spacing: 0;
    @include font-size(1.8);
    line-height: 18px;
    @include min(md) {
        @include font-size(2.0);
        line-height: 20px;
    }
    @include min(lg) {
        @include font-size(2.2);
        line-height: 22px;
    }
    @include min(xl) {
        @include font-size(2.4);
        line-height: 24px;
    }
    @include min(xxl) {
        @include font-size(2.6);
        line-height: 26px;
    }
    @include min(max) {
        @include font-size(2.8);
        line-height: 28px;
    }
}

h6 {
    font-family: $FactoryFont-Bold;
    letter-spacing: 0;
    @include font-size(1.8);
    line-height: 18px;
    @include min(md) {
        @include font-size(2.0);
        line-height: 20px;
    }
    @include min(lg) {
        @include font-size(2.0);
    }
    @include min(xl) {
        @include font-size(2.0);
    }
    @include min(xxl) {
        @include font-size(2.0);
    }
    @include min(max) {
        @include font-size(2.0);
    }
}



/*
h2, h3, h4, h5, h6 {
    font-family: $FactoryFont-Bold;
    margin-top: 0;
    margin-bottom: 1em;
}

h1 {
    @include font-size(5.0);
    line-height: 50px;
    // line-height: 1;
    margin: 0.9em 0;
    @include min(md) {
        @include font-size(6.0);
        line-height: 60px;
    }
    @include min(lg) {
        @include font-size(8.0);
        line-height: 80px;
    }
    @include min(xl) {
        @include font-size(10.0);
        line-height: 100px;
    }
    @include min(xxl) {
        @include font-size(13.0);
        line-height: 130px;
    }
    @include min(max) {
        @include font-size(16.0);
        line-height: 160px;
    }
    &.big {
        @include font-size(6.0);
        line-height: 60px;
        @include min(md) {
            @include font-size(8.0);
            line-height: 80px;
        }
        @include min(lg) {
            @include font-size(12.0);
            line-height: 120px;
        }
        @include min(xl) {
            @include font-size(16.0);
            line-height: 160px;
        }
        @include min(xxl) {
            @include font-size(20.0);
            line-height: 200px;
        }
        @include min(max) {
            @include font-size(26.0);
            line-height: 260px;
        }
    }
    &.small_special {
        font-family: $FactoryFont-Bold;
        color: $red;
        margin-top: 0;
        @include font-size(3.2);
        line-height: 32px;
        @include min(md) {
            @include font-size(3.4);
            line-height: 34px;
        }
        @include min(lg) {
            @include font-size(3.6);
            line-height: 36px;
        }
        @include min(xl) {
            @include font-size(3.8);
            line-height: 38px;
        }
        @include min(xxl) {
            @include font-size(4.0);
            line-height: 40px;
        }
        @include min(max) {
            @include font-size(4.2);
            line-height: 42px;
        }
    }
}

h2 {
    @include font-size(3.0);
    line-height: 30px;
    // line-height: 1;
    @include min(md) {
        @include font-size(3.6);
        line-height: 36px;
    }
    @include min(lg) {
        @include font-size(4.6);
        line-height: 46px;
    }
    @include min(xl) {
        @include font-size(5.6);
        line-height: 60px;
    }
    @include min(xxl) {
        @include font-size(6.6);
        line-height: 70px;
    }
    @include min(max) {
        @include font-size(7.0);
        line-height: 80px;
    }
    &.big {
        @include font-size(4.5);
        // line-height: 1;
        line-height: 45px;
        margin: 0.9em 0;
        @include min(md) {
            @include font-size(6.0);
            line-height: 60px;
        }
        @include min(lg) {
            @include font-size(8.0);
            line-height: 80px;
        }
        @include min(xl) {
            @include font-size(10.0);
            line-height: 100px;
        }
        @include min(xxl) {
            @include font-size(13.0);
            line-height: 130px;
        }
        @include min(max) {
            @include font-size(16.0);
            line-height: 160px;
        }
    }
    &.big_2 {
        @include font-size(4.5);
        // line-height: 1;
        line-height: 45px;
        margin: 0.9em 0;
        @include min(md) {
            @include font-size(6.0);
            line-height: 60px;
        }
        @include min(lg) {
            @include font-size(7.0);
            line-height: 70px;
        }
        @include min(xl) {
            @include font-size(8.0);
            line-height: 80px;
        }
        @include min(xxl) {
            @include font-size(10.0);
            line-height: 100px;
        }
        @include min(max) {
            @include font-size(12.0);
            line-height: 120px;
        }
    }
    &.small_special {
        color: $red;
        margin-top: 0;
        @include font-size(1.5);
        line-height: 26px;
        font-weight: inherit;
        @include min(md) {
            @include font-size(1.6);
            line-height: 28px;
        }
        @include min(lg) {
            @include font-size(1.8);
            line-height: 30px;
        }
        @include min(xxl) {
            text-align: right;
        }
        @include min(max) {
            line-height: 34px;
            @include font-size(2.0);
        }
    }
}

h3 {
    @include font-size(3.0);
    line-height: 1;
    line-height: 30px;
    @include min(md) {
        line-height: 34px;
        @include font-size(3.4);
    }
    @include min(lg) {
        line-height: 40px;
        @include font-size(4.0);
    }
    @include min(xl) {
        line-height: 44px;
        @include font-size(4.4);
    }
    @include min(xxl) {
        line-height: 50px;
        @include font-size(4.8);
    }
    @include min(max) {
        line-height: 60px;
        @include font-size(5.0);
    }
}

h4 {
    @include font-size(2.6);
    // line-height: 1;
    line-height: 26px;
    @include min(md) {
        @include font-size(2.8);
        line-height: 28px;
    }
    @include min(lg) {
        @include font-size(3.2);
        line-height: 32px;
    }
    @include min(xl) {
        @include font-size(3.4);
        line-height: 34px;
    }
    @include min(xxl) {
        @include font-size(3.8);
        line-height: 40px;
    }
    @include min(max) {
        @include font-size(4.2);
        line-height: 52px;
    }
    &.small_special {
        color: $red;
        margin-top: 0;
        @include min(xxl) {
            text-align: right;
        }
    }
}

h5 {
    @include font-size(1.8);
    // line-height: 1;
    line-height: 18px;
    @include min(md) {
        @include font-size(2.0);
        line-height: 20px;
    }
    @include min(lg) {
        @include font-size(2.2);
        line-height: 22px;
    }
    @include min(xl) {
        @include font-size(2.4);
        line-height: 24px;
    }
    @include min(xxl) {
        @include font-size(2.6);
        line-height: 26px;
    }
    @include min(max) {
        @include font-size(2.8);
        line-height: 28px;
    }
}

h6 {
    @include font-size(1.8);
    // line-height: 1;
    line-height: 18px;
    @include min(md) {
        @include font-size(2.0);
        line-height: 20px;
    }
    @include min(lg) {
        @include font-size(2.0);
    }
    @include min(xl) {
        @include font-size(2.0);
    }
    @include min(xxl) {
        @include font-size(2.0);
    }
    @include min(max) {
        @include font-size(2.0);
    }
}
    */


/* in rsce */
/*
section {
    &.big {
        h1 {
            @include font-size(6.0);
            @include min(md) {
                @include font-size(8.0);
            }
            @include min(lg) {
                @include font-size(12.0);
            }
            @include min(xl) {
                @include font-size(16.0);
            }
            @include min(xxl) {
                @include font-size(20.0);
            }
            @include min(max) {
                @include font-size(26.0);
            }
        }
    }
    &.small_special {
        font-family: $FactoryFont-Bold;
        color: $red;
        margin-top: 0;
        @include font-size(3.2);
        @include min(md) {
            @include font-size(3.4);
        }
        @include min(lg) {
            @include font-size(3.6);
        }
        @include min(xl) {
            @include font-size(3.8);
        }
        @include min(xxl) {
            @include font-size(4.0);
        }
        @include min(max) {
            @include font-size(4.2);
        }
    }
    &.big {
        h2 {
            @include font-size(4.5);
            line-height: 1;
            margin: 0.9em 0;
            @include min(md) {
                @include font-size(6.0);
            }
            @include min(lg) {
                @include font-size(8.0);
            }
            @include min(xl) {
                @include font-size(10.0);
            }
            @include min(xxl) {
                @include font-size(13.0);
            }
            @include min(max) {
                @include font-size(16.0);
            }
        }
    }
    &.big_2 {
        h2 {
            @include font-size(4.5);
            line-height: 1;
            margin: 0.9em 0;
            @include min(md) {
                @include font-size(6.0);
            }
            @include min(lg) {
                @include font-size(7.0);
            }
            @include min(xl) {
                @include font-size(8.0);
            }
            @include min(xxl) {
                @include font-size(10.0);
            }
            @include min(max) {
                @include font-size(12.0);
            }
        }
    }
    &.small_special {
        color: $red;
        margin-top: 0;
        @include font-size(1.5);
        line-height: 26px;
        font-weight: inherit;
        @include min(md) {
            @include font-size(1.6);
            line-height: 28px;
        }
        @include min(lg) {
            @include font-size(1.8);
            line-height: 30px;
        }
        @include min(xxl) {
            text-align: right;
        }
        @include min(max) {
            line-height: 34px;
            @include font-size(2.0);
        }
    }
}
*/