/* Hero Header Video */
.ce_rsce_cc_01-01_hero_header {
	.rs_container {
		.row {
			display: flex;
			flex-direction: column;
			position: relative;
			@include min(md) {
			}
			.content-holder {
				margin-top: 15%;
				display: flex;
				.content {
					@include flex(0,1,calc(100% - 4px));
					.headline {
						color: $red;
					}
				}
				.redline-holder {
					display: none;
					@include min(md) {
						display: block;
						@include flex(0,1,4px);
						background: $white;
						border-radius: 2px;
						margin: 2em 0;
					}
					.redline {
						border-radius: 2px;
						height: 20%;
						background: $red;
						animation: moveLine 2s linear infinite;
					}
				}
			}
			.video-container {
			}
		}
	}
}
@keyframes moveLine {
    0% {
        transform: translateY(0%);
    }
    100% {
        transform: translateY(100%);
    }
}

/* Hero Header Text */
.ce_rsce_cc_01-02_hero_header {
	height: calc(100vh - 200px);
	.rs_container {
		.row {
			.content-holder {
				.content {
					height: calc(100vh - 280px);
					display: flex;
					flex-direction: column;
					h1, h2, h3, h4, h5, h6 {
						margin: auto 0;
						position: relative;
						z-index: 1;
						@include min(md) {
							margin: auto 0;
						}
						/* old style without animation(ani on bottom) */
						display: flex;
						flex-direction: column;
						span {
							color: $black;
							// display: flex;
						    // flex-direction: column;
							&:nth-child(2) {
								text-align: right;
								@include min(md) {
								}
							}
						}
						/* end old style without animation */
					}
					.image-holder {
						position: absolute;
						top: 0px;
						left: 0px;
						display: flex;
						img {
							height: 100%;
							width: auto;
							margin: 0 auto;
						}
					}
					.footer-hero-links {
						position: absolute;
						bottom: -60px;
						left: 0px;
						display: flex;
						width: 100%;
						.ce_hyperlink { 
							@include min(md) {
								@include flex(0,1,33.33%);
							}
							@include min(max) {
								@include flex(0,1,70%);
							}
						}
						.url_tel {
							display: none;
							@include min(md) {
								display: block;
								text-align: center;
								@include flex(0,1,33.33%);
							}
							@include min(lg) {
								text-align: right;
							}
							@include min(max) {
								text-align: left;
								@include flex(0,1,15%);
							}
						}
						.ce_text { 
							display: none;
							@include min(md) {
								display: block;
								text-align: right;
								@include flex(0,1,33.33%);
							}
							@include min(max) {
								@include flex(0,1,15%);
							}
						}
						.ce_hyperlink {
							a {
								position: relative;
								padding-right: 0;
								padding-left: 35px;
								color: $black;
								@include min(md) {
									padding-left: 45px;
								}
								&:before {
									position: absolute;
									content: "";
									height: 21px;
									width: 29px;
									left: 0;
									top: -2px;
									background-image: url("data:image/svg+xml,%3Csvg%20id%3D'Button3-Pfeil-Klein'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'36'%20height%3D'26'%20viewBox%3D'0%200%2036%2026'%3E%3Crect%20x%3D'1'%20y%3D'1'%20width%3D'34'%20height%3D'24'%20rx%3D'4'%20ry%3D'4'%20stroke%3D'%2316141d'%20stroke-width%3D'2'%20fill%3D'none'/%3E%3Cpath%20d%3D'M23.5%2C13.4l1.4%2C1.4-6.1%2C6.1a.967.967%2C0%2C0%2C1-1.4%2C0l-6.2-6.2%2C1.4-1.4L17%2C17.7V5.7h2V17.9l4.5-4.5'%20fill%3D'%2316141d'/%3E%3C%2Fsvg%3E");
									background-position: center;
									background-size: 100% 100%;
									background-repeat: no-repeat;
									background-color: transparent;
									@include transition(all $speed ease-in);
									@include min(md) {
										height: 26px;
										width: 36px;
										top: -2px;
									}
								}
								&:after {
									// content: none;
									all: unset;
									position: absolute;
									content: "";
									height: 2px;
									width: 0;
									bottom: -2px;
									left: 35px;
									background: $black;
									@include transition(all $speed ease-in);
									@include min(md) {
										left: 45px;
									}
								}
								&:hover {
									color: $black;
									&:before {
										// background-image: url("data:image/svg+xml,%3Csvg%20id%3D'Button3-Pfeil-Klein'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'36'%20height%3D'26'%20viewBox%3D'0%200%2036%2026'%3E%3Crect%20x%3D'1'%20y%3D'1'%20width%3D'34'%20height%3D'24'%20rx%3D'4'%20ry%3D'4'%20stroke%3D'%2316141d'%20stroke-width%3D'2'%20fill%3D'%2316141d'/%3E%3Cpath%20d%3D'M23.5%2C13.4l1.4%2C1.4-6.1%2C6.1a.967.967%2C0%2C0%2C1-1.4%2C0l-6.2-6.2%2C1.4-1.4L17%2C17.7V5.7h2V17.9l4.5-4.5'%20fill%3D'%23FF0000'/%3E%3C%2Fsvg%3E");
										background-image: url("data:image/svg+xml,%3Csvg id='a' data-name='Ebene 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 26'%3E%3Cdefs%3E%3Cstyle%3E .b { fill: none; } .c { fill: %2316141d; } %3C/style%3E%3C/defs%3E%3Crect class='b' x='0' y='0' width='36' height='26' rx='4' ry='4'/%3E%3Cpath class='c' d='M32,0H4C1.79,0,0,1.79,0,4v18C0,24.21,1.79,26,4,26h28c2.21,0,4-1.79,4-4V4c0-2.21-1.79-4-4-4ZM18.75,20.98c-.19.19-.44.29-.71.29s-.52-.11-.71-.29l-6.22-6.22,1.41-1.41,4.44,4.44V5.73h2v12.2l4.5-4.5,1.41,1.41-6.13,6.13Z'/%3E%3C/svg%3E");
									}
									&::after {
										width: calc(100% - 35px);
										@include min(md) {
											width: calc(100% - 45px);
										}
									}
								}
							}
						}
						.url_tel {
							a {
								position: relative;
								color: $black;
								&:hover {
									// color: $white;
								}
								&:after {
									content: none;
								}
							}
						}
						.ce_text {
							p {
								margin: 0;
							}
						}
					}
				}
			}
		}
	}
	&.header-icon-red {
		.rs_container {
			.row {
				.content-holder {
					.content {
						.ce_hyperlink {
							a {
								&.hyperlink_txt {
								  color: $red !important;
								  &:hover {
									color: $white !important;
								  }
								}
							}
						}
					}
				}
			}
		}
	}
	/* new style for animate-typo class */
	&.animate-typo {
		.rs_container {
			.row {
				.content-holder {
					.content {
						h1, h2, h3, h4, h5, h6 {
							&.headline {
								display: flex;
								flex-direction: column;
								span {
									display: block;
									&.headline-part {
										overflow: hidden;
										&:nth-child(2) {
											margin-left: auto;
										}
									}
									&.letter {
										display: inline-block;
										transform: translate3d(0px, 100%, 0px);
										@include transition(all var(--anim-duration) ease-out);
										transition-delay: var(--char-delay);

									}
									&.word {
										display: inline-block;
										opacity: 0;
										transform: translateY(100%);
										transition: transform 0.6s ease-out, opacity 0.6s ease-out;
										transition-delay: var(--word-delay);
									}
								}
							}
						}
					}
				}
			}
		}
	}
	/* animate typo */
	&.iivp {
		&.animate-typo {
			.rs_container {
				.row {
					.content-holder {
						.content {
							h1, h2, h3, h4, h5, h6 {
								&.headline {
									span {
										&.headline-part {
										}
										&.letter {
											transform: translate3d(0px, 0%, 0px);
										}
										&.word {
											transform: translateY(0);
											opacity: 1;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

/* Hero Header Text/Pic */
.ce_rsce_cc_01-03_hero_header {
	padding: 0;
	min-width: 100%;
	@include min(md) {
		// min-height: 50vh;
	}
	.rs_container {
		@include min(md) {
			// min-height: 50vh;
		}
		.row {
			@include min(md) {
				// min-height: 50vh;
			}
			.content-holder {
				@include min(md) {
					// min-height: 50vh;
				}
				.content {
					display: flex;
					flex-direction: column;
					position: relative;
					@include min(md) {
						// min-height: 50vh;
					}
					.mobile-wrapper {
						position: relative;
						@include min(md) {
							// min-height: 50vh;
						}
						.image-holder {
							@include min(md) {
								// min-height: 50vh;
								display: flex;
							}
							picture {
								display: flex;
							}
							img {
								height: calc(100vh - 200px);
								width: 100%;
								margin: 0 auto;
								object-fit: cover;
								object-position: 50% 50%;
								@include min(md) {
									height: auto;
								}
							}
						}
						.footer-hero-links {
							position: absolute;
							bottom: 11px;
							left: 11px;
							@include min(md) {
								bottom: 11px;
								left: 35px;
							}
							.ce_hyperlink {
								a {
									position: relative;
									padding-right: 0;
									padding-left: 35px;
									color: $white;
									@include min(md) {
										padding-left: 45px;
									}
									&:before {
										position: absolute;
										content: "";
										height: 21px;
										width: 29px;
										left: 0;
										top: -2px;
										background-image: url("data:image/svg+xml,%3Csvg%20id%3D'Button3-Pfeil-Klein'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'36'%20height%3D'26'%20viewBox%3D'0%200%2036%2026'%3E%3Crect%20x%3D'1'%20y%3D'1'%20width%3D'34'%20height%3D'24'%20rx%3D'4'%20ry%3D'4'%20stroke%3D'%23ffffff'%20stroke-width%3D'2'%20fill%3D'none'/%3E%3Cpath%20d%3D'M23.5%2C13.4l1.4%2C1.4-6.1%2C6.1a.967.967%2C0%2C0%2C1-1.4%2C0l-6.2-6.2%2C1.4-1.4L17%2C17.7V5.7h2V17.9l4.5-4.5'%20fill%3D'%23ffffff'/%3E%3C%2Fsvg%3E");
										background-position: center;
										background-size: 100% 100%;
										background-repeat: no-repeat;
										background-color: transparent;
										@include transition(all $speed ease-in);
										@include min(md) {
											height: 26px;
											width: 36px;
											top: -2px;
										}
									}
									&:after {
										// content: none;
										all: unset;
										position: absolute;
										content: "";
										height: 2px;
										width: 0;
										bottom: -2px;
										left: 35px;
										background: $white;
										@include transition(all $speed ease-in);
										@include min(md) {
											left: 45px;
										}
									}
									&:hover {
										// color: $red;
										&:before {
											// background-image: url("data:image/svg+xml,%3Csvg%20id%3D'Button3-Pfeil-Klein'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'36'%20height%3D'26'%20viewBox%3D'0%200%2036%2026'%3E%3Crect%20x%3D'1'%20y%3D'1'%20width%3D'34'%20height%3D'24'%20rx%3D'4'%20ry%3D'4'%20stroke%3D'%23FAFAFD'%20stroke-width%3D'2'%20fill%3D'%23FAFAFD'/%3E%3Cpath%20d%3D'M23.5%2C13.4l1.4%2C1.4-6.1%2C6.1a.967.967%2C0%2C0%2C1-1.4%2C0l-6.2-6.2%2C1.4-1.4L17%2C17.7V5.7h2V17.9l4.5-4.5'%20fill%3D'%2316141D'/%3E%3C%2Fsvg%3E");
											background-image: url("data:image/svg+xml,%3Csvg id='a' data-name='Ebene 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 26'%3E%3Cdefs%3E%3Cstyle%3E .b { fill: none; } .c { fill: %23FAFAFD; } %3C/style%3E%3C/defs%3E%3Crect class='b' x='0' y='0' width='36' height='26' rx='4' ry='4'/%3E%3Cpath class='c' d='M32,0H4C1.79,0,0,1.79,0,4v18C0,24.21,1.79,26,4,26h28c2.21,0,4-1.79,4-4V4c0-2.21-1.79-4-4-4ZM18.75,20.98c-.19.19-.44.29-.71.29s-.52-.11-.71-.29l-6.22-6.22,1.41-1.41,4.44,4.44V5.73h2v12.2l4.5-4.5,1.41,1.41-6.13,6.13Z'/%3E%3C/svg%3E");
										}
										&::after {
											width: calc(100% - 35px);
											@include min(md) {
												width: calc(100% - 45px);
											}
										}
									}
								}
							}
						}
					}
					h3 {
						padding: 11px 11px 0 11px;
						margin-bottom: 0;
						@include min(md) {
							position: absolute;
							bottom: 11px;
							right: 35px;
							width: 38%;
							padding: 0;
						}
						@include min(xl) {
							width: 39%;
						}
						@include min(max) {
							width: 25%;
						}
					}
				}
			}
		}
	}
	&.header-icon-red {
		.rs_container {
			.row {
				.content-holder {
					.content {
						.ce_hyperlink {
							a {
								&.hyperlink_txt {
								  color: $red !important;
								  &:hover {
									color: $white !important;
								  }
								}
							}
						}
					}
				}
			}
		}
	}
	&.iivp {
		.rs_container {
			.row {
				.content-holder {
					.content {
						h1, h2, h3, h4, h5, h6 {
							.slide-in {
								// position: relative;
							}
						}
					}
				}
			}
		}
	}
}

/* Hero Header Text/Pic */
.ce_rsce_cc_01-04_hero_header {
	// padding: 0;
	@include min(md) {
		// height: 50vh;
	}
	.rs_container {
		@include min(md) {
			// height: 50vh;
		}
		.row {
			@include min(md) {
				// height: 50vh;
			}
			.content-holder {
				@include min(md) {
					// height: 50vh;
				}
				.content {
					display: flex;
					flex-direction: column;
					position: relative;
					@include min(md) {
						// height: 50vh;
					}
					.headline {
						width: 100%;
						margin-bottom: 210px;
						@include min(md) {
							width: 62%;
							margin-bottom: 135px;
						}
						@include min(xl) {
							width: 61%;
						}
						@include min(max) {
							width: 75%;
						}

					}
					.mobile-wrapper {
						position: relative;
						width: 100%;
						@include min(md) {
							display: flex;
							margin-bottom: 11px;
						}
						.subheadline {
							margin-bottom: 46px;
							order: 0;
							@include min(md) {
								margin-bottom: 0;
								order: 1;
								@include flex(0,1,38%);
							}
							@include min(xl) {
								width: 39%;
								@include flex(0,1,39%);
							}
							@include min(max) {
								@include flex(0,1,25%);
							}
						}
						.footer-hero-links {
							margin-bottom: 11px;
							@include min(md) {
								margin-bottom: 0;
								@include flex(0,1,62%);
								margin-top: auto;
							}
							@include min(xl) {
								width: 39%;
								@include flex(0,1,61%);
							}
							@include min(max) {
								@include flex(0,1,75%);
							}
							.ce_hyperlink {
								a {
									position: relative;
									padding-right: 0;
									padding-left: 35px;
									color: $black;
									@include min(md) {
										padding-left: 45px;
									}
									&:before {
										position: absolute;
										content: "";
										height: 21px;
										width: 29px;
										left: 0;
										top: -2px;
										background-image: url("data:image/svg+xml,%3Csvg%20id%3D'Button3-Pfeil-Klein'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'36'%20height%3D'26'%20viewBox%3D'0%200%2036%2026'%3E%3Crect%20x%3D'1'%20y%3D'1'%20width%3D'34'%20height%3D'24'%20rx%3D'4'%20ry%3D'4'%20stroke%3D'%2316141d'%20stroke-width%3D'2'%20fill%3D'none'/%3E%3Cpath%20d%3D'M23.5%2C13.4l1.4%2C1.4-6.1%2C6.1a.967.967%2C0%2C0%2C1-1.4%2C0l-6.2-6.2%2C1.4-1.4L17%2C17.7V5.7h2V17.9l4.5-4.5'%20fill%3D'%2316141d'/%3E%3C%2Fsvg%3E");
										background-position: center;
										background-size: 100% 100%;
										background-repeat: no-repeat;
										background-color: transparent;
										@include transition(all $speed ease-in);
										@include min(md) {
											height: 26px;
											width: 36px;
											top: -2px;
										}
									}
									&:after {
										// content: none;
										all: unset;
										position: absolute;
										content: "";
										height: 2px;
										width: 0;
										bottom: -2px;
										left: 35px;
										background: $black;
										@include transition(all $speed ease-in);
										@include min(md) {
											left: 45px;
										}
									}
									&:hover {
										// color: $white;
										&:before {
											// background-image: url("data:image/svg+xml,%3Csvg%20id%3D'Button3-Pfeil-Klein'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'36'%20height%3D'26'%20viewBox%3D'0%200%2036%2026'%3E%3Crect%20x%3D'1'%20y%3D'1'%20width%3D'34'%20height%3D'24'%20rx%3D'4'%20ry%3D'4'%20stroke%3D'%2316141d'%20stroke-width%3D'2'%20fill%3D'%2316141d'/%3E%3Cpath%20d%3D'M23.5%2C13.4l1.4%2C1.4-6.1%2C6.1a.967.967%2C0%2C0%2C1-1.4%2C0l-6.2-6.2%2C1.4-1.4L17%2C17.7V5.7h2V17.9l4.5-4.5'%20fill%3D'%23FF0000'/%3E%3C%2Fsvg%3E");
											background-image: url("data:image/svg+xml,%3Csvg id='a' data-name='Ebene 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 26'%3E%3Cdefs%3E%3Cstyle%3E .b { fill: none; } .c { fill: %2316141d; } %3C/style%3E%3C/defs%3E%3Crect class='b' x='0' y='0' width='36' height='26' rx='4' ry='4'/%3E%3Cpath class='c' d='M32,0H4C1.79,0,0,1.79,0,4v18C0,24.21,1.79,26,4,26h28c2.21,0,4-1.79,4-4V4c0-2.21-1.79-4-4-4ZM18.75,20.98c-.19.19-.44.29-.71.29s-.52-.11-.71-.29l-6.22-6.22,1.41-1.41,4.44,4.44V5.73h2v12.2l4.5-4.5,1.41,1.41-6.13,6.13Z'/%3E%3C/svg%3E");
										}
										&::after {
											width: calc(100% - 35px);
											@include min(md) {
												width: calc(100% - 45px);
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	/*
	&.iconGrey {
		.rs_container {
			.row {
				.content-holder {
					.content {
						.ce_hyperlink {
							a {
								&.hyperlink_txt {
								  &:hover {
									&:before {
										background-image: url("data:image/svg+xml,%3Csvg%20id%3D'Button3-Pfeil-Klein'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'36'%20height%3D'26'%20viewBox%3D'0%200%2036%2026'%3E%3Crect%20x%3D'1'%20y%3D'1'%20width%3D'34'%20height%3D'24'%20rx%3D'4'%20ry%3D'4'%20stroke%3D'%2316141d'%20stroke-width%3D'2'%20fill%3D'%2316141d'/%3E%3Cpath%20d%3D'M23.5%2C13.4l1.4%2C1.4-6.1%2C6.1a.967.967%2C0%2C0%2C1-1.4%2C0l-6.2-6.2%2C1.4-1.4L17%2C17.7V5.7h2V17.9l4.5-4.5'%20fill%3D'%23FAFAFD'/%3E%3C%2Fsvg%3E");
									}
								  }
								}
							}
						}
					}
				}
			}
		}
	}
	*/
	&.header-icon-red {
		.rs_container {
			.row {
				.content-holder {
					.content {
						.ce_hyperlink {
							a {
								&.hyperlink_txt {
								  color: $red !important;
								  &:hover {
									color: $white !important;
								  }
								}
							}
						}
					}
				}
			}
		}
	}
	&.iivp {
		.rs_container {
			.row {
				.content-holder {
					.content {
						h1, h2, h3, h4, h5, h6 {
							.slide-in {
								// position: relative;
							}
						}
					}
				}
			}
		}
	}
}

/* Hero Header BIG Text/Pic */
.ce_rsce_cc_01-05_hero_header {
	.rs_container {
		.row {
			.content-holder {
				.content {
					display: flex;
					flex-direction: column;
					position: relative;
					min-height: calc(100vh - 200px);
					.headline {
						width: 100%;
						margin-bottom: 210px;
						@include min(md) {
							margin-bottom: 135px;
						}

					}
					.mobile-wrapper {
						position: relative;
						width: 100%;
						@include min(md) {
							display: flex;
							margin-bottom: 11px;
							margin-top: auto;
						}
						.subheadline {
							margin-bottom: 46px;
							order: 0;
							@include min(md) {
								margin-bottom: 0;
								order: 1;
								@include flex(0,1,38%);
							}
							@include min(xl) {
								@include flex(0,1,39%);
							}
							@include min(max) {
								@include flex(0,1,25%);
							}
						}
						.footer-hero-links {
							margin-bottom: 11px;
							@include min(md) {
								margin-bottom: 0;
								@include flex(0,1,62%);
								margin-top: auto;
							}
							@include min(xl) {
								@include flex(0,1,61%);
							}
							@include min(max) {
								@include flex(0,1,75%);
							}
							.ce_hyperlink {
								a {
									position: relative;
									padding-right: 0;
									padding-left: 35px;
									color: $black;
									@include min(md) {
										padding-left: 45px;
									}
									&:before {
										position: absolute;
										content: "";
										height: 21px;
										width: 29px;
										left: 0;
										top: -2px;
										background-image: url("data:image/svg+xml,%3Csvg%20id%3D'Button3-Pfeil-Klein'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'36'%20height%3D'26'%20viewBox%3D'0%200%2036%2026'%3E%3Crect%20x%3D'1'%20y%3D'1'%20width%3D'34'%20height%3D'24'%20rx%3D'4'%20ry%3D'4'%20stroke%3D'%2316141d'%20stroke-width%3D'2'%20fill%3D'none'/%3E%3Cpath%20d%3D'M23.5%2C13.4l1.4%2C1.4-6.1%2C6.1a.967.967%2C0%2C0%2C1-1.4%2C0l-6.2-6.2%2C1.4-1.4L17%2C17.7V5.7h2V17.9l4.5-4.5'%20fill%3D'%2316141d'/%3E%3C%2Fsvg%3E");
										background-position: center;
										background-size: 100% 100%;
										background-repeat: no-repeat;
										background-color: transparent;
										@include transition(all $speed ease-in);
										@include min(md) {
											height: 26px;
											width: 36px;
											top: -2px;
										}
									}
									&:after {
										content: none;
									}
									&:hover {
										color: $white;
										&:before {
											background-image: url("data:image/svg+xml,%3Csvg%20id%3D'Button3-Pfeil-Klein'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'36'%20height%3D'26'%20viewBox%3D'0%200%2036%2026'%3E%3Crect%20x%3D'1'%20y%3D'1'%20width%3D'34'%20height%3D'24'%20rx%3D'4'%20ry%3D'4'%20stroke%3D'%2316141d'%20stroke-width%3D'2'%20fill%3D'%2316141d'/%3E%3Cpath%20d%3D'M23.5%2C13.4l1.4%2C1.4-6.1%2C6.1a.967.967%2C0%2C0%2C1-1.4%2C0l-6.2-6.2%2C1.4-1.4L17%2C17.7V5.7h2V17.9l4.5-4.5'%20fill%3D'%23ffffff'/%3E%3C%2Fsvg%3E");
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	&.header-icon-red {
		.rs_container {
			.row {
				.content-holder {
					.content {
						.ce_hyperlink {
							a {
								&.hyperlink_txt {
								  color: $red !important;
								  &:hover {
									color: $white !important;
								  }
								}
							}
						}
					}
				}
			}
		}
	}
}

.ce_rsce_cc_02-01_services {
	.rs_container {
		.row {
			.content-holder {
				.content {
					.service-wrapper {
						&:first-child {
							border-top: 2px solid $black;
						}
						a {
							&.hyperlink_service {
								&:hover {
									color: $red;
								}
								&:after, &:before {
									content: none;
								}
							}
						}
						.hyperlink_service {
							display: flex;
							border-bottom: 2px solid $black;
							padding-top: 12px;
							padding-bottom: 60px;
							padding-right: 0;
							@include min(md) {
								padding-bottom: 60px;
								padding-top: 0;
							}
							.link {
								display: flex;
								@include min(md) {
									@include flex(0,1,60%);
								}
								@include min(xxl) {
									@include flex(0,1,80%);
								}
								.service-box {
									margin: 0;
									padding-right: 0;
									/* font same like h1 */
									font-family: $FactoryFont-Bold;
									letter-spacing: 0;
									@include font-size(4.8);
									// margin: 0.9em 0;
									line-height: 52px;
									font-weight: 700;
									@include min(md) {
										@include font-size(6.0);
										// line-height: 60px;
										line-height: 70px;
									}
									@include min(lg) {
										@include font-size(8.0);
										// line-height: 80px;
										line-height: 90px;
									}
									@include min(xl) {
										@include font-size(10.0);
										// line-height: 100px;
										line-height: 110px;
									}
									@include min(xxl) {
										@include font-size(13.0);
										// line-height: 130px;
										line-height: 140px;
									}
									@include min(max) {
										@include font-size(18.7);
										// line-height: 187px;
										line-height: 202px;
									}
									/* edn font same like h1 */
								}
							}
							.description {
								display: none;
								@include min(md) {
									display: block;
									@include flex(0,1,40%);
									margin-top: 15px;
								}
								@include min(xxl) {
									@include flex(0,1,20%);
								}
								@include min(max) {
									margin-top: 30px;
								}
							}
						}
					}
					.text-wrapper {
						@include min(md) {
							margin-top: 30px;
						}
						.row_col {
							@include min(md) {
								display: grid;
								grid-template-columns: repeat(var(--grid__columns), minmax(0, 1fr));
								grid-gap: var(--grid__gutter);
								grid-auto-columns: 1fr;
							}
						}
					}
				}
			}
		}
	}
	&.animate-typo {
		.rs_container {
			.row {
				.content-holder {
					.content {
						.service-wrapper {
							.hyperlink_service{
								.link {
									.service-box {
										display: flex;
										flex-direction: column;
										span {
											display: block;
											&.headline-part {
												overflow: hidden;
												&:nth-child(2) {
													margin-left: auto;
												}
											}
											&.letter {
												display: inline-block;
												transform: translate3d(0px, 100%, 0px);
												@include transition(transform var(--anim-duration) ease-out);
												transition-delay: var(--char-delay);
		
											}
											&.word {
												display: inline-block;
												opacity: 0;
												transform: translateY(100%);
												transition: transform 0.6s ease-out, opacity 0.6s ease-out;
												transition-delay: var(--word-delay);
											}
										}
										&.iivp, &.above_viewport {
											span {
												&.headline-part {
												}
												&.letter {
													transform: translate3d(0px, 0%, 0px);
												}
												&.word {
													transform: translateY(0);
													opacity: 1;
												}
											}

										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.ce_rsce_cc_02-02_services_contact {
	.rs_container {
		.row {
			.content-holder {
				.content {
					position: relative;
					@include min(md) {
						display: flex;
						flex-direction: column;
					}
					@include min(xl) {
						// display: flex;
						display: grid;
						grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
						gap: 6px;
					}
					.headline {
						@include font-size(1.3);
						@include min(md) {
							font-size: revert;
						}
						@include min(xl) {
							@include font-size(1.8);
							position: absolute;
							width: 300px;
							transform: rotate(-90deg);
							top: 0;
							bottom: 0;
							margin: 0;
							text-align: center;
							left: -10px;
						}
						@include min(max) {
							// left: 70px;
							left: 30px;
						}
						@include min(2k) {
							@include font-size(3.8);
							position: absolute;
							transform: rotate(0deg);
							width: 33%;
							top: -50px;
							margin: 0;
							left: 0;
							text-align: left;
						}
					}
					a {
						position: relative;
						padding-right: 0;
						margin-bottom: 16px;
						display: block;
						@include min(md) {
							margin-bottom: 30px;
							&:nth-child(odd) {
								margin-left: 35%;
							}
							&:nth-child(even) {
								margin-right: 35%;
							}
						}
						@include min(lg) {
							&:nth-child(odd) {
								margin-left: 50%;
							}
							&:nth-child(even) {
								margin-right: 50%;
							}
						}
						@include min(xl) {
							margin-bottom: 0;
							&:nth-child(odd) {
								margin-left: 0;
							}
							&:nth-child(even) {
								margin-right: 0;
							}
						}
						.image-holder {
							overflow: hidden;
							display: flex;
							img {
								width: 100%;
								height: 100%;
								min-height: 360px;
								object-fit: cover;
								@include transition(all $speed ease-in);
								@include min(md) {
									min-height: 400px;
								}
								@include min(max) {
									min-height: 500px;
								}
							}
						}
						h3 {
							position: absolute;
							bottom: 30px;
							left: 30px;
							font-family: $FactoryFont-Bold;
							color: $white;
							margin: 0;
							z-index: 1;
							@include transition(all $speed ease-in);
							@include font-size(4.0);
							@include min(md) {
								@include font-size(2.0);
								bottom: 45px;
								left: 45px;
							}
							@include min(lg) {
								@include font-size(3.0);
							}
							@include min(max) {
								@include font-size(5.0);
							}
							&:after {
								position: absolute;
								content: "";
								height: 30px;
								width: 30px;
								right: -60px;
								background-image: url("data:image/svg+xml,%3Csvg%20id%3D'uuid-0478e9c9-d145-4ce8-ad04-340382150296'%20data-name%3D'_x31_'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2032.912%2017.4'%3E%3Cdefs%3E%3Cstyle%3E.uuid-1b01a2fd-b754-48c8-803b-b3ea58b9c013%7Bfill%3Anone%3Bstroke%3A%23FFFFFF%3Bstroke-linecap%3Around%3Bstroke-miterlimit%3A10%3Bstroke-width%3A2px%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cline%20id%3D'uuid-822c1021-8188-486a-a700-dd6df4784cad'%20data-name%3D'_x33_'%20class%3D'uuid-1b01a2fd-b754-48c8-803b-b3ea58b9c013'%20x1%3D'1'%20y1%3D'8.698'%20x2%3D'30.505'%20y2%3D'8.698'/%3E%3Cpath%20id%3D'uuid-4cc4ec3f-b934-4a03-9538-29173ce973c2'%20data-name%3D'_x32_'%20class%3D'uuid-1b01a2fd-b754-48c8-803b-b3ea58b9c013'%20d%3D'M23.798%2C16.4l7.7-7.7-7.7-7.7'/%3E%3C%2Fsvg%3E");
								background-size: cover;
        						background-position: right;
								background-repeat: no-repeat;
								margin-left: 0;
								margin-top: -1px;
								@include transition(all $speed ease-in);
								@include min(md) {
									height: 16px;
									width: 16px;
									margin-top: 9px;
									right: -32px;
								}
								@include min(lg) {
									height: 22px;
									width: 22px;
									margin-top: 10px;
									right: -44px;
								}
								@include min(xl) {
									height: 24px;
									width: 24px;
									margin-top: 10px;
									right: -48px;
								}
								@include min(xxl) {
									height: 24px;
									width: 24px;
									margin-top: 14px;
									right: -48px;
								}
								@include min(max) {
									height: 40px;
									width: 40px;
									margin-top: 11px;
									right: -80px;
								}
							}
						}
						.background-box {
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							background: transparent linear-gradient(180deg, #FFFFFF 0%, #EAEAEA 34%, #000000 100%) 0% 0% no-repeat padding-box;
							mix-blend-mode: multiply;
						}
						.icon-box {
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							z-index: 10;
							@include min(md) {
								display: none;
							}
							.open {
								display: flex;
								margin-left: auto;
								width: 30px;
								height: 30px;
								margin-right: 16px;
								margin-top: 16px;
								svg {
								}
							}
						}
						.info-box {
							opacity: 0;
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							padding: 30px;
							padding-right: 60px;
							color: $white;
							background: $red;
							@include transition(all $speed ease-in);
							@include font-size(1.5);
							@include min(md) {
								padding-right: 45px;
								padding-left: 45px;
							}
							&.show {
								opacity: 1;
							}
							.close {
								position: absolute;
								display: flex;
								margin-left: auto;
								margin-bottom: 30px;
								width: 30px;
								height: 30px;
								background-color: $white;
								border-radius: 50%;
								right: 16px;
    							top: 16px;
								@include min(md) {
									display: none;
								}
								svg {
									width: 14px;
									height: 14px;
									margin-top: 8px;
									margin-left: 8px;
								}
							}
						}
						&:after {
							content: none;
						}
						&:hover {
							.image-holder {
								img {
									transform: scale(1.1);
								}

							}
							h3 {
								// color: $red;
								color: $white;
								&:after {
									width: 60px;
									right: -90px;
									@include min(md) {
										width: 32px;
										right: -48px;
									}
									@include min(lg) {
										width: 48px;
										right: -66px;
									}
									@include min(xl) {
										width: 48px;
										right: -72px;
									}
									@include min(xxl) {
										width: 48px;
										right: -72px;
									}
									@include min(max) {
										width: 80px;
										right: -120px;
									}
								}
							}
							.info-box {
								@include min(md) {
									opacity: 1;
								}
							}

						}
						&.show {
							.icon-box {
								z-index: 0;
							}
							.info-box {
								opacity: 1;
							}
						}
						/* last contact box */
						&:last-child {
							.background-box, .icon-box {
								display: none;
							}
							h3 {
								@include font-size(1.8);
								@include min(max) {
									bottom: 20px;
								}
								&:after {
									height: 14px;
									width: 14px;
									margin-top: 8px;
									right: unset;
									margin-left: 20px;
									@include min(md) {
										margin-top: 10px;
									}
									@include min(lg) {
										margin-top: 13px;
									}
									@include min(xl) {
										margin-top: 14px;
									}
									@include min(xxl) {
										margin-top: 18px;
									}
									@include min(max) {
										height: 18px;
										width: 18px;
										margin-top: 20px;
										margin-left: 20px;
									}
								}
							}
							.info-box {
								opacity: 1;
								.close {
									display: none;
								}
								.rte {
									p {
										@include font-size(3.0);
										line-height: 1;
										line-height: 30px;
										@include min(md) {
											line-height: 34px;
											@include font-size(3.4);
										}
										@include min(lg) {
											line-height: 40px;
											@include font-size(4.0);
										}
										@include min(xl) {
											line-height: 44px;
											@include font-size(4.4);
										}
										@include min(xxl) {
											line-height: 50px;
											@include font-size(4.8);
										}
										@include min(max) {
											line-height: 60px;
											@include font-size(5.0);
										}
									}
								}
							}
							&:hover {
								.info-box {
									@include min(md) {
										background: $black;
									}
								}
								h3 {
									&:after {
										width: 28px;
										@include min(max) {
											width: 36px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}


.ce_rsce_cc_03-01_image_line {
	.rs_container {
		.row {
			.content-holder {
				.content {
					.image-line {
						/* mobile */
						div {
							&.ce_hyperlink {
								margin-top: 30px;
							}
						}
						@include min(md) {
							display: flex;        
							flex-direction: column;
							div {
								&:nth-child(odd) {
									@include flex(0,1,calc(60% - 6px));
									margin-right: 6px;
									margin-bottom: 12px;
									width: calc(60% - 6px);
								}
								&:nth-child(even) {
									@include flex(0,1,calc(40% - 6px));
									margin-left: 6px;
									margin-bottom: 12px;
									width: calc(40% - 6px);
									margin-left: auto;
								}
								&:nth-child(4n + 1) {
									&.image-holder {
										img {
											height: 570px;
										}
									}
								}
								&:nth-child(4n + 2), &:nth-child(4n + 3), &:nth-child(4n + 4) {
									&.image-holder {
										margin-top: -216px;
										img {
											height: 420px;
										}
									}
								}
								&:nth-child(4n + 3) {
									&.image-holder {
										img {
											max-width: 66.66%;
											margin-left: auto;
										}
									}
								}
								&:nth-child(4n + 5) {
									&.image-holder {
										margin-top: -216px;
									}
								}
								&:nth-child(4n + 6) {
									&.image-holder {
										// margin-top: -285px;
									}
								}
								&.image-holder {
									display: flex;
									img {
										width: 100%;
										height: 100%;
										object-fit: cover;
										// @include transition(all $speed ease-in);
									}
								}
								&.ce_hyperlink {
									margin-top: -210px;
									margin-bottom: 210px;
									z-index: 1;
								}
								&:nth-child(4n + 6) {
									&.ce_hyperlink {
										margin-top: -360px;
										margin-bottom: 360px;
									}
								}
								&:nth-child(odd) {
									&.ce_hyperlink {
										margin-left: auto;
									}
								}

							}
						}
					}
				}
			}
		}
	}
}

.ce_rsce_cc_03-02_image_line {
	.rs_container {
		.row {
			.content-holder {
				.content {
					.headline {
						// @include padding-case;
						margin-bottom: 0;
						@include min(lg) {
						  text-indent: 15%;
						}
						&.no_subheadline {
							padding-bottom: 65px;
							@include min(md) {
								padding-bottom: 80px;
							}
							@include min(max) {
								padding-bottom: 140px;
							}
						}
					}
					.subheadline-wrapper {
						@include min(md) {
							display: flex;
						}
						.subheadline {
							margin-top: 22px;
							margin-bottom: 0;
							padding-bottom: 65px;
							@include min(md) {
								padding-bottom: 80px;
								margin-top: 40px;
								@include flex(0,1,calc(40% - 20px));
								margin-right: 20px;
							}
							@include min(xxl) {
								@include flex(0,1,calc(55% - 20px));
							}
							@include min(max) {
								padding-bottom: 140px;
							}
						}
					}
					.image-line {
						/* global img/a tag style */
						.image-wrapper, .image-text-wrapper {
							.image-holder {
								margin-bottom: 11px;
								@include min(md) {
									margin-bottom: 0;
								}
								figure {
									overflow: hidden;
									width: 100%;
									picture {
										width: 100%;
									}
								}
								img {
									object-fit: cover;
									display: flex;
									width: 100%;
									height: 320px;
									@include transition(all $speed ease-in);
								}
								p {
									// margin-top: 10px;
									display: none;
									@include min(md) {
										display: block;
										margin-top: 17px;
									}
									span {
										margin-left: 20px;
										@include min(md) {
											margin-left: 50px;
										}
									}
								}
								a {
									display: block;
									padding-right: 0;
									@include min(md) {
										display: flex;
									}
									&:hover {
										img {
											transform: scale(1.2);
										}
									}
									&:before, &::after {
										content: none;
									}
								}
							}
						}
						/* end global img style */

						.image-wrapper {
							@include min(md) {
								display: flex;
							}
							.image_1 {
								@include min(md) {
									@include flex(0,1,60%);
								}
								img {
									@include min(md) {
										height: 480px;
									}
									@include min(lg) {
										height: 620px;
									}
									@include min(max) {
										height: 820px;
									}
								}
							}
							.image_2 {
								@include min(md) {
									@include flex(0,1,30%);
									margin: auto 0;
									margin-left: 10%;
								}
								img {
									@include min(md) {
										height: 360px;
									}
									@include min(lg) {
										height: 480px;
									}
									@include min(max) {
										height: 500px;
									}
								}
							}
						}
						.image-text-wrapper {
							@include min(md) {
								display: flex;
								margin-top: 40px;
							}
							@include min(lg) {
								margin-top: 60px;
							}
							@include min(max) {
								margin-top: 100px;
							}
							.image_3 {
								@include min(md) {
									@include flex(0,1,60%);
									padding-left: 40px;
								}
								@include min(lg) {
									@include flex(0,1,60%);
									margin-right: 10%;
									padding-left: 0;
								}
								img {
									@include min(md) {
										height: 600px;
									}
								}

							}
							.description {
								margin-top: 40px;
								@include min(md) {
									@include flex(0,1,40%);
									margin-top: auto;
									margin-bottom: 61px;
									order: -1;
								}
								@include min(lg) {
									@include flex(0,1,25%);
									margin-right: 15%;
								}
								.ce_text {
									> * {
										&:last-of-type {
											@include min(md) {
												margin-bottom: 0;
											}
										}
									}
								}
								.ce_hyperlink {
									@include min(md) {
										margin-top: 60px;
									}

								}

							}
							&.no_margin {
								.description {
									@include min(md) {
										margin-bottom: 0;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.ce_rsce_cc_03-03_image_video_line {
	.rs_container {
		.row {
			.content-holder {
				.content {
					.headline {
						// @include padding-case;
						margin-bottom: 0;
						@include min(lg) {
						  text-indent: 15%;
						}
						&.no_subheadline {
							padding-bottom: 65px;
							@include min(md) {
								padding-bottom: 80px;
							}
							@include min(max) {
								padding-bottom: 140px;
							}
						}
					}
					.subheadline-wrapper {
						@include min(md) {
							display: flex;
						}
						.subheadline {
							margin-top: 22px;
							margin-bottom: 0;
							padding-bottom: 65px;
							@include min(md) {
								padding-bottom: 80px;
								margin-top: 40px;
								@include flex(0,1,calc(40% - 20px));
								margin-right: 20px;
							}
							@include min(xxl) {
								@include flex(0,1,calc(55% - 20px));
							}
							@include min(max) {
								padding-bottom: 140px;
							}
						}
					}
					.image-line {
						/* global img/a tag style */
						.image-wrapper, .image-text-wrapper {
							.image-holder, .video-holder {
								margin-bottom: 11px;
								@include min(md) {
									margin-bottom: 0;
								}
								figure {
									overflow: hidden;
									width: 100%;
									picture {
										width: 100%;
									}
								}
								img {
									object-fit: cover;
									display: flex;
									width: 100%;
									height: 320px;
									@include transition(all $speed ease-in);
								}
								video {
									object-fit: cover;
									display: flex;
									width: 100%;
									height: 320px;
									@include transition(all $speed ease-in);
								}
								p {
									// margin-top: 10px;
									display: none;
									@include min(md) {
										display: block;
										margin-top: 17px;
									}
									span {
										margin-left: 20px;
										@include min(md) {
											margin-left: 50px;
										}
									}
								}
								a {
									display: block;
									padding-right: 0;
									@include min(md) {
										display: flex;
									}
									&:hover {
										img {
											transform: scale(1.2);
										}
									}
									&:before, &::after {
										content: none;
									}
								}
							}
						}
						/* end global img style */

						.image-wrapper {
							@include min(md) {
								display: flex;
							}
							.image_1, .video_1 {
								@include min(md) {
									@include flex(0,1,60%);
								}
								img, video {
									height: auto;
									/*
									@include min(md) {
										height: 480px;
									}
									@include min(lg) {
										height: 620px;
									}
									@include min(max) {
										height: 820px;
									}
									*/
								}
							}
							.image_2, .video_2 {
								@include min(md) {
									@include flex(0,1,30%);
									margin: auto 0;
									margin-left: 10%;
								}
								img, video {
									height: auto;
									/*
									@include min(md) {
										height: 360px;
									}
									@include min(lg) {
										height: 480px;
									}
									@include min(max) {
										height: 500px;
									}
									*/
								}
							}
						}
						.image-text-wrapper {
							@include min(md) {
								display: flex;
								margin-top: 40px;
							}
							@include min(lg) {
								margin-top: 60px;
							}
							@include min(max) {
								margin-top: 100px;
							}
							.image_3, .video_3 {
								@include min(md) {
									@include flex(0,1,60%);
									padding-left: 40px;
								}
								@include min(lg) {
									@include flex(0,1,60%);
									margin-right: 10%;
									padding-left: 0;
								}
								img {
									height: auto;
									/*
									@include min(md) {
										height: 600px;
									}
									*/
								}
								video {
									height: auto;
									/*
									@include min(md) {
										height: 600px;
									}
									*/
								}

							}
							.description {
								margin-top: 40px;
								@include min(md) {
									@include flex(0,1,40%);
									margin-top: auto;
									margin-bottom: 61px;
									order: -1;
								}
								@include min(lg) {
									@include flex(0,1,25%);
									margin-right: 15%;
								}
								.ce_text {
									> * {
										&:last-of-type {
											@include min(md) {
												margin-bottom: 0;
											}
										}
									}
								}
								.ce_hyperlink {
									@include min(md) {
										margin-top: 60px;
									}
								}

							}
							&.no_margin {
								.description {
									@include min(md) {
										margin-bottom: 0;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.ce_rsce_cc_03-04_image_line_ani {
	.rs_container {
		.row {
			.content-holder {
				.content {
					.headline {
						margin-bottom: 0;
						padding-bottom: 65px;
						@include min(md) {
							padding-bottom: 80px;
						}
						@include min(lg) {
						  text-indent: 15%;
						}
						@include min(max) {
							padding-bottom: 140px;
						}
					}
					.image-line {
						/* global img/a tag style */
						.image-wrapper {
							.image-holder, .video-holder {
								margin-bottom: 11px;
								@include min(md) {
									margin-bottom: 0;
								}
								figure {
									overflow: hidden;
									width: 100%;
									picture {
										width: 100%;
									}
								}
								img {
									object-fit: cover;
									display: flex;
									width: 100%;
									height: 320px;
									@include transition(all $speed ease-in);
									@include min(md) {
										height: auto;
									}
								}
								video {
									object-fit: cover;
									display: flex;
									width: 100%;
									height: 320px;
									@include transition(all $speed ease-in);
									@include min(md) {
										height: auto;
									}
								}
							}
						}
						/* end global img style */

						.image-wrapper {
							@include min(md) {
								display: flex;
								flex-wrap: wrap;
							}
							> div {
								// gross
								&:nth-of-type(4n+2), &:nth-of-type(4n+3) {
									@include min(md) {
										@include flex(0,1,60%);
										// margin-bottom: 80px;
									}
									@include min(max) {
										// margin-bottom: 140px;
									}
								}
								// klein
								&:nth-of-type(4n+1), &:nth-of-type(4n+4){
									@include min(md) {
										@include flex(0,1,30%);
										margin: auto 0;
									}
									&:nth-of-type(odd) {
										@include min(md) {
											margin-right: 10%;
											margin-left: 0%;
										}
									}
									&:nth-of-type(even) {
										@include min(md) {
											margin-left: 10%;
											margin-right: 0%;
										}
									}
								}
								// big
								&.big {
									@include min(md) {
										@include flex(0,1,90%);
										margin-left: 5%;
										margin-right: 5%;
										// margin-bottom: 80px;
									}
									@include min(max) {
										// margin-bottom: 140px;
									}
								}
								&.fake {
									display: none;
								}
							}
						}
					}
					.description {
						margin-top: 40px;
						@include min(md) {
							width: 40%;
						}
						.ce_text {
							> * {
								&:last-of-type {
									@include min(md) {
										margin-bottom: 0;
									}
								}
							}
						}
						.ce_hyperlink {
							@include min(md) {
								margin-top: 60px;
							}
						}
					}
				}
			}
		}
	}
}

.ce_rsce_cc_04-01_contact_teaser {
	.rs_container {
		.row {
			.content-holder {
				.content {
					.headline {
						p {
							color: $black;
							font-family: $FactoryFont-Medium;
							letter-spacing: 0;
							@include font-size(3.6);
							line-height: 40px;
							margin: 0 0 1em 0;
							font-weight: 400;
							@include min(md) {
								@include font-size(3.6);
								line-height: 36px;
							}
							@include min(lg) {
								@include font-size(4.6);
								line-height: 46px;
							}
							@include min(xl) {
								@include font-size(5.6);
								line-height: 60px;
							}
							@include min(xxl) {
								@include font-size(6.6);
								line-height: 70px;
							}
							@include min(max) {
								@include font-size(8.9);
								line-height: 107px;
							}
						}
					}
					.subheadline {
						p {
							font-family: $FactoryFont-Bold;
							color: $white;
						}
					}
					.text-holder {
						.ce_text {
							p {
								color: $white;
							}
							> * {
								&:last-child {
									margin-bottom: 0;
								}
							}
						}
						.ce_hyperlink {
							margin-top: 200px;
							@include min(md) {
								width: fit-content;
    							float: right;
								margin-top: 0;
							}
							@include min(lg) {
								@include flex(0,1,calc(50% - 10px));
								order: -1;
							}
							a {
								font-family: $FactoryFont-Bold;
								letter-spacing: 0;
								@include font-size(5.0);
								margin: 22px 0;
								line-height: 50px;
								font-weight: 700;
								@include min(md) {
									@include font-size(6.0);
									line-height: 60px;
									margin: 0.9em 0;
								}
								@include min(lg) {
									@include font-size(10.0);
									line-height: 100px;
								}
								@include min(xl) {
									@include font-size(14.0);
									line-height: 140px;
								}
								@include min(xxl) {
									@include font-size(16.0);
									line-height: 160px;
								}
								@include min(max) {
									@include font-size(18.7);
									line-height: 208px;
								}

								padding-right: 0;
								display: flex;
								align-items: center;
								@include min(md) {
									justify-content: flex-end;
									margin-bottom: 20px;
									margin-top: 1.1em;
								}
								@include min(max) {
									margin-bottom: 10px;
								}
								&::after {
									// background-image: url("data:image/svg+xml,%3Csvg%20id%3D'uuid-0478e9c9-d145-4ce8-ad04-340382150296'%20data-name%3D'_x31_'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2032.912%2017.4'%3E%3Cdefs%3E%3Cstyle%3E.uuid-1b01a2fd-b754-48c8-803b-b3ea58b9c013%7Bfill%3Anone%3Bstroke%3A%23ffffff%3Bstroke-linecap%3Around%3Bstroke-miterlimit%3A10%3Bstroke-width%3A2px%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cline%20id%3D'uuid-822c1021-8188-486a-a700-dd6df4784cad'%20data-name%3D'_x33_'%20class%3D'uuid-1b01a2fd-b754-48c8-803b-b3ea58b9c013'%20x1%3D'1'%20y1%3D'8.698'%20x2%3D'30.505'%20y2%3D'8.698'/%3E%3Cpath%20id%3D'uuid-4cc4ec3f-b934-4a03-9538-29173ce973c2'%20data-name%3D'_x32_'%20class%3D'uuid-1b01a2fd-b754-48c8-803b-b3ea58b9c013'%20d%3D'M23.798%2C16.4l7.7-7.7-7.7-7.7'/%3E%3C%2Fsvg%3E");
									background-image: url("data:image/svg+xml,%3Csvg%20id%3D%22a%22%20data-name%3D%22Ebene%201%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20300%20150%22%3E%3Cdefs%3E%3Cstyle%3E.b%7Bfill%3A%23e4e3ec%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cpath%20class%3D%22b%22%20d%3D%22M293.99%2C0H6.01C2.69%2C0%2C0%2C2.69%2C0%2C6.01v137.99c0%2C3.31%2C2.69%2C6.01%2C6.01%2C6.01h287.99c3.31%2C0%2C6.01-2.69%2C6.01-6.01V6.01c0-3.31-2.69-6.01-6.01-6.01ZM290.99%2C140.99H9.01V9.01h281.98v131.98Z%22%2F%3E%3Cpath%20class%3D%22b%22%20d%3D%22M208.36%2C116.01l6.37%2C6.37%2C44.64-44.64c.84-.85%2C1.32-1.99%2C1.32-3.19s-.47-2.34-1.32-3.19l-44.01-44.01-6.37%2C6.37%2C36.64%2C36.64H34.17v9.01h210.82l-36.63%2C36.63Z%22%2F%3E%3C%2Fsvg%3E");
									position: relative;
									height: 40px;
									width: 70px;
									// border: 4px solid $white;
									// border-radius: 6px;
									margin-left: 20px;
									@include min(md) {
										height: 50px;
										width: 100px;
										margin-left: 40px;
										// background-size: 80% 80%;
										background-size: 100% 100%;
									}
									@include min(lg) {
										// border: 9px solid $white;
										// border-radius: 6px;
										height: 80px;
										width: 180px;
										margin-left: 60px;
									}
									@include min(xl) {
										height: 100px;
										width: 250px;
										margin-left: 60px;
									}
									@include min(xxl) {
										height: 120px;
										width: 290px;
										margin-left: 60px;
									}
									@include min(max) {
										height: 140px;
										width: 290px;
										margin-left: 60px;
									}
								}
								&::before {
									content: none;
								}
								&:hover {
									// color: $white;
									&:after {
										// border: 4px solid $black;
										background-color: transparent;
										// background-image: url("data:image/svg+xml,%3Csvg%20id%3D'uuid-0478e9c9-d145-4ce8-ad04-340382150296'%20data-name%3D'_x31_'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2032.912%2017.4'%3E%3Cdefs%3E%3Cstyle%3E.uuid-1b01a2fd-b754-48c8-803b-b3ea58b9c013%7Bfill%3Anone%3Bstroke%3A%2316141D%3Bstroke-linecap%3Around%3Bstroke-miterlimit%3A10%3Bstroke-width%3A2px%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cline%20id%3D'uuid-822c1021-8188-486a-a700-dd6df4784cad'%20data-name%3D'_x33_'%20class%3D'uuid-1b01a2fd-b754-48c8-803b-b3ea58b9c013'%20x1%3D'1'%20y1%3D'8.698'%20x2%3D'30.505'%20y2%3D'8.698'/%3E%3Cpath%20id%3D'uuid-4cc4ec3f-b934-4a03-9538-29173ce973c2'%20data-name%3D'_x32_'%20class%3D'uuid-1b01a2fd-b754-48c8-803b-b3ea58b9c013'%20d%3D'M23.798%2C16.4l7.7-7.7-7.7-7.7'/%3E%3C%2Fsvg%3E");
										background-image: url("data:image/svg+xml,%3Csvg%20id%3D%22a%22%20data-name%3D%22Ebene%201%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20300%20150%22%3E%3Cdefs%3E%3Cstyle%3E.b%7Bfill%3A%2316141D%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cpath%20class%3D%22b%22%20d%3D%22M293.99%2C0H6.01C2.69%2C0%2C0%2C2.69%2C0%2C6.01v137.99c0%2C3.31%2C2.69%2C6.01%2C6.01%2C6.01h287.99c3.31%2C0%2C6.01-2.69%2C6.01-6.01V6.01c0-3.31-2.69-6.01-6.01-6.01ZM290.99%2C140.99H9.01V9.01h281.98v131.98Z%22%2F%3E%3Cpath%20class%3D%22b%22%20d%3D%22M208.36%2C116.01l6.37%2C6.37%2C44.64-44.64c.84-.85%2C1.32-1.99%2C1.32-3.19s-.47-2.34-1.32-3.19l-44.01-44.01-6.37%2C6.37%2C36.64%2C36.64H34.17v9.01h210.82l-36.63%2C36.63Z%22%2F%3E%3C%2Fsvg%3E");
										@include min(lg) {
											// border: 9px solid $black;
										}
									}
								}
							}
						}
					}
					&.hasText {
						.text-holder {
							.ce_hyperlink {
								@include min(lg) {
									margin-top: auto;
								}
							}
						}
					}
				}
			}
		}
	}
}

.ce_rsce_cc_05-01_text_image {
	padding-top: 11px;
	@include min(md) {
		padding-top: 35px;
	}
	.rs_container {
		.row {
			.content-holder {
				display: flex;
				flex-direction: column;
				@include min(md) {
					flex-direction: row;
				}
				.content {
					@include min(md) {
						@include flex(0,1,40%);
						padding: 0 45px;
						padding-left: 0;
					}
					.headline {
						margin-top: 11px;
						margin-bottom: 22px;
						@include min(md) {
							margin-top: 0;
							margin-bottom: 35px;
						}
					}
				}
				.image-holder {
					order: -1;
					@include min(md) {
						order: 0;
						@include flex(0,1,60%);
					}
					img {
						display: flex;
						width: 100%;
						height: 280px;
						object-fit: cover;
						@include min(md) {
							// height: 480px;
							height: auto;
							width: 100%;
							// object-fit: contain;
							// object-position: top;
						}
						@include min(lg) {
							// height: 600px;
						}
						@include min(max) {
							// height: 800px;
						}
					}

				}
				&.isMirror {
					.content {
						@include min(md) {
							padding: 0 45px;
						}
					}
					.image-holder {
						@include min(md) {
							order: -1;
						}
					}
				}
			}
		}
	}
}

.ce_rsce_cc_06-01_headline_right {
	.rs_container {
		.row {
			.content-holder {
				h1, h2, h3, h4, h5, h6 {
					font-family: $FactoryFont-Bold;
					margin: 0;
					@include min(md) {
						text-align: right;
					}
				}
			}
		}
	}
}


/* 3 BOXES */
.ce_rsce_cc_07-01_three_boxes {
	.rs_container{
		.row {
			@include min(md) {
				display: grid !important;
				grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
				gap: 60px;
			}
			@include min(max) {
				display: grid !important;
				grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
				gap: 160px;
			}
			.teaser-block {
				@include min(md) {
					display: flex;
					flex-direction: column;
				}
				.image-holder {
					margin-bottom: 30px;
					width: 40px;
					height: 40px;
					img {
						width: 40px;
						height: 40px;
					}
				}
				h4 {
					color: $red;
					margin-bottom: 20px;
					@include min(md) {
						margin-bottom: 10px;
					}
				}
				p {
					// margin-top: auto;
					margin-bottom: auto;
				}
			}
		}
	}
	&.headline-height-three {
		.rs_container{
			.row {
				.teaser-block {
					h4 {
						@include min(md) {
							min-height: 90px;
							margin-bottom: 20px;
						}
						@include min(lg) {
							min-height: 68px;
							margin-bottom: 20px;
						}
						@include min(max) {
							min-height: 160px;
							margin-bottom: 20px;
						}
					}
				}
			}
		}
	}
}
/* dark animation */
.mod_article {
	@include transition(all $speed-scrolling ease-in);
	&.dark {
		background-color: $black;
		.ce_rsce_cc_07-01_three_boxes {
			.rs_container{
				.row {
					.teaser-block {
						.image-holder {
							img {
								filter: brightness(0) invert(1);
							}
						}
						h4 {
							color: $red;
						}
						p {
							color: $white;
						}
					}
				}
			}
		}
	}
}
.ce_rsce_cc_07-01_three_boxes {
	.rs_container{
		.row {
			.teaser-block {
				.image-holder {
					img {
						@include transition(all $speed-scrolling ease-in);
					}
				}
				h4 {
					@include transition(all $speed-scrolling ease-in);
				}
				p {
					@include transition(all $speed-scrolling ease-in);
				}
			}
		}
	}
}
/* end dark animation */

/* 4 BOXES */
.ce_rsce_cc_07-02_four_boxes {
	.rs_container{
		.row {
			@include min(md) {
				display: grid !important;
				grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
				gap: 30px;
			}
			@include min(xl) {
				display: grid !important;
				grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
				gap: 60px;
			}
			.teaser-block {
				@include min(md) {
					display: flex;
					flex-direction: column;
				}
				.image-holder {
					margin-bottom: 22px;
					height: auto;
					img {
						width: 100%;
						height: auto;
					}
				}
				h6 {
					margin-bottom: 20px;
					@include min(md) {
						margin-bottom: 49px;
					}
				}
				.ce_text {
					ul {
						margin-top: 0;
						li {
							&:before {
								content: '';
								position: absolute;
								left: 0;
								top: 50%;
								transform: translateY(-50%);
								width: 9px;
								height: 9px;
								border: 2px solid $red;
								border-radius: 50%;
								top: 13px;
								left: -17px;
								background: transparent;
								@include min(md) {
									width: 12px;
									height: 12px;
									top: 15px;
									left: -23px;
								}
								@include min(max) {
									top: 17px;
								}

							}
						}
					}
				}
			}
		}
	}
}

/* 3 BOXES LEISTUNGEN */
.ce_rsce_cc_07-03_three_boxes {
	.rs_container{
		@include min(xl) {
			width: 80%;
			margin-left: auto;
		}
		.row {
			gap: 48px;
			@include min(md) {
				display: grid !important;
				grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
				gap: 35px;
			}
			@include min(xl) {
				gap: 20px;
			}
			@include min(max) {
				gap: 10px;
			}
			.teaser-block {
				@include min(md) {
					display: flex;
					flex-direction: column;
				}
				.image-holder {
					width: 60px;
					height: 60px;
					@include min(md) {
						width: 75px;
						height: 75px;
					}
					img {
						width: 60px;
						height: 60px;
						@include min(md) {
							width: 75px;
							height: 75px;
						}
					}
				}
				h4 {
					// color: $red;
					padding-bottom: 20px;
					margin-bottom: 0;
					border-bottom: 2px solid $black;
					@include min(md) {
						padding-bottom: 31px;
						margin-top: 55px;
					}
				}
				p {
					margin-bottom: 20px;
					margin-top: 20px;
					@include min(md) {
						margin-top: 35px;
						margin-bottom: 35px;
					}
				}
				.button-block {
					@include min(md) {
						margin-top: auto;
					}
				}
			}
		}
	}
}

/* jobs accordeon */
.ce_accordion {
	.row {
		&:first-of-type {
			margin-bottom: 20px;
			@include min(md) {
				margin-bottom: 30px;
			}
			> div {
				margin-top: 0;
			}
		}
		&:last-of-type {
			margin-bottom: 45px;
			@include min(md) {
				margin-bottom: 80px;
			}
			@include min(max) {
				margin-bottom: 140px;
			}
		}
		> div {
			margin-top: 20px;
			@include min(md) {
				margin-top: 0;
			}
			@include min(xxl) {
				margin-top: 30px;
			}
			&:last-of-type {
				@include min(md) {
					margin-bottom: 30px;
				}
				@include min(xxl) {
					margin-bottom: 0;
				}
			}
		}
	}
}

/* Contact Formlar */
.ce_rsce_cc_08-01_contact_formular {
	.rs_container {
		.row {
			.teaser {
				display: flex;
				flex-direction: column;
				@include min(xl) {
    				display: flex;
					flex-direction: row;
				}
				.content-holder {
					@include flex(0,1,100%);
					order: 1;
					@include min(xl) {
						@include flex(0,1,50%);
						order: 0;
					}
					h1, h2, h3, h4, h5, h6 {
						margin-top: 0;
						margin-bottom: 0;
					}
					p {
						margin-top: 20px;
						margin-bottom: 30px;

					}
				}
				.adress-block {
					@include flex(0,1,100%);
					order: 0;
					margin-bottom: 90px;
					text-transform: uppercase;
					@include min(xl) {
						@include flex(0,1,50%);
						order: 1;
						padding-left: 200px;
						margin-bottom: 0;
						margin-top: 10px;
					}
					@include min(xxl) {
						@include flex(0,1,30%);
						padding-left: 0;
						margin-left: auto;
					}
					.ce_text {
						> * {
							&:first-child {
								margin-top: 0;
							}
						}
						margin-bottom: 30px;
						@include min(md) {
							margin-bottom: 50px;
						}
					}
					.link-block {
						.link-title {
							margin-top: 60px;
							@include min(md) {
								margin-top: 80px;
							}
							@include min(xl) {
								@include flex(0,1,33%);
							}
							@include min(max) {
								margin-top: 90px;
							}
						}
						.ce_hyperlink {
							a {
								font-family: $FactoryFont-Bold;
								/*
								color: $black;
								@include font-size(2.5);
								line-height: 18px;
								@include min(md) {
									@include font-size(2.6);
									line-height: 20px;
								}
								@include min(lg) {
									@include font-size(2.7);
									line-height: 22px;
								}
								@include min(xl) {
									@include font-size(2.8);
									line-height: 24px;
								}
								@include min(xxl) {
									@include font-size(2.9);
									line-height: 26px;
								}
								@include min(max) {
									@include font-size(3.0);
									line-height: 28px;
								}
								*/
								&:hover {
									color: $red;
								}
								&:after {
									content: none;
								}
							}
						}

					}
				}
			}
		}
	}
	&.is-mirror {
		.container-custom {
			.row {
				.teaser {
					.content-holder {
						@include min(md) {
							order: 1;
						}
					}
					.adress-block {
						@include min(md) {
							order: 0;
						}
					}
				}
			}
		}
	}
}


.ce_rsce_cc_09-01_timeline {
	@include transition(all $speed-scrolling ease-in);
	max-width: 100%;
	padding-left: 0;
	padding-right: 0;
	.rs_container {
		&.in_Grid {
		}
		.row {
			.content-holder {
				/* mobile */
				@include max(md) {
					h2 {
						@include font-size(4.4);
					}
					.timeline-block {
						.teaser {
							.image {
								padding: 20px 0;
							}
							.line-holder {
								order: 0;
								.line-bottom {
									height: 19px;
								}
								.line-top {
									display: none;
								}
								&.mobile {
									order: 1;
									.point, .line-top {
										display: none;
									}
									.line-bottom {
										height: 68px;
									}
								}
							}
							.text {
								text-align: center;
								padding: 0 42px;
								padding-bottom: 30px;
								.subtitle {
									@include font-size(1.3);
									margin-top: 30px;
									margin-bottom: 30px;
								}
								.title {
									@include font-size(3.2);
									line-height: 34px;
								}
							}
							&:first-of-type {
								.line-holder {
									order: -1;
									.line-bottom {
										height: 68px;
									}
									&.mobile {
										order: 1;
									}
								}
							}
							&:last-of-type {
								.line-holder {
									order: 2;
									.line-top {
										display: none;
									}
									.point {
										display: none;
									}
									.line-bottom {
										height: 68px;
									}
									&.mobile {
										order: 0;
										.arrow {
											display: none;
										}
										.point {
											display: block;
										}
										.line-bottom {
											height: 19px;
										}
									}
								}
							}
						}
					}
				}
				/* end mobile */




				h2 {
					font-family: $FactoryFont-Bold;
					@include transition(all $speed-scrolling ease-in);
					@include min(md) {
						// margin-top: 0;
					}
				}
				.timeline-block {
					margin: 30px 0;
					overflow: hidden;
					@include min(md) {
						margin: 0;
					}
					.teaser {
						display: flex;
						flex-direction: column;
						@include min(md) {
							display: flex;
							flex-direction: row;
						}
						&.ivp {
							> div {
								&:not(.line-holder) {
									@include min(md) {
										opacity: 0.3;
										@include transition(all $speed-scrolling ease-in);
									}
								}
							}
						}
						&.over_half_ivp {
							> div {
								&:not(.line-holder) {
									@include min(md) {
										opacity: 1;
									}
								}
							}
						}
						.image {
							@include transition(all $speed-scrolling ease-in);
							background: $white;
							z-index: 1;
							@include min(md) {
								padding-bottom: 60px;
								@include flex(0,1,calc(50% - 24px));
							}
							@include min(max) {
								@include flex(0,1,calc(50% - 144px));
							}
						}
						.line-holder {
							display: flex;
							flex-direction: column;
							position: relative;
							@include min(md) {
								@include flex(0,1,48px);
							}
							@include min(max) {
								@include flex(0,1,288px);
							}
							&.mobile {
								@include min(md) {
									display: none;
								}
							}
							.point {
								width: 48px;
								height: 48px;
								min-height: 48px;
								border-radius: 50%;
								border: 2px dotted $black;
								margin: 0 auto;
								background-image: url("data:image/svg+xml,%3Csvg%20id%3D'uuid-c8d8f4e7-a9c5-468c-84ba-da2d919fb261'%20data-name%3D'_x31_'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2022.898%2018.659'%3E%3Cdefs%3E%3Cstyle%3E.uuid-6dc87a10-8ebb-47eb-9046-2ead13ecf6e9%7Bfill%3Anone%3Bstroke%3A%23797979%3Bstroke-miterlimit%3A10.005%3Bstroke-width%3A2px%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cpath%20id%3D'uuid-9026bb51-7432-4072-908f-173f0cfcd531'%20data-name%3D'_x32_'%20class%3D'uuid-6dc87a10-8ebb-47eb-9046-2ead13ecf6e9'%20d%3D'M.629%2C9.97l8.972%2C7.259L22.099.601'/%3E%3C%2Fsvg%3E");
								background-repeat: no-repeat;
								background-position: 50% 50%;
								background-size: 55%;
								background-color: $white;
								z-index: 1;
								@include transition(all $speed-scrolling ease-in);
							}
							.arrow {
								display: none;
								position: absolute;
								bottom: 4px;
								left: calc(50% - 16px);
								width: 32px;
								height: 32px;
								min-height: 32px;
								// border-radius: 50%;
								border-left: 2px dotted $black;
								border-bottom: 2px dotted $black;
								transform: rotate(-45deg);
								margin: 0 auto;
								margin-top: -33px;
								// background-color: $white;
								z-index: 2;
								@include transition(all $speed-scrolling ease-in);
							}
							.line {
								position: relative;
								margin: 0 auto;
								border-left: 2px dotted $black;
								@include transition(all $speed-scrolling ease-in);
								@include min(md) {
									height: 100%;
								}
								&.line-top {
									height: 68px;
									@include min(md) {
										height: 68px;
									}
									@include min(lg) {
										height: 46px;
									}
									@include min(xxl) {
										height: 60px;
									}
									@include min(max) {
										height: 120px;
									}
								}
								&::after {
									content: "";
									position: absolute;
									left: -2px;
									top: 0;
									width: 2px;
									height: var(--line-after-height, 0);
									background: $white;
									z-index: 1;
								}
							}
						}
						.text {
							@include transition(all $speed-scrolling ease-in);
							background: $white;
							z-index: 1;
							@include min(md) {
								padding-bottom: 60px;
								@include flex(0,1,calc(50% - 24px));
							}
							@include min(max) {
								@include flex(0,1,calc(50% - 144px));
							}
							.subtitle {
								color: $red;
								@include min(md) {
									@include font-size(1.8);
									margin: 0;
								}
							}
							.title {
								margin-bottom: 45px;
								@include transition(all $speed-scrolling ease-in);
								@include min(md) {
									margin-bottom: 45px;
								}
							}
							.ce_text {
								@include transition(all $speed-scrolling ease-in);
							}
						}
						&:first-of-type {
							.line-holder {
								.line {
									&.line-top {
										opacity: 0;
										@include max(md) {
											height: 0;
										}
									}
								}
							}
						}
						&:last-of-type {
							.line-holder {
								.arrow {
									display: block;
								}
							}
						}
						&:nth-of-type(odd) {
							.image {
								@include min(md) {
									padding-right: 40px;
								}
								@include min(max) {
									padding-left: 18%;
									padding-right: 0;
								}
							}
							.text {
								@include min(md) {
									padding-left: 40px;
								}
								@include min(max) {
									padding-right: 18%;
									padding-left: 0;
								}
							}
						}
						&:nth-of-type(even) {
							.image {
								@include min(md) {
									order: 3;
									padding-left: 40px;
								}
								@include min(max) {
									padding-right: 18%;
									padding-left: 0;
								}
							}
							.text {
								@include min(md) {
									order: -1;
									padding-right: 40px;
								}
								@include min(max) {
									padding-left: 18%;
									padding-right: 0;
								}
							}
						}
					}
				}
			}
		}
	}
	.image_last {
		svg {
			/* background */
			.uuid-c621e7ea-0f4e-4ddd-8fae-ec4a53d95924 {
				@include transition(all $speed-scrolling ease-in);
				fill: $white;
			}
			/* paths */
			.uuid-b0163ac5-ed44-41d2-aec3-a0437a56a6b6 {
				@include transition(all $speed-scrolling ease-in);
				fill: $black;
			}
		}
	}
	&.dark {
		background: $black;
		.rs_container {
			.row {
				.content-holder {
					h1, h2, h3, h4, h5, h6 {
						color: $white;
						z-index: 1;
					}
					.timeline-block {
						.teaser {
							.image {
								background: $black;
								z-index: 1;
							}
							.line-holder {
								.point {
									border: 2px dotted $grey_2;
									background-color: $black;
									background-image: url("data:image/svg+xml,%3Csvg%20id%3D'uuid-c8d8f4e7-a9c5-468c-84ba-da2d919fb261'%20data-name%3D'_x31_'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2022.898%2018.659'%3E%3Cdefs%3E%3Cstyle%3E.uuid-6dc87a10-8ebb-47eb-9046-2ead13ecf6e9%7Bfill%3Anone%3Bstroke%3A%23797979%3Bstroke-miterlimit%3A10.005%3Bstroke-width%3A2px%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cpath%20id%3D'uuid-9026bb51-7432-4072-908f-173f0cfcd531'%20data-name%3D'_x32_'%20class%3D'uuid-6dc87a10-8ebb-47eb-9046-2ead13ecf6e9'%20d%3D'M.629%2C9.97l8.972%2C7.259L22.099.601'/%3E%3C%2Fsvg%3E");
									// @include transition(all $speed ease-in);
								}
								.line {
									border-left: 2px dotted $grey_2;
									&.in_view {
										// border-left: 2px solid $white;
									}
								}
								.arrow {
									border-left: 2px dotted $white;
									border-bottom: 2px dotted $white;
									&.in_view {
										border-left: 2px solid $white;
										border-bottom: 2px solid $white;
									}
								}
							}
							.text {
								background: $black;
								z-index: 1;
								.subtitle {
									color: $red;
								}
								.title {
									color: $white;
								}
								.ce_text {
									color: $white;
								}
							}
						}
					}
				}
			}
		}
		.image_last {
			display: flex;
			img {
				width: 100%;
			}
			svg {
				/* background */
				.uuid-c621e7ea-0f4e-4ddd-8fae-ec4a53d95924 {
					fill: $black;
				}
				/* paths */
				.uuid-b0163ac5-ed44-41d2-aec3-a0437a56a6b6 {
					fill: $white;
				}
				&.in_view {
					/* background */
					.uuid-c621e7ea-0f4e-4ddd-8fae-ec4a53d95924 {
						// fill: $black;
					}
					/* paths */
					.uuid-b0163ac5-ed44-41d2-aec3-a0437a56a6b6 {
						fill: $red;
					}
				}
			}
		}
	}
	/* animate in_view */
	.rs_container {
		.row {
			.content-holder {
				.timeline-block {
					.teaser {
						.line-holder {
							.line {
								&.in_view {
									// border-left: 2px solid $black;
								}
							}
							.point {
								&.in_view {
									border: 2px solid $white;
									// safari fix
									background-position: 50% 50%;
									// end safari fix
									background-image: url("data:image/svg+xml,%3Csvg%20id%3D'uuid-c8d8f4e7-a9c5-468c-84ba-da2d919fb261'%20data-name%3D'_x31_'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2022.898%2018.659'%3E%3Cdefs%3E%3Cstyle%3E.uuid-6dc87a10-8ebb-47eb-9046-2ead13ecf6e9%7Bfill%3Anone%3Bstroke%3A%23ff0046%3Bstroke-miterlimit%3A10.005%3Bstroke-width%3A2px%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cpath%20id%3D'uuid-9026bb51-7432-4072-908f-173f0cfcd531'%20data-name%3D'_x32_'%20class%3D'uuid-6dc87a10-8ebb-47eb-9046-2ead13ecf6e9'%20d%3D'M.629%2C9.97l8.972%2C7.259L22.099.601'/%3E%3C%2Fsvg%3E");
								}
							}
							.arrow {
								&.in_view {
									// border-left: 2px solid $black;
									// border-bottom: 2px solid $black;
								}
							}
						}
					}
				}
			}
		}
	}
	.image_last {
		svg {
			&.in_view {
				/* background */
				.uuid-c621e7ea-0f4e-4ddd-8fae-ec4a53d95924 {
					// fill: $black;
				}
				/* paths */
				.uuid-b0163ac5-ed44-41d2-aec3-a0437a56a6b6 {
					// fill: $black;
				}
			}
		}
	}
}

.ce_rsce_cc_10-01_tags {
	.rs_container {
		.row {
			.content-holder {
				.content {
					.headline {
						margin-top: 0;
					}
					.teaser {
						display: ruby;
						p {
							display: flex;
							align-items: center;
							float: left;
							@include font-size(1.8);
							padding: 10px 20px;
							border: 2px solid $red;
							border-radius: 88px;
							margin: 0 15px 16px 0;
							@include min(md) {
								@include font-size(3.0);
								padding: 15px 42px;
								margin: 0 20px 31px 0;
							}
						}
					}
				}
			}
		}
	}
}

.ce_rsce_cc_11-01_animate_numbers {
	.rs_container {
		.row {
			.content-holder {
				.content {
					.headline {
						
					}
					.box-holder {
						display: grid;
						grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
						gap: 12px;
						@include min(md) {
							grid-template-columns: repeat(auto-fill, minmax(calc(50% - 12px), 1fr));
						}
						@include min(xl) {
							grid-template-columns: repeat(auto-fill, minmax(calc(25% - 12px), 1fr));
						}
						.boxes {
							padding: 25px;
							background: $grey;
							border-radius: 10px;
							text-align: center;
							@include min(md) {
								padding: 30px;
							}
							.number {
								line-height: 1;
								font-family: $FactoryFont-Bold;
								color: $red;
								@include font-size(12.0);
								margin-bottom: 30px;
								@include min(md) {
									@include font-size(13.7);
									margin-top: 30px;
									margin-bottom: 60px;
								}
								@include min(xl) {
									@include font-size(9.7);
								}
								@include min(max) {
									@include font-size(13.7);
								}
							}
							.ce_text {
								margin-bottom: 60px;
								@include min(md) {
									margin-bottom: 60px;
								}
							}
							.image-holder {
								height: auto;
								img {
									filter: brightness(0) invert(0);
								}
							}

						}

					}
				}
			}
		}
	}
}